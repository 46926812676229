
<template>

  <div v-if="this.$store.state.flow.activeRegion === 'piano-selector'">
    <div
      class="piano-selector-highlight-top"
      :style="{ top: `${pianoSelectorRect.y}px`, left: `${pianoSelectorRect.x}px`, width: `${pianoSelectorRect.width}px`, }"
    />
    <div
      class="piano-selector-highlight-right"
      :style="{ top: `${pianoSelectorRect.y}px`, left: `${pianoSelectorRect.x+pianoSelectorRect.width-1}px`, height: `${pianoSelectorRect.height}px`, }"
    />
    <div
      class="piano-selector-highlight-bottom"
      :style="{ top: `${pianoSelectorRect.y+pianoSelectorRect.height-1}px`, left: `${pianoSelectorRect.x}px`, width: `${pianoSelectorRect.width}px`, }"
    />
    <div
      class="piano-selector-highlight-left"
      :style="{ top: `${pianoSelectorRect.y}px`, left: `${pianoSelectorRect.x}px`, height: `${pianoSelectorRect.height}px`, }"
    />
  </div>

</template>

<script>

export default {

  data() {
    return {
    }
  },

  computed: {
    pianoSelectorRect() {
      const rect = this.$store.state.general.pianoSelectorRect
      // console.log('>>>rect', rect);
      return rect
    },
  },

  watch: {
    // loggedInUser(value){  // for case when user changes a note.random to 'fixed'
    //   window.location.reload()
    // },
  },
  methods: {
  },
  mounted() {
  },
  updated() {
  },

}

</script>


<style lang="scss">
@import "../assets/variables";

  .piano-selector-highlight-top {
    position: fixed;
    z-index: 10;
    border: 1px solid white;
    box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
  }
  .piano-selector-highlight-right {
    position: fixed;
    z-index: 10;
    border: 1px solid white;
    box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
  }
  .piano-selector-highlight-bottom {
    position: fixed;
    z-index: 10;
    border: 1px solid white;
    box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
  }
  .piano-selector-highlight-left {
    position: fixed;
    z-index: 10;
    border: 1px solid white;
    box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
  }

</style>
