// rename to flowDefaults?

export default {
  _id: null,
  userId: null,
  flowTitle: "untitled",
  // activeRegion: "piano-selector",
  activeRegion: "tune-entry",
  // activeRegion: "qwerty-player",
  previousRegion: '',
  chain: false,
  chainLoop: false,
  entrySound: true,
  editingSceneNumber: 0,
  editingSceneId: '',
  sceneAdvanceCued: false,
  sceneAdvanceTriggered: false,
  sceneChangeNumber: 0,
  scenes: [],
  sceneBench: [], // is this still referenced anywhere?
  public: false,
  originalData: null,
  authors: [],

  //playerParamSettings: [{'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned': false} ],
  // playerParamSettings: [{}, {}, {},  {}, {}, {},  {}, {}, {}, {}],
  // playerParamCurrent: 0,
  // playerParams:  {
  //   // Basics
  //   assigned: true,
  //   instrumentType: 'polySynth',
  //   sampleType: 'piano',
  //   waveType: 'sawtooth',
  //   qwertyOctave: 3,
  //   keyToQwertyDisplay: 'Rows-Octave',
  //   polySynthGainDefault: 0.12,
  //   monoSynthGainDefault: 0.15,
  //   samplerGainDefault: 0.5,
  //   gain: 0.5,
  //   delayTime: 0,
  //   delayFeedback: 0,
  //   delayActive: true,
  //   distortion: 0,
  //   // ADSR
  //   attack: 0.005,
  //   decay: 0.1,
  //   sustain: 0.3,
  //   release: 3,
  //   portamento: 0,
  //   portamentoOnlyOnDouble: true,
  //   harmonicity: 1,
  //   modulationType: 'square',
  //   modulationIndex: 2,   // not related to scene form!
  //   count: 2,
  //   spread: 20,
  //   modulationFrequency: 0.4,
  //   // Filter
  //   filterWet: 0.5,
  //   filterType: 'lowpass',
  //   filterRolloff: -12,
  //   filterBaseFrequency: 200,
  //   filterQ: 0,
  //   LFOWaveType: 'sine',
  //   LFOFrequency: 0.5,
  //   LFODepth: 0.2,
  //   LFOOctaves: 2.6,
  // },
}

