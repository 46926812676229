
<template>

<!--		<div class="piano-selector" :class="{ activeRegion: activeRegion === 'piano-selector' }" >-->
    <!--      :ref="pianoSelectorRefFunc"-->
    <div
      class="piano-selector"
      ref="pianoSelector"
      id="pianoSelectorId"
      :title="showTitles ? 'Highlighted notes determine random pitch selection' : ''"
    >
<!--      <div v-if="activeRegion === 'piano-selector'" class="piano-selector-active-highlight" />-->
			<ul>
				<li class="white c" @click="handleSendSelections('C')" :class="{ selected: checkNoteSelect('C') }" >C</li>
				<li class="black cs" @click="handleSendSelections('C#')" :class="{ selected: checkNoteSelect('C#') }" >C#</li>
				<li class="white d" @click="handleSendSelections('D')" :class="{ selected: checkNoteSelect('D') }" >D</li>
				<li class="black ds" @click="handleSendSelections('D#')" :class="{ selected: checkNoteSelect('D#') }" >D#</li>
				<li class="white e" @click="handleSendSelections('E')" :class="{ selected: checkNoteSelect('E') }" >E</li>
				<li class="white f" @click="handleSendSelections('F')" :class="{ selected: checkNoteSelect('F') }" >F</li>
				<li class="black fs" @click="handleSendSelections('F#')" :class="{ selected: checkNoteSelect('F#') }" >F#</li>
				<li class="white g" @click="handleSendSelections('G')" :class="{ selected: checkNoteSelect('G') }" >G</li>
				<li class="black gs" @click="handleSendSelections('G#')" :class="{ selected: checkNoteSelect('G#') }" >G#</li>
				<li class="white a" @click="handleSendSelections('A')" :class="{ selected: checkNoteSelect('A') }" >A</li>
				<li class="black as" @click="handleSendSelections('A#')" :class="{ selected: checkNoteSelect('A#') }" >A#</li>
				<li class="white b" @click="handleSendSelections('B')" :class="{ selected: checkNoteSelect('B') }" >B</li>
			</ul>
		</div>

</template>

<script>

export default {

	  data() {
			return {
			}
	  },

		computed: {
      showTitles() {
        return this.$store.state.generalPersist.showTitles
      },
			scene(){
				return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
			},
			activeRegion(){
				return this.$store.state.flow.activeRegion
			},
			selectedNotes(){
			  const selectedNotes = this.scene.selectedNotes
				return this.scene.selectedNotes
			},
		},

	  methods: {
			handleSendSelections: function (note) {
        // console.log('this.$refs', this.$refs);
				this.$store.commit('updateSelectedNotes', note)
	    },

			checkNoteSelect: function(note) {
				if (this.selectedNotes.indexOf(note) === -1) { return false}
				else { return true }
			}
	  },

    mounted() {
      // console.log('pianoSelectorRef', this.$refs.pianoSelector);
      this.$store.commit('setRef', { name: 'pianoSelector', value: this.$refs.pianoSelector})
      this.$store.dispatch('setPianoSelectorRect')
    },
    updated() {
    },
}

</script>


<style lang="scss">
@import "../assets/variables";

.piano-selector {
  margin: 0 0 0 0;
	//border: 1px solid transparent;
  width: 100%;
  //height: 120px;
  height: 100%;
  //background: grey;
  position: relative;

  .piano-selector-active-highlight {
    position: absolute;
    top: 0; left: 0;
    width: 270px;
    height: 100%;
    background: transparent;
    z-index: 10;
    border: 1px solid white;
    box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
  }

  ul {
    padding: 0;
    margin: 0;
    height: 100%;
    //remember this element is collapsed:
    //border:1px solid #160801;
    //height:126px; /* 110px; */
    //width:297px; /* 300px */
    //position:relative;
    //background: $accentMed1;
    //vertical-align: bottom;

    li {
      display: inline-block;
      list-style:none;
      position:relative;
      float:left;
      text-align: center;
      cursor: pointer;

      &.white {
        //height:118px; /* 100px */
        height: 100%;
        width:40px;
        z-index:1;
        border-left: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        //background: white;
        background: $themeMed;
        line-height: 1175%;

      }

      &.black {
        height:73px; /* 60px */
        width:30px;
        margin:0 0 0 -16px;
        z-index:2;
        border:1px solid #000;
        //background: #333;
        background: $themeMed;
        line-height: 80px;
        color: white;
      }

      &.white.selected {
        background: $pianoKeyNaturalSelected;
        //background: white;
      }
      &.black.selected {
        background: #070;
        //background: #333;
      }

      &.d,&.e,&.g,&.a,&.b { margin:0 0 0 -16px;  }
      &.f { margin: 0 0 0 0px}
      &.b { border-right: 1px solid black; };
    }

  }


}
//.piano-selector.activeRegion {
	//border: 1px solid white;
	//box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
/*	animation: blinker 2s linear infinite; */
//}



</style>
