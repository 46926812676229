import router from "@/router";
// import authAPI from SOMETHING-OR-OTHER

export const auth = {
  namespaced: false,
  state: {
    sessionKey: null,
    user: null,
  },

  mutations: {
    login: (state, data) => { // should perhaps name this something else since both register & login actions use it
      // console.log('mutation login data', data)
      const { session_key, user } = data
      state.sessionKey = session_key
      state.user = user
    },
    logout: state => {
      state.sessionKey = null
      state.user = null
    },
  },

  actions: {
    register: (ctx, userData) => {
      // console.log('action register')
      fetch(`http://localhost:4000/auth/register`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
      })
        .then(res => res.json())
        .then(async data => {
          // console.log('fetched data', data)
          ctx.commit('login', data)
          const { user } = data
          // console.log('user._id', user._id)
          await router.push(`/users/${user._id}`)
        })
        .catch(err => console.error(err))
    },
    login: (ctx, userData) => {
      // console.log('action login')

      fetch(`http://localhost:4000/auth/login`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
      })
        .then(res => res.json())
        .then(async data => {
          // console.log('fetched data', data)
          ctx.commit('login', data)
          if (router.currentRoute.value.path === '/signup') { await router.push('/') }
        })
        .catch(err => {
          console.error(err)
          alert("Username/email and password don't match")
        })
    },

    logout: ctx => {
      // console.log('action logout')
      ctx.commit('logout')
    },
  },

  getters: {
  }
};



// export const counter = {
//   namespaced: true,
//   state: {
//     counter: 0
//   },
//
//   mutations: {
//     [SET_COUNTER]: state => state.counter++
//   },
//
//   actions: {
//     [SET_COUNTER]: ({ commit, dispatch }) => {
//       dispatch(`${COUNTER2_M}${SET_COUNTER}`, null, { root: true });
//       commit(SET_COUNTER);
//     }
//   },
//
//   getters: {
//     [GET_COUNTER]: state => state.counter
//   }
// };
