import { AudioManager as AM } from "@/audio/AudioManager"
import * as HELPERS from "@/utils/helpers"


// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************

export const changeTrackNoteDuration = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].noteDuration = payload.duration
}

export const mChangeTrackWave = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].waveType = payload.wave
}

export const toggleTrackDelay = (state, trackNumber) => {
  // console.log('toggleTrackDelay');
  // would be cool if there were some way (other than disposing of the delay node, which seems inconvenient) to stop the delay entirely so that on resume there is no residual echo
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let track = scene.tracks[trackNumber]
  if (track.delayActive === true) {
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].disconnect()
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].delayTime.value = 0
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].feedback.value = 0
    // AM.scenes[scene.title].delays[scene.editingTrackNumber].wet.value = 0
    AM.scenes[scene.title].delays[trackNumber].wet.value = 0
    track.delayActive = false
  } else {
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].connect(AM.scenes[scene.title].gains[scene.editingTrackNumber])
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].delayTime.value = track.delayTime
    //AM.scenes[scene.title].delays[scene.editingTrackNumber].feedback.value = track.delayFeedback
    // AM.scenes[scene.title].delays[scene.editingTrackNumber].wet.value = 1
    AM.scenes[scene.title].delays[trackNumber].wet.value = 1
    track.delayActive = true
  }
}


// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************


export const changeTrackInstrumentOrSample = (context, payload) => {
  // this may actually be confusing/problematic to not have two different functions for this, even though they would be the same
  // also: note that this does not pass the scene title in the payload. currently this is found by ternary in updateTrackSoundParams
  // likewise, it does not pass the sceneNumber, this is also found by ternary
  if ( (payload.value === 'polySynth' || payload.value === 'monoSynth') && payload.track.instrumentType ==='sampler' ) {
    context.commit('changeTrackNoteDuration', { trackNumber: payload.trackNumber, duration: '8n' } )
  } else if (payload.value === 'sampler' && (payload.track.instrumentType === 'monoSynth' || payload.track.instrumentType === 'polySynth') ) {
    context.commit('changeTrackNoteDuration', { trackNumber: payload.trackNumber, duration: '1m' })
  }
  context.commit('mUpdateTrackSoundParams', payload)
  context.dispatch('initializeSceneAudio', context.state.flow.editingSceneNumber)
}

export const changeTrackWave = (context, payload) => {
  // console.log('cchangeTrackWave payload',payload)
  context.commit('mChangeTrackWave', payload)
  AM.scenes[context.getters.activeSceneTitle].instruments[payload.trackNumber].set({
    'oscillator': { 'type': payload.wave }
  })
}
