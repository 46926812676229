import * as CONSTANTS from "@/constants/constants"
import { MODEDATA } from "@/constants/modeData"
import * as helpers from "@/utils/helpers"


// ***************************************************************************************************************
// GETTERS
// ***************************************************************************************************************

export const toneTunes = state => { // computed for "current" scene only! (pros / cons of returning an array/object with all the scenes? )
  let toneTunes = []
  state.flow.scenes[state.flow.editingSceneNumber].tracks.forEach(function(track, index) {
    if (track.tune.length > 0) {
      let mod1 = track.tune[track.tune.length-1].pitch === '+' ? track.tune.slice(0, -1) : track.tune
      let mod2 = mod1.map(note => {
        if (note.pitch === ' ') return 0
        else return note.pitch
      })
      toneTunes.push(mod2)
    } else { toneTunes.push([])}
  })
  return toneTunes
}

export const pitchSets = state => { // computed for "current" scene only! (pros / cons of returning an array/object with all the scenes? )
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let pitchSets = []
  scene.tracks.forEach(function(track, index){
    let pitchSet = []
    let adjustedRangeLow = []
    let adjustedRange = [] // minus the lower and higher bounds checked...
    for (let i=0; i < CONSTANTS.FULLRANGE.length; i++){
      if(CONSTANTS.FULLRANGE[i] === track.rangeLow){ adjustedRangeLow = CONSTANTS.FULLRANGE.slice(i) }
    }
    for (let j=0; j <= adjustedRangeLow.length; j++){
      if(adjustedRangeLow[j-1] === track.rangeHigh){ adjustedRange = adjustedRangeLow.slice(0, j) }
    }
    for (let k=0; k < adjustedRange.length; k++){
      if (scene.selectedNotes.indexOf(adjustedRange[k].slice(0,-1)) >-1 ) {
        pitchSet.push(adjustedRange[k])
      }
    }
    pitchSets.push(pitchSet)
  })         //  ; console.log("getter pitchSets", pitchSets)
  return pitchSets
}

export const maxChangeables = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let maxChangeables = []
  scene.tracks.forEach( (track, index) => {
    let maxChangeable = 0
    track.tune.forEach( (note, index) => {
      if (note.random === 'noRests' || note.random === 'rests') { maxChangeable++ }
    })
    maxChangeables.push(maxChangeable)
  })
  return maxChangeables
}

export const selectedModulations = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let modulations =[]
  for (let mod in scene.modulationWeights) {
    for (let i=0; i < scene.modulationWeights[mod]; i++) { modulations.push(mod) }
  }
  if (modulations.length === 0) { modulations = ['dia'] }
  //console.log('modsulations', modulations)
  return modulations
}


// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************

export const startScene = state => {
  // console.log('engine startScene');
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.started = true
}

export const changeToneTuneIndex = (state, payload) => {
    // console.log("called changeToneTuneIndex:", payload)
    let scene = state.flow.scenes[state.flow.editingSceneNumber]
    if (payload.change === 'increment') { scene.tracks[payload.index].toneTuneIndex++ }
else if (payload.change === 'zero') { scene.tracks[payload.index].toneTuneIndex = 0 }
}

export const changeChainIncrement = (state, change) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (change === "increment") { scene.chainIncrement++ }
  else if (change === "zero") { scene.chainIncrement = 0 }
}

export const changeCycles = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (payload.change === 'increment') { scene.tracks[payload.index].changeCycles++ }
  else if (payload.change === 'zero') { scene.tracks[payload.index].changeCycles = 0 }
}

export const toggleTrackChangeTriggered = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.index].changeTriggered = payload.bool
}

export const changeTuneNote = (state, payload) => {
  // console.log("called changeTuneNote")
let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackIndex].tune[payload.tuneIndex].pitch = payload.pitch
}

export const overwriteTrackTune = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].tune = payload.newTune
}

export const setSceneAdvanceCued = (state, bool) => {
  bool === true? state.flow.sceneAdvanceCued = true : state.flow.sceneAdvanceCued = false
}

export const setSceneAdvanceTriggered = (state, bool) => {
  bool === true? state.flow.sceneAdvanceTriggered = true : state.flow.sceneAdvanceTriggered = false
}

export const updateSelectedMode = (state, modeInfo) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  //console.log('modeInfo', modeInfo)
  scene.selectedNotes = modeInfo.modePitches
  scene.lastMode = modeInfo.modeBase + '-' + modeInfo.modulation
}

export const updateFormStep = (state, update) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (update === 'zero'){
    scene.formStep = 0
  } else if (update === 'increment') {
    scene.formStep++
  } else if (update === 'off') {
    scene.formStep = -1
  }
  // console.log('formStep after update', scene.formStep)
}

export const updateNextModulation = (state, modulation) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  //console.log("mod", modulation)
  scene.nextModulation = modulation
}

export const updateModulationCycles = (state, update) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (update === 'increment') { scene.modulationCycles++ }
  else if (update === 'zero') { scene.modulationCycles = 0 }
}

export const toggleModulationTriggered = (state, bool) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.modulationTriggered = bool
}

// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

// TODO refactor to manage Drift & Form separately?
export const morphSelectedNotes = (context, userCalled) => {
  // console.log('in morphSelectedNotes');
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (scene.modulationStyle === 'form') {
    context.commit('updateSelectedMode', scene.nextModulation)
    context.dispatch('formStepAndChainIncrement')
    let nextFormSection = (scene.formStep < scene.harmonicForm.length-1)  ? scene.harmonicForm[scene.formStep+1] : scene.harmonicForm[0]
    let nextFormSectionSansPrefix = nextFormSection.match(/([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr|maj|min|sus|ma7|dom|mi7|hdm|dm7|blu|pen|fth|one)/i)[0]
    let nextModeInfo = helpers.referenceMode(MODEDATA, nextFormSectionSansPrefix)
    context.commit('updateNextModulation', nextModeInfo)
  } else if (scene.modulationStyle === 'drift' && scene.autoModulate) {
    if (!userCalled){
      context.commit('updateSelectedMode', scene.nextModulation)
    }
    // DRY re: autoModulate & toggleModulationStyle
    let type = helpers.randomElement(context.getters.selectedModulations)
    let nextModeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
    context.commit('updateNextModulation', nextModeInfo)
  } else {
    let type = helpers.randomElement(context.getters.selectedModulations)
    let newModeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
    context.commit('updateSelectedMode', newModeInfo)
  }
}

export const checkChainIncrementAndTriggerAdvance = (context, payload) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (payload.track.id === scene.leadTrackId &&
    context.state.flow.chain === true &&
    payload.increment === scene.sceneChangeIncrement
  ) {
    // console.log("in cCIATA, chainIncrement", scene.chainIncrement)
    if (scene.chainIncrement < scene.chainAdvancePer-1) {
    context.commit('changeChainIncrement', 'increment' )
    } else {
      context.commit('changeChainIncrement', 'zero' )
      context.commit('setSceneAdvanceTriggered', true)
    }
  }
}

export const checkAdvanceCueVsChangeIncrement = (context, payload) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (context.state.flow.sceneAdvanceCued &&
    payload.track.id === scene.leadTrackId &&
    payload.increment === scene.sceneChangeIncrement
  ) {
    context.commit('setSceneAdvanceTriggered', true)
    context.commit('setSceneAdvanceCued', false)
  }
}

export const changeTune = (context, payload)  => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let track = scene.tracks[payload.trackIndex]
  if (track.tune.length === 0 || track.tune[0].pitch === '+') { return }
  let changeableNoteIndexes = []
  let pitchSet = context.getters.pitchSets[payload.trackIndex]
  track.tune.forEach( (note, index) => {
    if ( (note.random === 'rests' || note.random === 'noRests') && note.pitch != '+') { changeableNoteIndexes.push(index) }
  })
  if (changeableNoteIndexes.length > 0) {
    helpers.shuffle(changeableNoteIndexes)
    let changeTotal = payload.all ? context.getters.maxChangeables[payload.trackIndex] : track.changeTotal
    for (let i = 0; i < changeTotal; i++) {
      let prevPitch = track.tune[changeableNoteIndexes[i]].pitch
      let newPitch = ''
      if (pitchSet.length > 1) {
        do {
          newPitch = helpers.randomElement(pitchSet)
        } while (newPitch === prevPitch)
      } else {
        newPitch = pitchSet[0]
      }
      if (track.tune[changeableNoteIndexes[i]].random === 'rests' && Math.random() * 100 > track.pitchPercent) {
        newPitch = ' '
      }
      // why is this after the doWhile? shouldn't this be first and doWhile be in an else?
      // && (prevPitch != ' ' || pitchSet.length === 1) // see commentary
      context.commit('changeTuneNote', {
        trackIndex: payload.trackIndex,
        tuneIndex: changeableNoteIndexes[i],
        pitch: newPitch
      })
    }
  }
  if (scene.filterPitchesOnChange && payload.trackIndex === context.getters.leadTrackNumber){
    scene.tracks.forEach( (track, index) => {
      let filteredTune = helpers.filterTrackTunePitches(track.tune, context.getters.pitchSets[index])
      context.commit('overwriteTrackTune', { trackNumber: index, newTune: filteredTune  })
    })
  }
  let formSection = scene.harmonicForm[scene.formStep]
  if ( formSection !== undefined && formSection.match(/([c|d|f|g|a]#?|[b|e])\\/i) !== null) {
    context.dispatch('updateTuneWithPrefix', {trackIndex: payload.trackIndex, formSection: formSection})
  }
}

export const updateTuneWithPrefix = (context, payload) => {
  let pitchSet = context.getters.pitchSets[payload.trackIndex]
  let formSectionPrefix = payload.formSection.match(/([c|d|f|g|a]#?|[b|e])\\/i)[0]
  let rootPitch = formSectionPrefix.match(/[c|d|f|g|a]#?|[b|e]/i)[0]
  let newPitch = pitchSet.find( pitch => {
    return pitch.slice(0, -1) === rootPitch
  })
  context.commit('changeTuneNote', {
    trackIndex: payload.trackIndex,
    tuneIndex: 0,
    pitch: newPitch, // 'C4'
  })
}

export const formStepAndChainIncrement = (context) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let leadTrack = scene.tracks[context.getters.leadTrackNumber]
  // console.log('fS', scene.formStep, 'hFL-1:', scene.harmonicForm.length-1)
  if (scene.formStep < scene.harmonicForm.length-1) {
    context.commit('updateFormStep', 'increment')
  } else {
    context.commit('updateFormStep', 'zero')
    context.dispatch('checkChainIncrementAndTriggerAdvance', { track: leadTrack, increment: 'Form' }  )
    context.dispatch('checkAdvanceCueVsChangeIncrement', { track: leadTrack, increment: 'Form' } )
  }
}

export const changeScene = (context) => {
  // console.log(">>>action change scene")
  context.commit('mChangeScene')
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (scene.loadQwertySettingOnSceneChange != 'none'){
    context.commit('activatePlayerParamSettings', scene.loadQwertySettingOnSceneChange)
  }
}

// doesn't really seem like an "engine" thing since it is not called from loop
export const changeAll = (context, number) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  scene.tracks.forEach( (track, index) => {
    switch (number) {
      case 'all':
        context.dispatch('changeTune', { trackIndex: index, all: true })
        break
      case 'changeTotal':
        context.dispatch('changeTune', { trackIndex: index, all: false })
        break
    }
  })
}
