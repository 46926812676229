<template>
  <div class="track-controls">

    <div class="track-controls__left">
      <div
        class="track-number--lead"
        v-if="trackNumber===leadTrackNumber"
        :title="showTitles ? 'The lead track determines how often randomized note changes occur' : ''"
      >
        Lead
      </div>
      <div
        class="track-number"
        v-else
        @click="assignLeadTrack(trackNumber)"
        :title="showTitles ? 'Notes change in this track when the lead track changes' : ''"
      >
        Track {{ trackNumber+1 }}
      </div>
      <div class="track-settings-n-mute">
        <div 
          class="track-mute" 
          v-if="!track.muted" 
          @click="toggleTrackMute" 
          :title="showTitles ? 'Mute track' : ''"
        >
          <img src="../assets/images/icons8-speaker-96.png" />
        </div>
        <div 
          class="track-mute" 
          v-else 
          @click="toggleTrackMute" 
          :title="showTitles ? 'Unmute track' : ''"
        >
          <img class="small" src="../assets/images/icons8-mute-96.png" />
        </div>
        <div 
          class="track-settings" 
          @click="toggleSoundPanel" 
          :title="showTitles ? 'Open/close sound options' : ''"
        >
          <img src="../assets/images/icons8-settings-96.png" />
        </div>
      </div>
    </div>

    <div class="track-controls__right">

      <div 
        class="range-adjust"
        :title="showTitles ? 'Low and high ranges for random note selection' : ''"
        >
        <span>Low</span>
        <select
          :id="'rangeLow-'+trackId"
          v-model="rangeLow"
          @focus="focusFunction"
          @change="changeFunction($event)"
          @blur="changeFunction($event)"
        >
          <option v-for="pitch in lowerRangeOptions" :key="Math.random().toString().slice(2)" >{{ pitch }}</option>
        </select>
        <span>High</span>
        <select
          :id="'rangeHigh-'+trackId"
        	v-model="rangeHigh"
          @focus="focusFunction"
          @change="changeFunction($event)"
          @blur="changeFunction($event)"
        >
          <option v-for="pitch in upperRangeOptions" :key="Math.random().toString().slice(2)" >{{ pitch }}</option>
        </select>
      </div>

      <div 
        class="pitch-percent"
        :title="showTitles ? 'Odds for a randomized note to be a pitch rather than a rest' : ''"
      >
        <span class="fill-odds">Fill Odds</span>
        <input
          type="number" min="0" max="100"
          :id="'changePitchPercent-'+trackId"
          v-model="pitchPercent"
          @focus="focusFunction"
          @keyup.enter="enterFunction($event)"
        />
        <span>%</span>
      </div>

      <div class="tune-changer">
        <span 
          class="track-change" 
          v-on:click="changeTune"
          :title="showTitles ? 'Change track notes now' : ''"
        >
          Change
        </span>
        <input
          :id="'changeTotal-'+trackId"
          v-bind:class="{ greyOut: scene.suspendChanges }"
          type="number" min="0"
          v-bind:max="getMaxChangeable"
          v-bind:value="changeTotal"
          v-on:change="updateChangeTotal($event.target.value)"
          @focus="focusFunction"
          @keyup.enter="enterFunction($event)"
          @blur="enterFunction($event)"
          :title="showTitles ? 'How many notes change at once' : ''"
        >
        <span>per</span>
        <input
          :id="'changePer-'+trackId"
          v-bind:class="{ greyOut: scene.suspendChanges }"
          type="number" min="0"
          v-bind:value="changePer"
          v-on:change="updateChangePer"
          @focus="focusFunction"
          @keyup.enter="enterFunction($event)"
          @blur="enterFunction($event)"
          :title="showTitles ? 'How many lead-track cycles till the notes change' : ''"
        >
      </div>

        <!--   {{ track.id }} -->
    </div>

  </div>

</template>

<script>

import { FULLRANGE } from "@/constants/constants";

export default {
  name: "",

  props: ["trackNumber", "trackId"],

  components: {
  },

  data: () => ({
  }),

  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    track(){
      return this.scene.tracks[this.trackNumber]
    },
    soundPanel(){
      return this.track.soundPanel
    },
    leadTrackNumber() {
      return this.$store.getters.leadTrackNumber
    },
    pitchPercent: {
      get(){
        return this.scene.tracks[this.trackNumber].pitchPercent
      },
      set(value){
        this.$store.commit('updateTrackPitchPercent', { trackNumber: this.trackNumber, value: value })
      },
    },
    rangeLow: {
      get(){
        return this.scene.tracks[this.trackNumber].rangeLow
      },
      set(value){
        this.$store.commit('adjustRange', { trackNumber: this.trackNumber, range: 'low', pitch: value })
      },
    },
    rangeHigh: {
      get(){
        return this.scene.tracks[this.trackNumber].rangeHigh
      },
      set(value){
        this.$store.commit('adjustRange', { trackNumber: this.trackNumber, range: 'high', pitch: value })
      },
    },

    upperRangeOptions: function () {
      const highRange = FULLRANGE.slice(FULLRANGE.indexOf(this.rangeLow))
      return highRange.reverse()
    },
    lowerRangeOptions: function () {
      const lowRange = FULLRANGE.slice(0, FULLRANGE.indexOf(this.rangeHigh)+1)
      return lowRange.reverse()
    },

    getMaxChangeable(){
      return this.$store.getters.maxChangeables[this.trackNumber]
    },
    changeTotal(){
      let changeTotal = this.scene.tracks[this.trackNumber].changeTotal
      // console.log('changeTotal', changeTotal);
      // the problem with this next line is (was?), while it does automatically drop changeTotal when you de-randomable a note, it also automatically increases changeTotal when you randomable a note
      if (changeTotal > this.getMaxChangeable) { changeTotal = this.getMaxChangeable }
      return changeTotal
    },
    changePer(){
      let changePer = this.scene.tracks[this.trackNumber].changePer
      return changePer
    },
  },

  watch:{
    getMaxChangeable(value){  // for case when user changes a note.random to 'fixed'
      // this.track will be undefined for last track on removeTrack (this.scene.tracks length is 3, but this.trackNumber (index) is also 3)
      // methods are unaltered from original, guessing this has to do with vuex-persist
      if (this.track && value < this.track.changeTotal) { this.updateChangeTotal(value) }
    },
  },

  methods: {
    toggleSoundPanel(){
      this.$store.commit('toggleSoundPanel', this.trackNumber)
    },
    toggleTrackMute(){
      this.$store.dispatch('toggleTrackMute', this.trackNumber)
    },
    changeTune(){
      this.$store.dispatch('changeTune', { trackIndex: this.trackNumber, all: false })
    },
    updateChangeTotal(value){
      this.$store.commit('updateChangeTotal', { trackNumber: this.trackNumber, value: value })
    },
    updateChangePer(e){
      this.$store.commit('updateChangePer', { trackNumber: this.trackNumber, value: e.target.value })
    },
    assignLeadTrack(index) {
      this.$store.commit('updateLeadTrack', index)
    },
    focusFunction(){
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      this.$store.commit('changeActiveRegion', 'track-controls')
    },
    enterFunction(event){
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
    },
    changeFunction(event){
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      // bus.$emit('clearKeyFromDown', 'Enter')
    },

  },
}

</script>


<style lang="scss">
@import "../assets/variables";

.track-controls {
  display: flex;
  background: #bbb;
  min-width: 200px;
  box-sizing: border-box;
  padding: 5px;
  min-height: 70px;
  align-items: stretch;

  border: 1px solid black;
  //min-height: 60px;
  //float:left; /* if you make this inline-block there's extra space on top. Maybe: https://css-tricks.com/fighting-the-space-between-inline-block-elements/ */
  flex-shrink: 0;

  &__left {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 38px;
    font-size: 12px;
    min-height: 100%;


    .track-number--lead {
      padding: 4px 0;
      background: #0c8;
      font-weight: bold;
      box-sizing: border-box;
      font-size: 10px;
      border: 1px solid black;
    }

    .track-number {
      padding: 4px 0;
      background: #aaa;
      cursor: pointer;
      box-sizing: border-box;
      font-size: 10px;
      border: 1px solid black;
    }

    .track-settings-n-mute {
      display: flex;
      flex-direction: column;

      .track-settings {
        cursor: pointer;

        img {
          width: 20px;
        }
      }

      .track-mute {
        cursor: pointer;

        img {
          width: 20px;
        }
      }
    }
  }
}


.track-controls__right {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: flex-start;
  margin-left: 5px;


  font-size: 12px;

  span {
    margin: 0 2px 0 0;
  }

  input, select {
    font-size: 10px;
    margin: 0 2px 0 0;
    padding: 1px 0;
  }

  .range-adjust {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 3px;

    span {
      //width: 20px;
    }
    select {
      //width: 50px;
    }
  }

  .pitch-percent {
    margin-bottom: 3px;

    .fill-odds {
      display: inline-block;
      width: 50px;
    }

    input {
      width: 36px;
    }
  }

  .tune-changer {
    display: flex;
    align-items: center;

    .track-change {
      width: 50px;
      display: inline-block;
      box-sizing: border-box;
      padding: 1px;
      background: $accentMed1;
      border: 1px solid black;
      cursor: pointer;
    }

    span {
      /*	width: 20px;*/
    }
    input {
      width: 30px;
    }
  }

}

</style>
