import * as Tone from 'tone'

class Audio {
  constructor() {
    this.test = 'test'
    this.synths = []
  }

  addSynth = () => {
    // console.log('addSynth');
    const synth = new Tone.Synth().toDestination()
    this.synths.push(synth)
  }

}

const audio = new Audio

export default audio
