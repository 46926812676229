<template>

  <div class="flow">
<!--    <div class="flow-user-pic"><p>Pic!!!</p></div>-->
<!--    <svg :data-jdenticon-value="flow.user_id.email" width="80" height="80">-->
<!--      Fallback text or image for browsers not supporting inline svg.-->
<!--    </svg>-->
    <jazzicon
      v-if="showJazzicon"
      :address="flow.user_id ? flow.user_id._id : flow._id"
      :diameter="80"
    />

    <div v-if="isLoggedInUser || showDelete" class="delete-flow">
      <button v-if="isLoggedInUser" @click="deleteFlow(flow._id)">Delete</button>
      <button v-else @click="deleteFlowLocal(flow._id)">Delete</button>
    </div>

    <div class="flow-info">
      <p>
        <span
          class="flow-title"
          @click="setFlow(flow._id)"
        >{{flow.flow_title || flow.flowTitle}}</span>
        <span
          v-if="showUsername && flow.username"
          class="flow-username"
        > by <span @click="setAuthor(flow.user_id._id)">{{flow.user_id.username}}</span></span>
        <span class="flow-updated-at">&nbsp;(updated {{ $filters.dateFormattedBasic(flow.updatedAt)}})</span>
      </p>
      <p class="first-scene-lead">First scene lead: {{ flowLeadTrackNotes }}</p>
      <p
        class="authorship-history"
        v-if="flow.authors.length > 0"
      >
        Author History:
        <span
          v-for="(author, i) in flow.authors"
          :key="`${author.authorId}${author.flowId}`"
        >
          <span
            class="authorship-history__author"
            @click="setAuthor(author.authorId)"
          >{{author.authorName}}</span>
          <span> (</span>
          <span
            class="authorship-history__flow"
            @click="setFlow(author.flowId)"
          >{{author.flowTitle}}</span>)<span v-if="i < flow.authors.length - 1">, </span>
        </span>
      </p>
    </div>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import Jazzicon from 'vue3-jazzicon/src/components';


export default defineComponent({
  name: "FlowListing",
  components: {
    [Jazzicon.name]: Jazzicon
  },
  props: [
    'flow', 'showUsername', 'showJazzicon',  
    'deleteFlow', 'isLoggedInUser', 'showDelete'
  ],
  data:() => ({
  }),
  computed: {
    flowLeadTrackNotes() {
      const firstScene = this.flow.scenes[0]
      const leadTrackId = firstScene.leadTrackId
      const leadTrack = firstScene.tracks.find(e => e.id === leadTrackId)
      const leadTrackTuneNotes = leadTrack.tune.map(e => {
        if (e.pitch === '+') return
        if (e.pitch === ' ') return '__'
        return e.pitch
      })
      return leadTrackTuneNotes.join(' ')
    }
  },
  methods: {
    setFlow(flowId) {
      if (true) { // TODO if generalPersist.warnOnSetFlow ("This warning can be deactivated in settings.")
        const doSet = confirm('You will loose any unsaved changes to the current flow. Proceed?')
        if (!doSet) return
      }

      // this.$store.commit('setNewFlow', this.flows[flowIndex])
      router.push(`/${flowId}`)
    },
    setAuthor(authorId) {
      router.push(`/users/${authorId}`)
    },
    deleteFlowLocal(flowId) {
      const activeFlowId = this.$store.state.flow._id
      if (flowId === activeFlowId) {
        const doDelete = confirm('This is your currently active flow. Are you sure you want to delete it?')
        this.$store.dispatch('resetDefaults')
        if (doDelete) this.$store.dispatch('deleteFlowLocal', flowId)
      } else {
        const doDelete = confirm('Are you sure you want to delete this flow?')
        if (doDelete) this.$store.dispatch('deleteFlowLocal', flowId)
      }
    },
  },
});
</script>

<style lang="scss">
@import "../../assets/variables";

.flow {
  display: flex;
  align-items: center;
  //background: lightsalmon;
  margin-bottom: 10px;

  //.flow-user-pic {
  //  background: grey;
  //  padding: 1px 5px;
  //  border: 1px solid black;
  //  display: inline-block;
  //  cursor: pointer;
  //}

  .delete-flow {
    font-size: 10px;
    margin-left: 10px;

    button {
      cursor: pointer;
    }
  }

  .flow-info {
    margin-left: 10px;
    text-align: start;

    p {
      text-align: start;
      margin: 0 0 0 0;
      padding: 0 5px;
      //font-size: 12px;

      .flow-title {
        font-size: 24px;
        color: darkslateblue;
        text-decoration: underline;
        cursor: pointer;
      }

      .flow-username {
        span {
          font-size: 24px;
          color: darkslateblue;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .flow-updated-at {
        //font-size: 12px;
      }
    }

    .first-scene-lead {
      //font-size: 12px;
    }

    .authorship-history {
      //font-size: 12px;

      &__author {
        color: darkslateblue;
        text-decoration: underline;
        cursor: pointer;
      }

      &__flow {
        color: darkslateblue;
        text-decoration: underline;
        cursor: pointer;
        font-style: italic;
      }
    }
  }
}

</style>
