// consider: constructor approach?
import * as Tone from "tone"
import tones from './tones'


export let AudioManager = {
  scenes: {},

  playerInstrument: null,
  playerGain: new Tone.Gain(),
  playerDelay: new Tone.FeedbackDelay(0, 0),
  playerDistortion: new Tone.Distortion(),
  playerAutoFilter: new Tone.AutoFilter("4n"),

  playerPolySynth: () => {
    // return new Tone.PolySynth(4, Tone.Synth) // deprecated
    return new Tone.PolySynth(Tone.Synth) // all better?
  },
  playerMonoSynth: () => {
    return new Tone.Synth()
  },

  instrument: (instrumentType, sampleType, track) => {
    let instrument = ''
    if (instrumentType === 'monoSynth'){
      instrument = new Tone.Synth()
      instrument.set({
        "oscillator" : {
          "type"     : track.waveType
        },
        "envelope"   : {
          "attack"   : track.attack ,
          "decay"    : track.decay,
          "sustain"  : track.sustain,
          "release"  : track.release,
        },
        "portamento" : track.portamento,
      })
    } else if (instrumentType === 'polySynth'){
    // instrument = new Tone.PolySynth(4, Tone.Synth) // deprecated
      instrument = new Tone.PolySynth(Tone.Synth) // all better?
      instrument.set({
        "oscillator" : {
          "type"     : track.waveType
        },
        "envelope"   : {
          "attack"   : track.attack ,
          "decay"    : track.decay,
          "sustain"  : track.sustain,
          "release"  : track.release,
        },
      })
    } else {
      switch (sampleType){
        case 'piano':
          instrument = new Tone.Sampler({
            "C2" : tones.piano1_C2,
            "G2" : tones.piano1_G2,
            "C3" : tones.piano1_C3,
            "G3" : tones.piano1_G3,
            "C4" : tones.piano1_C4,
            "G4" : tones.piano1_G4,
            "C5" : tones.piano1_C5,
            "G5" : tones.piano1_G5,
            "C6" : tones.piano1_C6,
          })
          break
        case 'elecPno1':
          instrument = new Tone.Sampler({
            "C1" : tones.elecPno1_C1,
            "C2" : tones.elecPno1_C2,
            "C3" : tones.elecPno1_C3,
            "C4" : tones.elecPno1_C4,
            "C5" : tones.elecPno1_C5,
            "C6" : tones.elecPno1_C6,
            "C7" : tones.elecPno1_C7,
          })
          break
        case 'elecPno2':
          instrument = new Tone.Sampler({
            "C1" : tones.elecPno2_C1,
            "C2" : tones.elecPno2_C2,
            "C3" : tones.elecPno2_C3,
            "C4" : tones.elecPno2_C4,
            "C5" : tones.elecPno2_C5,
            "C6" : tones.elecPno2_C6,
            "C7" : tones.elecPno2_C7,
          })
          break
        case 'elecPno3':
          instrument = new Tone.Sampler({
            "C2" : tones.elecPno3_C2,
            "C3" : tones.elecPno3_C3,
            "C4" : tones.elecPno3_C4,
            "C5" : tones.elecPno3_C5,
            "C6" : tones.elecPno3_C6,
            "C7" : tones.elecPno3_C7,
          })
          break
        case 'gtrSwell':
          instrument = new Tone.Sampler({
            urls: {
              "C2" : tones.gtrSwell_C2,
              "C3" : tones.gtrSwell_C3,
              "C4" : tones.gtrSwell_C4,
              "C5" : tones.gtrSwell_C5,
              "C6" : tones.gtrSwell_C6,
            },
            onload: () => {
              // console.log('>>> gtrSwell loaded');
            },
            // baseUrl: nope!
          })
          break
        case 'gtrMute':
          instrument = new Tone.Sampler({
            "C2" : tones.gtrMute_C1,
            "C3" : tones.gtrMute_C2,
            "C4" : tones.gtrMute_C3,
            "C5" : tones.gtrMute_C4,
            "C6" : tones.gtrMute_C5,
          })
          break
        case 'bassGtr':
          instrument = new Tone.Sampler({
            "C1" : tones.bassGtr_C1,
            "C2" : tones.bassGtr_C2,
            "C3" : tones.bassGtr_C3,
            "C4" : tones.bassGtr_C4,
            "E4" : tones.bassGtr_E4,
          })
          break
        case 'marimba':
          instrument = new Tone.Sampler({
            "C0" : tones.marimba_C0,
            "C1" : tones.marimba_C1,
            "C2" : tones.marimba_C2,
            "C3" : tones.marimba_C3,
            "C4" : tones.marimba_C4,
            "C5" : tones.marimba_C5,
            "C6" : tones.marimba_C6,
            "C7" : tones.marimba_C7,
          })
          break
        case 'strings':
          instrument = new Tone.Sampler({
            "C1" : tones.stringSection_C0,
            "C2" : tones.stringSection_C1,
            "C3" : tones.stringSection_C2,
            "C4" : tones.stringSection_C3,
            "C5" : tones.stringSection_C4,
            "C6" : tones.stringSection_C5,
            "C7" : tones.stringSection_C6,
          })
          break
        // case 'digiHarp':
        //   instrument = new Tone.Sampler({
        //     "C1" : "digiHarp_C0.mp3",
        //     "C2" : "digiHarp_C1.mp3",
        //     "C3" : "digiHarp_C2.mp3",
        //     "C4" : "digiHarp_C3.mp3",
        //     "C5" : "digiHarp_C4.mp3",
        //     "C6" : "digiHarp_C5.mp3",
        //     "C7" : "digiHarp_C6.mp3",
        //   }, {
        //     'baseUrl' : 'src/assets/tones/digiHarp_mp3s/'
        //   })
        //   break
      }
    }
    return instrument
  },
}
