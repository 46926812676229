<template>


  <div class="save-modal-background" @click="onClose">
    <div class="save-modal-inner" @click="$event.stopPropagation()">


      <div class="close-button" @click="onClose" >
        <img class="medium" src="@/assets/images/icons8-close-60-inverted.png" />
      </div>

      <h1>Save Options</h1>

      <div class="save-setting">
        <p>Flow Title:</p>
        <input
        class="flow-title"
          v-model="flowTitle"
        >
        <div
          class="random-flow-title"
          @click="pickRandomFlowTitle"
          title="Pick a random title for this flow"
        >
          <img class="small" src="../assets/images/icons8-dice-64.png" alt="Random Flow Title" />
        </div>
      </div>

      <button
        v-if="flow._id == null"
        class="save-button"
        @click="saveFlow"
      >
        Save
      </button>
      <button
        v-if="flow._id && (this.flowUserId === this.userId)"
        class="save-button"
        @click="updateFlow"
      >
        Update
      </button>

      <button
        v-if="flow._id && (this.flowUserId === this.userId)"
        class="save-button"
        @click="saveAs"
      >
        Save As
      </button>
      <button
        v-if="flow._id && (this.flowUserId !== this.userId)"
        class="save-button"
        @click="copyFlow"
      >
        Copy
      </button>

      <div class="public">
        <p class="public__text">Public?</p>
        <input class="public__checkbox" type="checkbox" v-model="public">
      </div>

      <br />
<!--      <button-->
<!--        class="reset-defaults"-->
<!--        @click="resetDefaults"-->
<!--      >-->
<!--        Reset-->
<!--      </button>-->


<!--      <div class="scene-setting">-->
<!--        <p>Lead track number:</p>-->
<!--        &lt;!&ndash; how does focus & enter work on select? it does work in the DOM https://jsfiddle.net/0eh6z947/ &ndash;&gt;-->
<!--        &lt;!&ndash; https://stackoverflow.com/questions/50434769/receive-event-object-in-vuex-v-model-setter?noredirect=1#comment87903913_50434769 &ndash;&gt;-->
<!--        &lt;!&ndash; note that v-model set is only called when the value changes. @keyup.enter="enterFunction($event)" doesn't work, is it possible to get enter to work on this some other way? For the moment looks like you'd need to use the global keyup listener (blur selects, possibly testing for active element as region)  &ndash;&gt;-->
<!--        <select-->
<!--          id="leadSelect"-->
<!--          class="lead-track-number"-->
<!--          v-model="leadTrackNumberAdjust"-->
<!--          @focus="focusFunction"-->
<!--          @change="enterFunction($event)"-->
<!--          @blur="enterFunction($event)"-->
<!--        >-->
<!--          <option-->
<!--            v-for="(track, index) in scene.tracks "-->
<!--            :key="Math.random().toString().slice(2)"-->
<!--          >{{ index+1 }}</option>-->
<!--        </select>-->
<!--      </div>-->


<!--      <div class="scene-setting">-->
<!--        <p>Lead track cycles to change scene:</p>-->
<!--        <input type="number" min="0"-->
<!--               v-model="chainAdvancePer"-->
<!--               @focus="focusFunction"-->
<!--               @keyup.enter="enterFunction($event)"-->
<!--               @blur="enterFunction($event)"-->
<!--        >-->
<!--      </div>-->


    </div>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import { randomElement } from "@/utils/helpers";
import * as CONSTANTS from "@/constants/constants"

export default defineComponent({
  name: "SaveModal",
  props: ['close', 'registerCombos', 'unregisterCombos'],
  data:() => ({
    // changeIncrements: ['Lead Cycle', 'Lead Change', 'Modulation', 'Form'],
  }),
  computed: {
    userId() {
      const userId = this.$store.state.auth.user._id
      // console.log('SaveModal userId:', userId);
      return userId;
    },
    flow(){
      console.log('>>>flow...', this.$store.state.flow);
      return this.$store.state.flow
    },
    flowUserId(){
      const flowUserId = this.$store.state.flow.userId
      // console.log('flowUserId', flowUserId);
      return flowUserId
    },
    flowTitle: {
      get(){
        return this.flow.flowTitle
      },
      set(title){
        this.$store.commit('updateFlowTitle', title)
      },
    },
    public: {
      get(){
        return this.flow.public
      },
      set(title){
        this.$store.commit('toggleFlowPublic', title)
      },
    },
    // leadTrackNumberAdjust: {
    //   get(){
    //     let leadTrackNumber = ''
    //     this.scene.tracks.forEach( (track, index) => {
    //       if (track.id === this.scene.leadTrackId) { leadTrackNumber = index }
    //     })
    //     return leadTrackNumber+1
    //   },
    //   set(value){
    //     this.$store.commit('updateLeadTrack', value-1 )
    //   },
    // },
    // chainAdvancePer: {
    //   get(){
    //     return this.scene.chainAdvancePer
    //   },
    //   set(value){
    //     // console.log(value)
    //     this.$store.commit('updateChainAdvancePer', value)
    //   },
    // },


  },
  methods: {
    pickRandomFlowTitle(){
      const newTitle = randomElement(CONSTANTS.SCENETITLES)
      this.$store.commit('updateFlowTitle', newTitle)
    },
    onClose(){
      this.$store.commit('changePreviousRegion', this.$store.state.flow.previousRegion)
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      this.registerCombos()
      this.close()
    },
    saveFlow() {
      this.$store.dispatch('createFlow', this.$props.close)
    },
    updateFlow() {
      this.$store.dispatch('updateFlow', this.$props.close)
    },
    saveAs() {
      this.$store.dispatch('saveAs', this.$props.close)
    },
    copyFlow() {
      this.$store.dispatch('copyFlow', this.$props.close)
    },
    // resetDefaults(){
    //   const doReset = window.confirm("This will wipe all data and reset defaults. Proceed?")
    //   console.log('doReset', doReset);
    //   this.$store.dispatch('resetDefaults')
    //   this.$props.close()
    // },
    // updateSelectedLength(e){
    //   this.$store.commit('updateSelectedLength', e.target.value)
    // },
    // fill(){
    //   this.$store.dispatch('fill')
    // },
    // distribute(){
    //   this.$store.dispatch('distribute')
    // },
  },
  mounted(){
    this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
    this.$store.commit('changeActiveRegion', 'bypass-dispatch')
    this.unregisterCombos()
  }
});
</script>

<style lang="scss">
@import "../assets/variables";

.save-modal-background {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3; // 2 causes keyboard keys to peek through. why? NoteSelectModal has z-index 2 does not.
  //background: #555;
  background-color: rgba(10, 39, 30, 0.8);

  .save-modal-inner {
    display: flex;
    flex-direction: column;
    background-color: rgb(10, 39, 30);
    position: relative;
    padding: 40px 40px 40px;
    text-align: left;
    color: #fff;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      img {
        width: 60px;
      }
    }

    button {
      cursor: pointer;
      width: 100px;
      margin-top: 10px;
    }

    .save-setting {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      p {
        font-size: 18px;
        margin-right: 10px;
        //width: 150px;
        color: white;

        &.reset-option-text {
          margin-right: 0;
        }
      }

      .random-flow-title {
        cursor: pointer;
        margin: 0 0 0 10px;

        img {
          width: 50px;
        }
      }

      input,
      select {
        font-size: 16px;
      }

      .flow-title {
        width: 250px;
        height: 24px;
      }

      //.lead-track-number {
      //  width: 60px;
      //  height: 24px;
      //}
      //
      //.reset-option {
      //  height: 20px;
      //}
    }

    .public {
      display: flex;
      align-items: center;

      &__text {
        color: white;
      }

      &__checkbox {
        margin-left: 10px;
      }
    }
  }
}

</style>
