<template>

    <div class="note-select-background" @click="close">

        <div class="note-select-inner" @click="$event.stopPropagation()">

          <div class="close-button" @click="close" >
            <img class="medium" src="@/assets/images/icons8-close-60-inverted.png" />
          </div>

          <div class="note-select">
            <!-- <select v-model="pitch"> -->
            <!-- <option v-for="pitch in pitchSet">{{ pitch }}</option> -->
            <!-- </select> -->
            <ul
              class="select-homemade"
            >
              <li
                :class="{selected: pitch === ' '}"
                @click="setPitch(' ')"
              >(rest)
              </li>
              <li
                v-for="(p, i) in pitchSet"
                :key="i+p"
                :class="{selected: p === pitch}"
                @click="setPitch(p)"
              >
                {{p}}
              </li>
              <li
                :class="{selected: pitch === ' '}"
                @click="setPitch(' ')"
              >(rest)
              </li>
            </ul>
          </div>

          <div class="note-shapes">
            <div class="note-demo-container">
              <p>Changeable</p>
              <div
                class="note-demo random-rests"
                :class="{randomType: note.random === 'rests'}"
                @click="setRandom('rests')"
              />
            </div>
            <div class="note-demo-container">
              <p>Changeable, no rests</p>
              <div
                class="note-demo random-no-rests"
                :class="{randomType: note.random === 'noRests'}"
                @click="setRandom('noRests')"
              />
            </div>
            <div class="note-demo-container">
              <p>Fixed</p>
              <div
                class="note-demo"
                :class="{randomType: note.random === 'fixed'}"
                @click="setRandom('fixed')"
              />
            </div>
            <div class="note-demo-container">
              <p class="p-delete">Delete</p>
              <div
                class="delete-note"
                @click="deleteNote"
              ><h1>X</h1></div>

            </div>
          </div>

        </div>

    </div>

</template>


<script>

// import TrackControls from './TrackControls.vue'


export default {
  name: 'note-select',

  props: ['noteData', 'close'],

  components: {},

  data () {
    return {}
  },

  computed: {

    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    track(){
      return this.scene.tracks[this.noteData.trackNumber]
    },
    note(){
      const note = this.track.tune[this.noteData.noteIndex]
      console.log('note', note)
      return note
    },
    pitch() {
      let pitch = this.note.pitch
      console.log('pitch', pitch)
      return pitch
    },
    pitchSet(){
      return this.$store.getters.pitchSets[this.noteData.trackNumber]

      console.log('pitchSet', pitchSet)
      return pitchSet
    },
  },

  methods: {
    setPitch(pitch) {
      console.log('pitch', pitch)
      this.$store.commit(
        'updateTrackNoteMobile',
        { trackNumber: this.noteData.trackNumber, noteIndex: this.noteData.noteIndex, pitch}
      )
    },
    setRandom(randomType) {
      this.$store.commit(
        'updateNoteRandomMobile',
        { trackNumber: this.noteData.trackNumber, noteIndex: this.noteData.noteIndex, randomType}
      )
    },
    deleteNote() {
      console.log('deleteNote');
      this.$store.commit('mDeleteNote', {
        trackNumber: this.noteData.trackNumber,
        noteIndex: this.noteData.noteIndex
      })

      this.close()
    },
    // enterFunction(event){
      // event.target.blur()
    // },
//    possiblyUpdateEditingTrackNumber: function(evt){
//      console.log("evt.draggedContext.futureIndex", evt.draggedContext.futureIndex)
//    },
  },
};

</script>


<style lang="scss">


.note-select-background {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  //background: #555;
  background-color: rgba(10, 39, 30, 0.8);


  .note-select-inner {
    display: flex;
    background-color: rgb(10, 39, 30);
    position: relative;
    padding: 20px;


    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      img {
        width: 60px;
      }
    }


    .note-select {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      /* background: #aaa; */
      margin-right: 20px;
      max-height: 70vh;
    }

    .note-select ul {
      overflow: auto;
      width: 100px;
      /*height: 80%;*/
      background: #abe;
      padding: 20px 20px 20px 25px;
      margin: 0;

      li {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
        cursor: pointer;

        &.selected {
          //background: black;
          color: white;
        }
      }
    }

    .note-shapes {
      display: flex;
      margin-top: 90px;

      flex-direction: column;
      /* user @media to align these horizonally for landscape */
      justify-content: flex-start;
      align-items: flex-start;

      .note-demo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        font-size: 20px;
        color: white;
        margin-bottom: 30px;

        &:last-of-type {
        margin-bottom: 0;
      }

        p {
          margin: 10px 0;
          // padding-bottom: 10px;
        }

        .note-demo {
          background-color: rgba(130, 110, 255, 0.6);
          width: 50px; height: 50px;
          /*padding: 10px 4px 0 0px; border: 1px solid black; margin: 0 3px 0 3px;*/
          vertical-align:top;
          text-align:center;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid grey;
          cursor: pointer;

          &.random-rests {
            border-radius: 50%
          }
          &.random-no-rests {
            border-radius: 30%
          }
          &.randomType {
            width: 60px; height: 60px;
            background: #abe;
          }
        }

        .p-delete {
          margin-bottom: 0;
        }

        .delete-note {
          font-size: 40px;
          font-weight: bold;
          cursor: pointer;

          h1 {
            margin: 0
          }
        }

      }
    }
  }
}


</style>
