<template>

    <div class="sound-panel">

      <div class="sound-panel-left">
        <div class="hide-n-fill">
          <div 
            class="random-fill" 
            v-on:click="fill"
            :title="showTitles ? 'Randomize all changeable notes' : ''"
          >
            Randomize
          </div>
<!--            <input-->
<!--              type="number" min="1"-->
<!--              v-bind:value="length"-->
<!--              v-on:change="updateSelectedLength"-->
<!--              @focus="focusFunction"-->
<!--              @keyup.enter="enterFunction"-->
<!--              @blur="enterFunction($event)"-->
<!--            >-->

          <div 
            class="random-distribute" 
            v-on:click='distribute' 
            :title="showTitles ? 'Re-build track with one of each highlighted pitch, rests determined by fill odds' : ''"
          >
            Distribute
          </div>

          <div 
            v-if="track.hidePitches" 
            class="toggle-hide-pitches" 
            @click="toggleHidePitches"
            :title="showTitles ? 'Hide pitches for ear training' : ''"
          >
            Show
          </div>
          <div 
            v-else class="toggle-hide-pitches" 
            @click="toggleHidePitches"
            :title="showTitles ? 'Hide pitches for ear training' : ''"
          >
            Hide
          </div>
        </div>

        <div class="instrument-select">
          <select 
            class="stacking-select" 
            v-model="instrumentType" 
            @focus="focusFunction" 
            @change="enterFunction($event)" 
            @blur="enterFunction($event)"
            :title="showTitles ? 'Select sampler or synthesizer type' : ''"
          >
            <option v-for="instrument in instrumentTypes">{{ instrument }}</option>
          </select>

          <select 
            class="stacking-select" 
            v-if="this.track.instrumentType === 'monoSynth' || this.track.instrumentType ==='polySynth'"
            v-model="waveType" 
            @focus="focusFunction" 
            @change="enterFunction($event)" 
            @blur="enterFunction($event)" 
            :title="showTitles ? 'What is the sound of this track?' : ''"
          >
            <option v-for="waveName in waveNameOptions" >{{ waveName }}</option>
          </select>
          <select 
            class="stacking-select" 
            v-else
            v-model="sampleType" 
            @focus="focusFunction" 
            @change="enterFunction($event)" 
            @blur="enterFunction($event)"
            :title="showTitles ? 'What is the sound of this track?' : ''"
          >
            <option v-for="sample in sampleOptions">{{ sample }}</option>
          </select>

          <select 
            class="stacking-select" 
            v-model="modulationType" 
            @focus="focusFunction" 
            @change="enterFunction($event)" 
            @blur="enterFunction($event)"
            v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                    (this.track.waveType === 'amsine'     || this.track.waveType === 'amtriangle' ||
                    this.track.waveType === 'amsawtooth' || this.track.waveType === 'amsquare'   ||
                    this.track.waveType === 'fmsine'     || this.track.waveType === 'fmtriangle' ||
                    this.track.waveType === 'fmsawtooth' || this.track.waveType === 'fmsquare' ) "
            :title="showTitles ? 'Wave type for amplitude or frequency modulation' : ''"
          >
            <option v-for="modulationType in modulationTypes">{{ modulationType }}</option>
          </select>
        </div>
      </div>


      <div class="gain-n-duration">
        <div class="sound-panel-controls">
          <div>Gain</div>
          <knob-control class="knob-control" v-model:value="gain"  :min="0" :max="2" :stepSize="0.01" :size="40" ></knob-control>
          <input :id="'trackGain-'+trackId" type="number" v-model.lazy="gain" :min="0" :max="2" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>


        <div class="sound-panel-controls">
          <div>Duration</div>
          <select v-model="durationRelative" @focus="focusFunction" @change="enterFunction($event)" @blur="enterFunction($event)">
            <option v-for="duration in relativeDurations">{{ duration }}</option>
          </select>
        </div>
        <!--
                <div class="sound-panel-controls">
                  <div>Duration</div>
                  <knob-control class="knob-control" v-model="duration"  :min="0" :max="4" :stepSize="0.1" :size="40" ></knob-control>
                  <input type="number" v-model.lazy="duration" :min="0" :max="4" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
                </div>
        -->
      </div>


      <div class="adsr">
        <div class="sound-panel-controls">
          <div>Attack</div>
          <knob-control class="knob-control" v-model:value="attack"  :min="0" :max="10" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="attack" :min="0" :max="10" :step="0.005" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
        <div class="sound-panel-controls">
          <div>Decay</div>
          <knob-control class="knob-control" v-model:value="decay"  :min="0" :max="10" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="decay" :min="0" :max="10" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
        <div class="sound-panel-controls">
          <div>Sustain</div>
          <knob-control class="knob-control" v-model:value="sustain"  :min="0" :max="1" :stepSize="0.05" :size="40" ></knob-control>
          <input type="number" v-model.lazy="sustain" :min="0" :max="1" :step="0.05" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
        <div class="sound-panel-controls">
          <div>Release</div>
          <knob-control class="knob-control" v-model:value="release"  :min="0" :max="10" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="release" :min="0" :max="10" :step="0.05" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
      </div>


      <div class="synth-specific">
        <div class="sound-panel-controls" v-if="this.track.instrumentType === 'monoSynth'">
          <div>Glide</div>
          <knob-control class="knob-control" v-model:value="portamento"  :min="0" :max="1" :stepSize="0.01" :size="40" ></knob-control>
          <input type="number" v-model.lazy="portamento" :min="0" :max="1" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls"
             v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                    (this.track.waveType === 'amsine'     || this.track.waveType === 'amtriangle' ||
                    this.track.waveType === 'amsawtooth' || this.track.waveType === 'amsquare'   ||
                    this.track.waveType === 'fmsine'     || this.track.waveType === 'fmtriangle' ||
                    this.track.waveType === 'fmsawtooth' || this.track.waveType === 'fmsquare' ) "
        >
          <div>Harmonicity</div>
          <knob-control class="knob-control" v-model:value="harmonicity"  :min="0" :max="4" :stepSize="0.001" :size="40" ></knob-control>
          <input type="number" v-model.lazy="harmonicity" :min="0" :max="4" :step="0.001" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls"
             v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                    (this.track.waveType === 'fmsine'     || this.track.waveType === 'fmtriangle' ||
                    this.track.waveType === 'fmsawtooth' || this.track.waveType === 'fmsquare' ) "
        >
          <div>Mod. Index</div>
          <knob-control class="knob-control" v-model:value="modulationIndex"  :min="0" :max="4" :stepSize="0.01" :size="40" ></knob-control>
          <input type="number" v-model.lazy="modulationIndex" :min="0" :max="4" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls"
             v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                    (this.track.waveType === 'fatsine'     || this.track.waveType === 'fattriangle' ||
                    this.track.waveType === 'fatsawtooth' || this.track.waveType === 'fatsquare' ) "
        >
          <div>Count</div>
          <knob-control class="knob-control" v-model:value="count"  :min="1" :max="10" :stepSize="1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="count" :min="1" :max="10" :step="1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls"
             v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                    (this.track.waveType === 'fatsine'     || this.track.waveType === 'fattriangle' ||
                    this.track.waveType === 'fatsawtooth' || this.track.waveType === 'fatsquare' ) "
        >
          <div>Spread</div>
          <knob-control class="knob-control" v-model:value="spread"  :min="0" :max="1200" :stepSize="1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="spread" :min="0" :max="1200" :step="1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls"
             v-if="(this.track.instrumentType === 'monoSynth' || this.track.instrumentType === 'polySynth') &&
                     this.track.waveType === 'pwm'">
          <div>Mod. Frequency</div>
          <knob-control class="knob-control" v-model:value="modulationFrequency"  :min="0" :max="100" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="modulationFrequency" :min="0" :max="100" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
      </div>


        <!-- EFFECTS **************************************************************************** -->

      <div class="delay">
        <div class="toggle-delay" v-if="delayActive" @click="toggleDelayActive">Delay On</div>
        <div class="toggle-delay" v-else @click="toggleDelayActive">Delay Off</div>

        <div class="sound-panel-controls" v-bind:class="{ greyOut: !delayActive }" >
          <div>Time</div>
          <knob-control class="knob-control" v-model:value="delayTime"  :min="0" :max="1" :stepSize="0.01" :size="40" ></knob-control>
          <input :id="'trackDelayTime-'+trackId" type="number" v-model.lazy="delayTime" :min="0" :max="1" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls" v-bind:class="{ greyOut: !delayActive }" >
          <div>Feed</div>
          <knob-control class="knob-control" v-model:value="delayFeedback"  :min="0" :max="1" :stepSize="0.01" :size="40" ></knob-control>
          <input type="number" v-model.lazy="delayFeedback" :min="0" :max="1" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
      </div>

        <!--        <div class="sound-panel-controls">-->
        <!--          <div>Distortion</div>-->
        <!--          <knob-control class="knob-control" v-model="distortion"  :min="0" :max="1" :stepSize="0.01" :size="40" ></knob-control>-->
        <!--          <input type="number" v-model.lazy="distortion" :min="0" :max="1" :step="0.01" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />-->
        <!--        </div>-->

        <!--        <div class="track-filter-rolloff-wrap">-->
        <!--            <div class="track-filter-n-rolloff">-->
        <!--              <div>Filter Type</div>-->
        <!--              <select v-model="trackFilterType" @focus="focusFunction" @change="enterFunction($event)" @blur="enterFunction($event)">-->
        <!--                <option v-for="filter in filterTypes">{{ filter }}</option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--            <div class="track-filter-n-rolloff">-->
        <!--              <div>Rolloff</div>-->
        <!--              <select v-model="trackFilterRolloff" @focus="focusFunction" @change="enterFunction($event)" @blur="enterFunction($event)">-->
        <!--                <option v-for="roll in rolloffs">{{ roll }}</option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--        </div>-->

      <div class="filter">
<!--        <div class="toggle-filter" v-if="filterActive" @click="toggleFilterActive">Filter On</div>-->
<!--        <div class="toggle-filter" v-else @click="toggleFilterActive">Filter Off</div>-->

        <div class="sound-panel-controls">
          <div>Filter %</div>
          <knob-control class="knob-control" v-model:value="trackFilterWet"  :min="0" :max="1" :stepSize="0.1" :size="40" ></knob-control>
          <input :id="'trackFilterWet-'+trackId" type="number" v-model.lazy="trackFilterWet" :min="0" :max="1" :step="0.1"  @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)"/>
        </div>

        <div class="sound-panel-controls">
          <div>Base</div>
          <knob-control class="knob-control" v-model:value="trackFilterBaseFrequency"  :min="50" :max="10000" :stepSize="50" :size="40" ></knob-control>
          <input type="number" v-model.lazy="trackFilterBaseFrequency" :min="50" :max="10000" :step="50" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>
        <!--        <div class="sound-panel-controls">-->
        <!--          <div>Q</div>-->
        <!--          <knob-control class="knob-control" v-model="trackFilterQ"  :min="0" :max="100" :stepSize="1" :size="40" ></knob-control>-->
        <!--          <input type="number" v-model.lazy="trackFilterQ" :min="0" :max="100" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />-->
        <!--        </div>-->

        <!--        <div class="sound-panel-controls">-->
        <!--          <div>LFO Wave</div>-->
        <!--          <select v-model="trackLFOWaveType" @focus="focusFunction" @change="enterFunction($event)" @blur="enterFunction($event)">-->
        <!--            <option v-for="wave in waveTypes">{{ wave }}</option>-->
        <!--          </select>-->
        <!--        </div>-->

        <div class="sound-panel-controls">
          <div>Frequency</div>
          <knob-control class="knob-control" v-model:value="trackLFOFrequency"  :min="0" :max="10" :stepSize="0.1" :size="40" ></knob-control>
          <input :id="'trackLFOFrequency-'+trackId" type="number" v-model.lazy="trackLFOFrequency" :min="0" :max="10" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls">
          <div>Depth</div>
          <knob-control class="knob-control" v-model:value="trackLFODepth"  :min="0" :max="1" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="trackLFODepth" :min="0" :max="1" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

        <div class="sound-panel-controls">
          <div>Octaves</div>
          <knob-control class="knob-control" v-model:value="trackLFOOctaves"  :min="0.1" :max="6" :stepSize="0.1" :size="40" ></knob-control>
          <input type="number" v-model.lazy="trackLFOOctaves" :min="0.1" :max="6" :step="0.1" @focus="focusFunction" @keyup.enter="enterFunction($event)" @blur="enterFunction($event)" />
        </div>

      </div>

    </div>

</template>


<script>

//import VueKnobControl from 'vue-knob-control'
import KnobControl from './KnobControl'
// import {bus} from '../main.js'


export default {

  name: "",

  props: ['trackNumber', 'trackId'],

  components: {
    //'knob-control': VueKnobControl,
    'knob-control': KnobControl,

  },

  data: () => ({
    instrumentTypes: ['monoSynth', 'polySynth', 'sampler'],
    sampleOptions: ['gtrSwell', 'gtrMute', 'bassGtr', 'piano', 'elecPno1', 'elecPno2', 'elecPno3', 'marimba', 'strings'], // 'digiHarp',
    waveNameOptions: [
      'sine','triangle','sawtooth','square', 'amsine','amtriangle','amsawtooth','amsquare',
    'fmsine','fmtriangle','fmsawtooth','fmsquare', 'fatsine','fattriangle','fatsawtooth','fatsquare', 'pwm',
],
    modulationTypes: ['sine', 'triangle', 'sawtooth', 'square'],
    relativeDurations: ['32n', '16n', '8n', '4n', '2n', '1m', '2m', '4m'],
    filterTypes: ['lowpass', 'highpass', 'bandpass', 'notch', 'allpass'],
    // waveTypes: ['sine', 'triangle', 'sawtooth', 'square'], // LFO
    rolloffs: ['-12','-24','-48','-96'],
  }),

  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    track(){
      return this.scene.tracks[this.trackNumber]
    },
    delayActive(){
      return this.track.delayActive
    },
    instrumentType: {
      get(){ return this.track.instrumentType },
      set(value){ this.$store.dispatch('changeTrackInstrumentOrSample', { param:'instrumentType', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    waveType: {
      get(){ return this.track.waveType },
      set(value){ this.$store.dispatch('changeTrackWave', { trackNumber: this.trackNumber, wave: value }) },
    },
    sampleType: {
      get(){ return this.track.sampleType },
      set(value){ this.$store.dispatch('changeTrackInstrumentOrSample', { param:'sampleType', trackNumber: this.trackNumber, value: value, track: this.track }) }
    },
    gain: {
      get(){
        // console.log('gain get value')
        return Math.round(this.track.gain*100)/100
      },
      set(value){
        // console.log('gain set value', value)
        this.$store.dispatch('updateTrackSoundParams', { param:'gain', trackNumber: this.trackNumber, value:value, track:this.track })
      },
    },
    portamento: {
      get(){ return Math.round(this.track.portamento*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'portamento', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    modulationType: {
      get(){ return this.track.modulationType },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'modulationType', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    harmonicity: {
      get(){ return Math.round(this.track.harmonicity*1000)/1000 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'harmonicity', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    modulationIndex: {
      get(){ return Math.round(this.track.modulationIndex*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'modulationIndex', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    count: {
      get(){ return this.track.count },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'count', trackNumber: this.trackNumber, track:this.track, value:value }) },
    },
    spread: {
      get(){ return Math.round(this.track.spread*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'spread', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    modulationFrequency: {
      get(){ return Math.round(this.track.modulationFrequency*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'modulationFrequency', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    delayTime: {
      get(){ return Math.round(this.track.delayTime*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'delayTime', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    delayFeedback: {
      get(){ return Math.round(this.track.delayFeedback*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'delayFeedback', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    // distortion: {
    //   get(){ return Math.round(this.track.distortion*100)/100 },
    //   set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'distortion', trackNumber: this.trackNumber, value:value, track:this.track }) },
    // },
    /*duration: {
      get(){ return Math.round(this.track.noteDuration*10)/10 },
      set(value){ this.$store.commit('changeTrackNoteDuration', { trackNumber: this.trackNumber, duration: value } ) },
    },*/
    durationRelative: {
      get(){ return this.track.noteDuration },
      set(value){ this.$store.commit('changeTrackNoteDuration', { trackNumber: this.trackNumber, duration: value } ) },
    },
    attack: {
      get(){ return Math.round(this.track.attack*1000)/1000 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'attack', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    decay: {
      get(){ return Math.round(this.track.decay*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'decay', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    sustain: {
      get(){ return Math.round(this.track.sustain*100)/100 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'sustain', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },
    release: {
      get(){ return Math.round(this.track.release*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { param:'release', trackNumber: this.trackNumber, value:value, track:this.track }) },
    },


    trackFilterWet: {
      get(){ return  Math.round(this.track.filterWet*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'filterWet', trackNumber: this.trackNumber, 'value':value }) },
    },

    // trackFilterType: {
    //   get(){ return  this.track.filterType },
    //   set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'filterType', trackNumber: this.trackNumber, 'value':value }) },
    // },
    // trackFilterRolloff: {
    //   get() { return this.track.filterRolloff },
    //   set(value) { this.$store.dispatch('updateTrackSoundParams', { 'param': 'filterRolloff', trackNumber: this.trackNumber, 'value': value }) }
    // },
    trackFilterBaseFrequency: {
      get(){ return  Math.round(this.track.filterBaseFrequency*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'filterBaseFrequency', trackNumber: this.trackNumber, 'value':value }) },
    },
    // trackFilterQ: {
    //   get(){ return  Math.round(this.track.filterQ*10)/10 },
    //   set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'filterQ', trackNumber: this.trackNumber, 'value':value }) },
    // },

    // trackLFOWaveType: {
    //   get(){ return  this.track.LFOWaveType },
    //   set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'LFOWaveType', trackNumber: this.trackNumber, 'value':value }) },
    // },
    trackLFOFrequency: {
      get(){ return Math.round(this.track.LFOFrequency*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'LFOFrequency', trackNumber: this.trackNumber, 'value':value }) },
    },
    trackLFODepth: {
      get(){ return  Math.round(this.track.LFODepth*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'LFODepth', trackNumber: this.trackNumber, 'value':value }) },
    },
    trackLFOOctaves: {
      get(){ return  Math.round(this.track.LFOOctaves*10)/10 },
      set(value){ this.$store.dispatch('updateTrackSoundParams', { 'param':'LFOOctaves', trackNumber: this.trackNumber, 'value':value }) },
    },

  },

  methods: {

    fill(){
      this.$store.dispatch('fill', this.trackNumber)
    },
    toggleHidePitches(){
      this.$store.commit('toggleHidePitches', this.trackNumber)
    },
    distribute(){
      this.$store.dispatch('distribute', this.trackNumber)
    },
    toggleDelayActive(){
      this.$store.commit('toggleTrackDelay', this.trackNumber)
    },
    focusFunction(){
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      this.$store.commit('changeActiveRegion', 'sound-panel-controls')
    },
    enterFunction(event){
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      // bus.$emit('clearKeyFromDown', 'Enter')
    },
  },


}
</script>


<style lang="scss">
@import "../assets/variables";

.sound-panel {
  margin: 3px 0 12px 0;
  display: flex;
  flex-wrap: wrap;

  .sound-panel-left {
    display: flex;
    font-size: 12px;
    width: 200px;
    max-height: 70px;
    border: 1px solid black;
    box-sizing: border-box;
    background: #bbb;
    //padding-top: 1px;

    .hide-n-fill {
      padding: 5px;
      //background: lightpink;
      display: flex;
      flex-direction: column;
      min-width: 100px;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      min-height: 100%;

      .random-fill,
      .random-distribute,
      .toggle-hide-pitches {
        border: 1px solid black;
        padding: 1px 3px;
        background: $accentMed1;
        cursor: pointer;
        text-align: start;
        box-sizing: border-box;
        margin-bottom: 2px;
        min-height: 18px;
      }

      .toggle-hide-pitches {
        //width: 37px;
      }
    }

    .instrument-select {
      padding: 5px;
      //background: lightblue;
      display: flex;
      flex-direction: column;
      min-width: 100px;
      box-sizing: border-box;
      justify-content: flex-start;

      select {
        min-height: 18px;
        font-size: 12px;
        border: 1px solid black;
        margin-bottom: 2px;
        padding: 0 3px;
      }

      .stacking-select {
        //display: block;
      }
    }
  }

  .sound-panel-controls {
    display: inline-block;
    //background: #cad;
    text-align: center;
    padding: 3px 0;
    margin-left: 1px;
    font-size: 10px;
    min-width: 50px;
    box-sizing: border-box;

    input, select {
      width: 40px;
      padding: 0;
      font-size:10px;
    }
  }

  .gain-n-duration,
  .adsr,
  .synth-specific,
  .delay,
  .filter,
  {
    border: 1px solid black;
    box-sizing: border-box;
    //background: lightblue;
    display: flex;
    margin: 0 3px 3px 0;
    min-height: 70px;
    background: #bbb;
  }

  .gain-n-duration {
    margin-left: 6px;
  }

  .toggle-delay,
  .toggle-filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 56px;
    text-align: center;
    background: $accentMed1;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 5px;
    margin: 10px 2px 10px 5px;
  }


  //.track-filter-rolloff-wrap {
  //  display: inline-block;
  //  padding: 1px;
  //
  //  .track-filter-n-rolloff {
  //    display: block;
  //    background: #866;
  //    text-align: center;
  //    padding: 2px;
  //    margin-left: 1px;
  //    font-size:12px;
  //  }
  //}
}



</style>
