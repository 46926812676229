
export function mapFlowData(flowData) {
  const flow = {}

  let activeRegion = flowData.active_region || flowData.activeRegion
  if (activeRegion === 'bypass-dispatch') activeRegion = 'tune-entry'

  flow.userId = flowData.user_id?._id || null
  flow.username = flowData.user_id?.username || null
  flow._id = flowData._id
  flow.flowTitle = flowData.flow_title || flowData.flowTitle
  flow.activeRegion = activeRegion
  flow.previousRegion = flowData.previous_region || flowData.previousRegion
  flow.chain = flowData.chain
  flow.chainLoop = flowData.chain_loop || flowData.chainLoop
  flow.editingSceneId = flowData.editing_scene_id || flowData.editingSceneId
  flow.editingSceneNumber = flowData.editing_scene_number || flowData.editingSceneNumber
  flow.entrySound = flowData.entry_sound || flowData.entrySound
  flow.sceneChangeNumber = flowData.scene_change_number || flowData.sceneChangeNumber
  flow.scenes = flowData.scenes
  flow.public = flowData.public
  flow.authors = flowData.authors
  flow.createdAt = flowData.createdAt || Date.now()
  flow.updatedAt = flowData.updatedAt || Date.now()
  // Save a copy of the original for various comparisons:
  flow.originalData = flowData

  return flow
}