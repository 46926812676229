import router from "@/router";
import { AudioManager as AM } from "@/audio/AudioManager"
import * as helpers from "@/utils/helpers"
import * as DATAMAPPING from "@/utils/dataMapping"
import STATEDEFAULTS from "../stateDefaults.js"
import * as CONSTANTS from "@/constants/constants"


// ***************************************************************************************************************
// GETTERS
// ***************************************************************************************************************

export const pitchSetFullRange = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let pitchSetFullRange = []
  for (let i=0; i < CONSTANTS.FULLRANGE.length; i++) {
    if (scene.selectedNotes.indexOf(CONSTANTS.FULLRANGE[i].slice(0,-1)) > -1) {
      pitchSetFullRange.push(CONSTANTS.FULLRANGE[i])
    }
  }
  return pitchSetFullRange
}


// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************


export const changeActiveRegion = (state, region) => {
  // console.log('changeActiveRegion:', region)
  state.flow.activeRegion = region
  // console.log('new activeReg', state.flow.activeRegion)
}

export const changePreviousRegion = (state, region) => {
  // console.log('changePreviousRegion:', region)
  state.flow.previousRegion = region
  // console.log('new prevReg', state.flow.previousRegion)
}

export const toggleEntrySound = state => {
  state.flow.entrySound = !state.flow.entrySound
}


export const updateFlowTitle = (state, title) => {
  state.flow.flowTitle = title
}

export const toggleFlowPublic = state => {
  state.flow.public = !state.flow.public
}

export const setFlowId = (state, id) => {
  console.log('setFlowId');
  state.flow._id = id
}

export const setFlowUserId = (state, userId) => {
  state.flow.userId = userId
}

export const resetFlow = state => {
  // console.log('resetFlow...');
  const stateDefaultsParsed = JSON.parse(JSON.stringify(STATEDEFAULTS))
  state.flow = stateDefaultsParsed
  // console.log('flow:', state.flow);
}

// BTW u should use this also on initialization
export const setNewFlow = (state, flow) => {
  // console.log('setNewFlow flow arg:', flow);
  // console.log('setNewFlow pre', state.flow);
  // state.flow = null // don't think that works

  // HEADS UP any flow property not assigned here will inherit from the previously persisted property
  //  ... is this still try with data mapping util? after all it's a new object
  // state.flow.userId = flow.user_id ? flow.user_id._id : null
  // state.flow.username = flow.user_id ? flow.user_id.username : null
  // state.flow._id = flow._id
  // state.flow.flowTitle = flow.flow_title
  // state.flow.activeRegion = flow.active_region
  // state.flow.previousRegion = flow.previous_region
  // state.flow.chain = flow.chain
  // state.flow.chainLoop = flow.chain_loop
  // state.flow.editingSceneId = flow.editing_scene_id
  // state.flow.editingSceneNumber = flow.editing_scene_number
  // state.flow.entrySound = flow.entry_sound
  // state.flow.sceneChangeNumber = flow.scene_change_number
  // state.flow.scenes = flow.scenes
  // state.flow.public = flow.public
  // state.flow.authors = flow.authors
  // Save a copy of the original for various comparisons:
  // state.flow.originalData = flow
  // console.log('hmm sewNewFlow dataMapping', DATAMAPPING);
  
  const mappedFlow = DATAMAPPING.mapFlowData(flow)
  // console.log('mappedFlow', mappedFlow);

  state.flow = mappedFlow


  // Unused:
  // sceneAdvanceCued: false
  // sceneAdvanceTriggered: false
  // sceneBench: []
  // Maybe:
  // updatedAt: "2022-01-22T17:53:12.616Z"
  // createdAt: "2022-01-22T17:53:12.616Z"
  // console.log('setNewFlow post', state.flow);

  // await router.push(`/users/${userID}`)
  router.push(`/${flow._id}`) // Note this can be redundant vs eg createFlow

  // On refresh, this is a strange blend of info from the DB and setup
  // activeRegion: "tune-entry"
  // active_region: "tune-entry"
  // chain: false
  // chainLoop: false
  // chain_loop: false
  // createdAt: "2022-01-22T17:53:12.616Z"
  // editingSceneId: ""
  // editingSceneNumber: 0
  // entrySound: true
  // entry_sound: true
  // flowTitle: "untitled"
  // flow_title: "stocking"
  // previousRegion: ""
  // previous_region: "tune-entry"
  // sceneAdvanceCued: false
  // sceneAdvanceTriggered: false
  // sceneBench: []
  // sceneChangeNumber: 0
  // scenes: (2) [{…}, {…}]
  // updatedAt: "2022-01-22T17:53:12.616Z"
  // userId: null
  // user_id: {_id: '6175802466fbc82fb3aeb82a', username: 'test1', email: 'test1@q.q', password: '$2b$12$ivqLMrDjW25Ia8l7Gsw.pug2YAgSuxqtmuhm8EW0E/MjxqyoODxC2', session_key: 'kfuftIRQbpZQkCqbbANWqxGVkwVpXQCiWuqc', …}
  // __v: 0
  // _id: "61ec44880c83f6563063334a"
}

// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

export const resetDefaults = context => {
  context.commit('resetFlow')

  context.dispatch('initializeApp')
}
