import ObjectID from 'bson-objectid';

import router from "@/router";
// import authAPI from SOMETHING-OR-OTHER

export const flows = {
  namespaced: false,
  state: {
    localStoredFlows: [],
  },
  getters: {},
  mutations: {
    addLocalFlow: (state, stringifiedFlow) => {
      state.localStoredFlows.push(stringifiedFlow)
    },
    updateLocalFlow: (state, flow) => {
      const flowId = flow._id
      const index = state.localStoredFlows.findIndex(e => JSON.parse(e)._id === flowId)
      const newFlows = [...state.localStoredFlows]
      newFlows[index] = JSON.stringify(flow)
      state.localStoredFlows = newFlows
    },
    deleteLocalFlow: (state, flowId) => {
      const index = state.localStoredFlows.findIndex(e => JSON.parse(e)._id === flowId)
      const newFlows = [...state.localStoredFlows]
      newFlows.splice(index, 1)
      state.localStoredFlows = newFlows
    }
  },

  actions: {
    loadFlow: async (context, id) => {
      console.log('action loadFLow:', id);

      const response = await fetch(`http://localhost:4000/flows/${id}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log('response:', response);

      if (!response.ok) {
        alert('Fetch error or no flow found with that id')
        await context.dispatch('resetDefaults')
        await router.push(`/`)
        // Error: Start time must be strictly greater than previous start time
          // ...which you can (wrongly) make go away by:
        window.location.reload()
        return
      }

      const resJSON = await response.json()
      console.log('resJSON', resJSON)

      context.commit('setNewFlow', resJSON.flow)
          // ctx.commit('login', data)
          // const { user: { id: userID} } = data
          // console.log('userID', userID)
          // await router.push(`/userIDs/${userID}`)
        // .catch(err => {
        //   console.log('err:', err)
        // })
    },

    loadFlowLocal: async (context, id) => {
      // console.log('action loadFLowLocal:', id);
      let flow = context.state.localStoredFlows.find(e => {
        return JSON.parse(e)._id === id
      });

      if (!flow) {
        alert('No flow found with that id')
        await context.dispatch('resetDefaults')
        await router.push(`/`)
        // Error: Start time must be strictly greater than previous start time
          // ...which you can (wrongly) make go away by:
        window.location.reload()
        return
      }

      flow = JSON.parse(flow)

      context.commit('setNewFlow', flow)
    },

    createFlow: async (context, closeSaveModal) => {
      console.log('action createFlow')
      const userId = context.rootState.auth.user._id
      console.log('user', userId);
      context.commit('setFlowUserId', userId)

      const flow = {...context.rootState.flow}
      console.log('flow:', flow)
      const titleCheckResponse = await fetch(`http://localhost:4000/flows/check-title?user_id=${userId}&flow_title=${flow.flowTitle}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log('titleCheckResponse', titleCheckResponse);
      const titleCheck = await titleCheckResponse.json()
      console.log('titleCheck', titleCheck);

      if (titleCheck.flowWithThatTitleExists) {
        alert('You already have a flow with that title.')
        return
      }

      flow.activeRegion = 'tune-entry' // SaveModal sets it to bypass-dispatch
      const flowStringified = JSON.stringify(flow)
      console.log('flowStringified', flowStringified)

      fetch(`http://localhost:4000/flows/create`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: flowStringified,
      })
      .then(res => res.json())
      .then(async data => {
        console.log('fetched data', data)
        const flowId = data._id
        // context.commit('setFlowId', flowId)
        await router.push(`/${flowId}`)
        closeSaveModal()
        context.dispatch('loadFlow', flowId)
        alert('Flow Saved')
      })
      .catch(err => console.log(err))
    },

    createFlowLocal: async (context, closeSaveModal) => {
      // console.log('action createFlowLocal')
      const flow = {...context.rootState.flow}
      const flowId = new ObjectID().toHexString()
      flow._id = flowId
      flow.createdAt = (new Date).toISOString()
      flow.updatedAt = (new Date).toISOString()

      flow.activeRegion = 'tune-entry' // SaveModal sets it to bypass-dispatch
      const flowStringified = JSON.stringify(flow)

      context.commit('addLocalFlow', flowStringified)

      closeSaveModal()
      
      await router.push(`/${flowId}`)
    },

    updateFlow: async (context, closeSaveModal) => {
      console.log('action updateFlow')

      const flow = {...context.rootState.flow}
      console.log('flow:', flow)
      flow.activeRegion = 'tune-entry' // SaveModal sets it to bypass-dispatch
      const flowStringified = JSON.stringify(flow)
      console.log('flowStringified', flowStringified)

      fetch(`http://localhost:4000/flows/${flow._id}`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: flowStringified,
      })
      .then(res => res.json())
      .then(async data => {
        console.log('fetched data', data)
        closeSaveModal()
        alert('Flow Updated')
      })
      .catch(err => console.log(err))
    },

    updateFlowLocal: async (context, closeSaveModal) => {
      const flow = {...context.rootState.flow}

      flow.activeRegion = 'tune-entry' // SaveModal sets it to bypass-dispatch
      context.commit('updateLocalFlow', flow)

      closeSaveModal()
      alert('Flow Updated')
    },

    saveAs: async (context, closeSaveModal) => {
      console.log('action saveAs')

      const userId = context.rootState.auth.user._id
      console.log('user', userId);
      context.commit('setFlowUserId', userId)

      const flow = context.rootState.flow
      console.log('flow:', flow)
      const titleCheckResponse = await fetch(`http://localhost:4000/flows/check-title?user_id=${userId}&flow_title=${flow.flowTitle}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log('titleCheckResponse', titleCheckResponse);
      const titleCheck = await titleCheckResponse.json()
      console.log('titleCheck', titleCheck);

      let newTitle = ''
      if (titleCheck.flowWithThatTitleExists) {
        newTitle = window.prompt('You already have a flow with this title. Please pick a new one:')
        if (newTitle == null) return
        context.commit('updateFlowTitle', newTitle)
      }

      context.commit('setFlowId', null)

      const flowStringified = JSON.stringify(flow)
      console.log('flowStringified', flowStringified)

      fetch(`http://localhost:4000/flows/create`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: flowStringified,
      })
        .then(res => res.json())
        .then(async data => {
          console.log('fetched data', data)
          const flowId = data._id
          // context.commit('setFlowId', data._id)
          await router.push(`/${flowId}`)
          closeSaveModal()
          context.dispatch('loadFlow', flowId)
          alert('New Flow Saved')
        })
        .catch(err => console.log(err))
    },

    // NOTE copy is for multi-user situation. 
    // ie: copy someone else's flow. so no need for locally.
    copyFlow: async (context, closeSaveModal) => {
      console.log('action copyFlow')

      // insist on flow title change?

      // Todo move to later on, this sets your id but method could fail later
      const userId = context.rootState.auth.user._id
      console.log('user', userId);
      context.commit('setFlowUserId', userId)

      const flow = {...context.rootState.flow}
      console.log('flow:', flow)
      const titleCheckResponse = await fetch(`http://localhost:4000/flows/check-title?user_id=${userId}&flow_title=${flow.flowTitle}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log('titleCheckResponse', titleCheckResponse);
      const titleCheck = await titleCheckResponse.json()
      console.log('titleCheck', titleCheck);

      if (titleCheck.flowWithThatTitleExists) {
        alert('You already have a flow with that title.')
        return
      }

      const { originalData } = flow
      console.log('>>originalData', originalData);
      const authorData = {
        authorName: originalData.user_id.username,
        authorId: originalData.user_id._id,
        flowTitle: originalData.flow_title,
        flowId: originalData._id,
      }
      console.log('authorData', authorData);
      const updatedAuthors = [...flow.authors, authorData]
      // console.log('typeof updatedAuthors', isArray updatedAuthors)
      console.log('updatedAuthors', updatedAuthors);
      flow.authors = updatedAuthors

      const flowStringified = JSON.stringify(flow)

      fetch(`http://localhost:4000/flows/create`, {
        method: 'POST',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: flowStringified,
      })
        .then(res => res.json())
        .then(async data => {
          console.log('fetched data', data)
          const flowId = data._id
          // context.commit('setFlowId', data._id)
          await router.push(`/${flowId}`)
          closeSaveModal()
          context.dispatch('loadFlow', flowId)
          alert('Flow Copied')
          // ctx.commit('login', data)
          // const { user: { id: userID} } = data
          // console.log('userID', userID)
          // await router.push(`/userIDs/${userID}`)
        })
        .catch(err => console.log(err))
    },

    deleteFlow: async (context, id) => {
      // console.log('action deleteFlow:', id);

      let deletedFlowData;

      await fetch(`http://localhost:4000/flows/${id}`, {
        method: 'DELETE',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(async data => {
          console.log('deleted flow data', data)

          deletedFlowData = data
          // context.commit('setNewFlow', data.flow)
          // ctx.commit('login', data)
          // const { user: { id: userID} } = data
          // console.log('userID', userID)
          // await router.push(`/userIDs/${userID}`)
        })
        .catch(err => console.log(err))

      console.log('awaited?');
      return deletedFlowData
    },

    deleteFlowLocal: async (context, flowId) => {
      // console.log('action deleteFlowLocal:', flowId);
      context.commit('deleteLocalFlow', flowId)
    },
  },


};
