
export const generalPersist = {
  namespaced: false,
  state: {
    showModeOptions: false,
    showTutorial: false,
    tutorialStep: 0,
    showTitles: true,
  },

  getters: {},
  mutations: {
    toggleShowModeOptions: state => {
      state.showModeOptions = !state.showModeOptions
    },
    toggleShowTutorial: state => {
      console.log('toggleShowTutorial pre', state.showTutorial);
      state.showTutorial = !state.showTutorial
      console.log('toggleShowTutorial post', state.showTutorial);
    },
    changeTutorialStep: (state, change) => {
      if (change === 'increment') {
        state.tutorialStep = state.tutorialStep + 1
      } else if (change === 'decrement') {
        if (state.tutorialStep > 0)
        state.tutorialStep = state.tutorialStep - 1
      }
    },
  },

  actions: {},
}

