import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

import moment from "moment";

// import "./assets/reset.css";
import "./assets/normalize.css";
import './assets/global.scss'

const app = createApp(App).use(store)
app.use(router)
app.config.globalProperties.$filters = {
  dateFormattedBasic(date) {
    return moment(date).format('MMM Do YYYY')
  }
}

app.mount("#app");


