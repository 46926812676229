<template>


  <div class="modulation-controls">


    <div class="harmonic-form-wrap">

      <div
        v-if="this.scene.modulationStyle === 'drift'"
        class="modulation-style"
        v-on:click="toggleModulationStyle"
        :title="showTitles ? 'Modulation style: Drift' : ''"
      >
<!--        :class="{ greyOut: this.scene.suspendChanges }"-->
        <img
          src="@/assets/images/icons8-paper-ship-50.png"
          alt="Modulation style: Drift"
        />
      </div>
      <div
        class="modulation-style"
        v-else
        v-on:click="toggleModulationStyle"
        :title="showTitles ? 'Modulation style: Form' : ''"
      >
<!--        :class="{ greyOut: this.scene.suspendChanges }"-->
        <img
          src="@/assets/images/icons8-form-50.png"
          alt="Modulation style: Drift"
        />
      </div>

      <div class="harmonic-form">
        <input
          v-show="scene.editingForm" class="harmonic-form-regex" type="text"
          ref="formInput"
          :value="harmonicFormString"
          @focus="focusFunction"
          @keyup.enter="handleHarmonicFormEntry($event)"
          @blur="handleHarmonicFormEntry($event)"
          placeholder="Enter modulation form, eg: -a -b -a -c"
          spellcheck="false"
        />
        <div v-if="!scene.editingForm" class="harmonic-form-display" @click="toggleEditingForm">
          <span v-if="harmonicFormArray.length === 0">Enter modulation form, eg: -a -b -a -c</span>
          <span
            class="harmonic-form-step" v-for="(step, index) in harmonicFormArray"
            v-if="harmonicFormArray.length > 0"
          >
          <span :class="{ currentFormStep: scene.formStep === index }">{{ step }}</span>,
        </span>
        </div>
      </div>
    </div>


    <div class="modulation-controls__bottom">

      <div class="modulation-controls__left">

        <div class="mode-advance-container">
          <div class="mode-advance">
            <div 
              class="mode-advance-meter"
              :title="showTitles ? 'Progress till next mode' : ''"
            >
              <div v-if="this.scene.autoModulate && !this.scene.suspendChanges" v-bind:style="{ width: progressTillChange + '%' }"></div>
            </div>
            <div class="mode-advance-arrow">
              <img src="@/assets/images/icons8-arrow-thin-30.png" alt="play" />
            </div>
            <div 
              class="mode-advance-title"
              :title="showTitles ? 'Next mode' : ''"
            >
              <div v-if="this.nextModulation != '' && !(this.scene.autoModulate === false && this.scene.modulationStyle==='drift')">
                {{ nextModulation }}
              </div>
            </div>
          </div>
          </div>

        <div class="modulation-options">
          <div
              v-if="this.scene.modulationStyle==='drift'"
              class="modulate"
              @click="morphSelectedNotes"
              :title="showTitles ? 'Modulate note options' : ''"
          >
            <img src="@/assets/images/icons8-treble-clef-52.png" alt="Modulate"/>
          </div>
          <div
            v-else
            class="modulate"
            @click="morphSelectedNotes"
            :title="showTitles ? 'Modulate note options' : ''"
          >
            <img src="@/assets/images/icons8-advance-48.png" alt="Modulate"/>
          </div>

          <div
            v-if="this.scene.autoModulate"
            class="auto"
            @click="autoModulate('off')"
            :title="showTitles ? 'Auto-modulation on' : ''"
          >
            <img
              :class="{ greyOut: this.scene.suspendChanges }"
              src="@/assets/images/icons8-people-in-car-side-view-96-dark.png"
              alt="Automatic Modulation On"
            />
          </div>
          <div
            v-else
            class="auto"
            @click="autoModulate('on')"
            :title="showTitles ? 'Auto-modulation off' : ''"
          >
            <img
              :class="{ greyOut: this.scene.suspendChanges }"
              src="@/assets/images/icons8-people-in-car-side-view-96.png"
              alt="Automatic Modulation Off"
            />
          </div>

          <div
            class="modulate-per-lead-changes"
            :class="{ greyOut: !this.scene.autoModulate }"
            :title="showTitles ? '# of Lead Track changes to trigger auto-modulation' : ''"
          >
            <span>per</span>
            <input
              id="modulatePerLeadChanges"
              type="number"
              min="1"
              v-model="modulatePerLeadChanges"
              @focus="focusFunction"
              @keyup.enter="enterFunction"
            />
          </div>

          <div
            v-if="filterPitchesOnChange"
            class="filter"
            v-on:click="toggleFilterOnChange"
            :title="showTitles ? 'Filter pitches on modulation' : ''"
          >
            <img
              :class="{ greyOut: this.scene.suspendChanges }"
              src="@/assets/images/icons8-filter-96-full.png"
              alt="Filter pitches on modulation"
            />
          </div>
          <div
            class="filter"
            v-else
            v-on:click="toggleFilterOnChange"
            :title="showTitles ? 'Don\'t filter pitches on modulation' : ''"
          >
          <img
            :class="{ greyOut: this.scene.suspendChanges }"
            src="@/assets/images/icons8-filter-96.png"
            alt="Filter pitches on modulation"
          />
          </div>

          <div
            class="toggle-mode-options"
            v-if="this.showModeOptions"
            @click="toggleShowModeOptions"
            :title="showTitles ? 'Close mode options panel' : ''"
          >
            <img src="../assets/images/icons8-ellipsis-100-dark.png" />
          </div>
          <div
            class="toggle-mode-options"
            v-else @click="toggleShowModeOptions"
            :title="showTitles ? 'Open mode options panel' : ''"
          >
            <img class="small" src="../assets/images/icons8-ellipsis-100-light.png" />
          </div>

        </div>

      </div>

      <div class="modulation-controls__right">
<!--        MODE OPTIONS-->
        <ModeOptions v-if="this.showModeOptions" />
      </div>

    </div>


  </div>

</template>


<script>
import * as helpers from "@/utils/helpers"
import ModeOptions from "@/components/ModeOptions"
// import {bus} from '../main.js'


export default {
  components: {
    ModeOptions,
  },
  data(){
    return {
    }
  },
  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    progressTillChange(){
      // is this expensive? Maybe there is a way to save some calc time?
      let leadTrack = this.scene.tracks[this.$store.getters.leadTrackNumber]
      // console.log('dash tTI', leadTrack.toneTuneIndex)
      let leadTuneLength = this.$store.getters.toneTunes[this.$store.getters.leadTrackNumber].length
      if (leadTuneLength === 0) { return 0}
      let stepsNeeded = leadTrack.changePer * leadTuneLength * this.scene.modulatePerLeadChanges
      let currentChangeCycleSteps = leadTrack.changeCycles * leadTuneLength
      let modCycleSteps = this.scene.modulationCycles * leadTrack.changePer * leadTuneLength
      let stepsByCycleCount = modCycleSteps + currentChangeCycleSteps + leadTrack.toneTuneIndex
      let modProgBarDisplayCount = (this.scene.modulationCycles === 0 && leadTrack.changeCycles === 0 &&
        leadTrack.toneTuneIndex === 0 && this.scene.started)
        ? stepsNeeded : stepsByCycleCount
      let progress = modProgBarDisplayCount / stepsNeeded * 100
      return progress <= 100 ? progress :  100 // if modulatePerLeadChanges is dropped during the cycle, stepsSoFar may exceed stepsNeeded
    },
    nextModulation(){
      let modeInfo = this.scene.nextModulation
      //console.log('modeInfo', modeInfo)
      if (modeInfo === '') return ''
      let mod = ''
      switch (modeInfo.modulation) {
        case 'dia': mod = 'diatonic'; break;
        case 'mel': mod = 'melodic min'; break;
        case 'har': mod = 'harmonic min'; break;
        case 'dim': mod = 'diminished'; break;
        case 'aug': mod = 'augmented'; break;
        case 'chr': mod = 'chromatic'; break;
        case 'maj': mod = 'major triad'; break;
        case 'min': mod = 'minor triad'; break;
        case 'sus': mod = 'suspended triad'; break;
        case 'ma7': mod = 'major 7'; break;
        case 'dom': mod = 'dominant 7'; break;
        case 'mi7': mod = 'minor 7'; break;
        case 'hdm': mod = 'half-diminshed'; break;
        case 'dm7': mod = 'diminished 7'; break;
        case 'blu': mod = 'blues'; break;
        case 'pen': mod = 'pentatonic'; break;
        case 'fth': mod = 'fifth'; break;
        case 'one': mod = '(one note)'; break;
      }
      let modulation = modeInfo.modeBase + ' ' + mod
      if (modulation.charAt(1) === 's') { modulation = helpers.spliceSlice(modulation, 1, 1, '#') }
      return modulation
    },
    modulatePerLeadChanges: {
      get(){
        return this.scene.modulatePerLeadChanges
      },
      set(value){
        this.$store.commit('updateModulatePerLeadChanges', value)
      },
    },
    filterPitchesOnChange(){
      return this.scene.filterPitchesOnChange
    },
    harmonicFormString(){
      let form = this.scene.harmonicForm
      // console.log('harmonicFormString form', form)
      return form.join(', ')
      //return "working on it..."
    },
    harmonicFormArray(){
      return this.scene.harmonicForm
  },
    showModeOptions(){
      return this.$store.state.generalPersist.showModeOptions // formerly show modulation options
    }
  },

  methods: {
    morphSelectedNotes(){
      this.$store.dispatch('morphSelectedNotes', true)
    },
    autoModulate(onOrOff){
      this.$store.dispatch('autoModulate', onOrOff)
    },
    toggleSuspendChanges(){
      this.$store.commit('toggleSuspendChanges')
    },
    toggleFilterOnChange(){
      this.$store.commit('toggleFilterOnChange')
    },
    toggleShowModeOptions(){ // formerly show modulation options
      this.$store.commit('toggleShowModeOptions')
    },
    focusFunction(){
      // console.log("focussing")
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      this.$store.commit('changeActiveRegion', 'scene-dashboard')
    },
    enterFunction(event){
      // console.log('in SceneDashBoard enterFunction')
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      // bus.$emit('clearKeyFromDown', 'Enter')
    },
    handleHarmonicFormEntry(e){
      this.$store.dispatch('buildHarmonicForm', e.target.value)
      this.enterFunction(e)  // this will blur the input, which may redundantly call this function again. so for now we need this if:
      if (this.scene.editingForm) { this.toggleEditingForm() }
    },
    toggleModulationStyle(){
      this.$store.dispatch('toggleModulationStyle')
    },
    toggleEditingForm(){
      this.$store.commit('toggleEditingForm')
      if (this.scene.editingForm) {
        // https://forum.vuejs.org/t/how-to-set-focus-to-input/10672/7 // using just 'formInput', I think this is ok because only one scene is rendered at a time.
        this.$nextTick(() => this.$refs.formInput.focus())
      }
    },
  },

}

</script>


<style lang="scss">


.modulation-controls {
  //background: #667;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  margin-left: 0;
  min-width: 200px;
  width: 100%;
  height: 100%;

  .harmonic-form-wrap {
    width: 100%;
    //border: 1px solid black;
    display: flex;

    .modulation-style {
      cursor: pointer;
      //margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
      }
    }

    .harmonic-form {
      margin-left: 5px;
      width: 100%;
      background: chocolate;

      .harmonic-form-regex {
        //margin: 3px;
        padding: 2px;
        //min-width: 600px;
        background: linear-gradient(270deg, #cccccc 0%, #eeeeee 35%, white 100%);
        font-family: helvetica;
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
        width: 100%;
      }

      .harmonic-form-display {
        //margin: 3px;
        padding: 4px;
        //min-width: 600px;
        background: linear-gradient(270deg, #cccccc 0%, #eeeeee 35%, white 100%);
        font-family: helvetica;
        font-size: 14px;
        color: grey;
        box-sizing: border-box;

        .harmonic-form-step {
          color: black;
        }

        .currentFormStep {
          background: #bcd;
          font-weight: bold;
          color: black;
          text-decoration: underline;
        }
      }
    }
  }


  .modulation-controls__bottom {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 5px;
    //background: orange;
    height: calc(100% - 24px);

    .modulation-controls__left {
      display: grid;
      grid-template-rows: 1fr 1fr;

      .mode-advance-container {
        display: flex;
        align-items: center;

        .mode-advance {
          display: flex;
          height: 16px;

          .mode-advance-meter {
            width: 90px;
            min-height: 14px;
            border: 1px solid #000;
            /*  margin: 0 auto 20px auto; */
            box-sizing: border-box;

            div {
              height: 14px;
              background: blue;
            }
          }

          .mode-advance-arrow {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 15px;
            }
          }

          .mode-advance-title {
            display: flex;
            align-items: center;
            width: 90px;
            min-height: 14px;
            border: 1px solid #000;
            box-sizing: border-box;
            padding: 0 0 0 2px;

            div {
              font-size: 12px;
              overflow: hidden;
            }
          }
        }
      }


      .modulation-options {
        padding: 5px;
        //background: #877;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .modulate-per-lead-changes {
          font-size: 12px;
          display: inline-block;

          span {
            margin-right: 2px;
          }

          input {
            width: 35px;
          }
        }

        .auto,
        .modulate,
        .filter,
        .toggle-mode-options {
          cursor: pointer;
          //margin: 0 5px;

          img {
            width: 20px;
          }
        }
      }
    }

    .modulation-controls__right {
      height: 100%;
      //background: forestgreen;

    }
  }
}

</style>
