import * as Tone from "tone"

import * as helpers from "@/utils/helpers"
import * as constants from "@/constants/constants.js"
import { MODEDATA } from "@/constants/modeData"


// ***************************************************************************************************************
// GETTERS
// ***************************************************************************************************************


// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************

export const updateSelectedNotes = (state, note) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  const noteIndex = scene.selectedNotes.indexOf(note);
  if (noteIndex === -1) { scene.selectedNotes.push(note) }
  else {
    if (scene.selectedNotes.length > 1)
      scene.selectedNotes.splice(noteIndex, 1)
  }
}

export const changeTempo = (state, e) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.bpm = e.target.value.toString()
  Tone.Transport.bpm.value = scene.bpm
}

export const resetScene = (state) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  // editingTrackNumber, editingTrackId, editingIndex - all left alone
  scene.chainIncrement = 0
  scene.tracks.forEach( (track, index) => {
    track.toneTuneIndex = 0
    track.changeCycles = 0
    track.changeTriggered = false
  })
  scene.started = false
  scene.modulationCycles = 0
  scene.modulationTriggered = false
  state.sceneAdvanceTriggered = false
  // ?scene.formStep = 0 // scene.formStep = 1 ?
}

export const rememberTune = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let track = scene.tracks[trackNumber]
  let remembering = JSON.parse(JSON.stringify(track.tune))
  if (remembering[remembering.length-1].pitch === '+') { remembering.pop() }
  track.rememberedTune = remembering
}

export const tuneReturn = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let track = scene.tracks[trackNumber]
  let remembered = JSON.parse(JSON.stringify(track.rememberedTune))
  if (trackNumber === scene.editingTrackNumber) {
    // console.log(JSON.parse(JSON.stringify(constants.ENDCAP)))
    remembered.push(JSON.parse(JSON.stringify(constants.ENDCAP)))
  }
  track.tune = remembered
}

export const toggleSuspendChanges = state => {
  // console.log('mutation tSC');
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (scene.suspendChanges){
    scene.suspendChanges = false
  } else {
    scene.suspendChanges = true
    scene.tracks.forEach( track => {
      track.changeCycles = 0
    })
  }
}

export const updateAutoModulate = (state, onOrOff) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (onOrOff === 'on') { scene.autoModulate = true }
  else if (onOrOff === 'off') { scene.autoModulate = false }
}

export const updateModulatePerLeadChanges = (state, value) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.modulatePerLeadChanges = value
}

export const toggleFilterOnChange = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.filterPitchesOnChange = !scene.filterPitchesOnChange
}

export const updateHarmonicForm = (state, harmonicForm) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.harmonicForm = harmonicForm
}

export const updateModulationStyle = (state, style) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.modulationStyle = style
}

export const updateSelectedRootPitches = (state, rootPitch) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (rootPitch === 'clear') {
    scene.selectedRootPitches = []
  } else if (rootPitch === 'all') {
    scene.selectedRootPitches = ['C','G','D','A','E','B','Fs','Cs','Gs','Ds','As','F']
  } else {
    let rootPitchIndex = scene.selectedRootPitches.indexOf(rootPitch)
    if (rootPitchIndex === -1) { scene.selectedRootPitches.push(rootPitch) }
    else {
      if (scene.selectedRootPitches.length > 1)
        scene.selectedRootPitches.splice(rootPitchIndex, 1)
    }
  }
}

export const updateModulationWeights = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (payload.value === 'increment') {
    scene.modulationWeights[payload.modulationType]++
  } else {
    scene.modulationWeights[payload.modulationType] = payload.value
  }
  console.log('uMW payload', payload);
  console.log('scene.modulationWeights[payload.modulationType]', scene.modulationWeights[payload.modulationType])
}

export const toggleEditingForm = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.editingForm = !scene.editingForm
}


// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

export const rememberAllTunes = context => {
  // console.log('rememberAll');
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  for (let i=0; i < scene.tracks.length; i++){
    context.commit('rememberTune', i)
  }
}

export const returnAllTunes = context => {
  // console.log('returnAll');
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  for (let i=0; i < scene.tracks.length; i++){
    context.commit('tuneReturn', i)
  }
}

export const autoModulate = (context, onOrOff) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if ( onOrOff === 'on') {
    // console.log(context.getters.selectedModulations.indexOf(scene.nextModulation.modulation) )
    if  (scene.modulationStyle === 'drift' && ( scene.nextModulation.modulation === '' ||
      context.getters.selectedModulations.indexOf(scene.nextModulation.modulation) === -1  )) {  // here scene.nextModulation is checked in two different conditions, but it's a bit redundant because the second will also catch the first...
      // console.log('in drift && ')
      // DRY re: morphSelectedNotes, toggleModulationStyle
      let type = helpers.randomElement(context.getters.selectedModulations)
      let modeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
      // console.log("automodulate modeInfo", modeInfo)
      context.commit('updateNextModulation', modeInfo)
    } else if (scene.modulationStyle === 'form') {
      // console.log('automodulate form section...')
    }
  }
  context.commit('updateAutoModulate', onOrOff)
}

export const toggleModulationStyle = context => {
  console.log('dispatching toggleModulationStyle');
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (scene.modulationStyle === 'drift') {
    if (scene.harmonicForm.length === 0) {
      console.log('scene.harmonicForm.length === 0');
      context.dispatch('buildHarmonicForm', constants.HARMONIC_FORM_DEFAULT) // -a-b-a-c
    }
    // a 'setUpForForm' action may be useful... is this procedure used somewhere else...
    let firstFormSection = helpers.referenceMode(MODEDATA, scene.harmonicForm[0])
    console.log('firstFormSection', firstFormSection)
    context.commit('updateNextModulation', firstFormSection)
    context.commit('updateModulationStyle', 'form')
  } else if (scene.modulationStyle === 'form'){
    context.commit('updateFormStep', 'off')
    // DRY re: morphSelectedNotes, autoModulate:
    console.log("nextModulation", scene.nextModulation)
    let type = helpers.randomElement(context.getters.selectedModulations)
    let modeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
    context.commit('updateNextModulation', modeInfo)
    context.commit('updateModulationStyle', 'drift')
  }
}

// Maybe abstract this logic to a helper...
export const buildHarmonicForm = (context, value) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  //let harmonicPreForm = value.match(/((([c|d|f|g|a]#?|[b|e])\\)?([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr))|((([c|d|f|g|a]#?|[b|e])\\)?\([a-z]\))/gi)
  let harmonicPreForm = value.match(/((([c|d|f|g|a]#?|[b|e])\\)?([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr|maj|min|sus|ma7|dom|mi7|hdm|dm7|blu|pen|fth|one))|((([c|d|f|g|a]#?|[b|e])\\)?-[a-z])/gi)
  if (harmonicPreForm === null) { console.log("harmonicPreForm is null!"); return }
  if (helpers.arraysEqual(harmonicPreForm, scene.harmonicForm)) { console.log('same'); return }

  // find all the -[a-z] and eliminate the duplicates
  let formLetters = []
  let formToPickFinderArray = value.match(/-[a-z]/gi)
  if (formToPickFinderArray !== null) {
    let formToPickLettersArray = formToPickFinderArray.map(foundLetter => foundLetter.match(/[a-z]/i)[0] )
    formToPickLettersArray.forEach((letter, index) => {
      if (formLetters.indexOf(letter) === -1) { formLetters.push(letter) }
    })
  }

  // checking formLetters.length against totalFormStepOptions prevents an infiniteloop if there are too few options
  let totalFormStepOptions = scene.selectedRootPitches.length * context.getters.selectedModulations.length
  // create a unique match for each letter
  let formLettersMatches = []        // https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
  while(formLettersMatches.length < formLetters.length){
      let type = helpers.randomElement(context.getters.selectedModulations)
      let preMatch = helpers.pickMode(MODEDATA, type, false, scene.selectedRootPitches)
      preMatch.modeBase = preMatch.modeBase.replace(/s/,'#')
      let match = preMatch.modeBase + preMatch.modulation
      if(formLettersMatches.indexOf(match) > -1 && totalFormStepOptions >= formLetters.length) continue
      formLettersMatches[formLettersMatches.length] = match
  }
    // then combine those together onto an object
  let formLettersRef = {}
  formLetters.forEach((letter, index) => {
    formLettersRef[letter] = formLettersMatches[index]
  })

  // translate the pre-form into the form using that object
      // lower stuff below is redundant...
  let harmonicForm = []
  harmonicPreForm.forEach( (section, index) => {
    let prefix = ''
    if (section.match(/([c|d|f|g|a]#?|[b|e])\\/i) !== null) {
      let prefixMatch = section.match(/([c|d|f|g|a]#?|[b|e])\\/i)
      prefix = helpers.ucFirst(prefixMatch[0])
    }
    let sectionMode = ''
    //if (section.match(/\([a-z]\)/i) !== null ) {
    if (section.match(/-[a-z]/i) !== null ) {
      //let letterSection = section.match(/\([a-z]\)/i)
      let letterSection = section.match(/-[a-z]/i)
      let formLetter = letterSection[0].match(/[a-z]/i)[0]
      sectionMode = formLettersRef[formLetter]
    } else if (section.match(/([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr|maj|min|sus|ma7|dom|mi7|hdm|dm7|blu|pen|fth|one)/i) !== null){
      let sectionPreMode = section.match(/([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr|maj|min|sus|ma7|dom|mi7|hdm|dm7|blu|pen|fth|one)/i)[0]
      sectionMode = helpers.ucFirst(sectionPreMode)
    }
    harmonicForm.push(prefix + sectionMode)
  })
  console.log("harmonicForm", harmonicForm)
  context.commit('updateHarmonicForm', harmonicForm)
}

export const toggleAutoModulate = context => {
  // seemed like there was some good reason autoModulate accepted on/off rather than a toggle. What was it?
  // This is for the keyboard shortcut:
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (scene.autoModulate === true) { context.dispatch('autoModulate', 'off') }
  else if (scene.autoModulate === false) { context.dispatch('autoModulate', 'on') }
}

// Currently in engine
// export const morphSelectedNotes = (context, userCalled) => {
//   let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
//   if (scene.modulationStyle === 'form') {
//     context.commit('updateSelectedMode', scene.nextModulation)
//     context.dispatch('formStepAndChainIncrement')
//     let nextFormSection = (scene.formStep < scene.harmonicForm.length-1)  ? scene.harmonicForm[scene.formStep+1] : scene.harmonicForm[0]
//     let nextFormSectionSansPrefix = nextFormSection.match(/([c|d||f|g|a]#?|[b|e])(dia|mel|har|dim|aug|chr|maj|min|sus|ma7|dom|mi7|hdm|dm7|blu|pen|fth|one)/i)[0]
//     let nextModeInfo = helpers.referenceMode(MODEDATA, nextFormSectionSansPrefix)
//     context.commit('updateNextModulation', nextModeInfo)
//   } else if (scene.modulationStyle === 'drift' && scene.autoModulate) {
//     if (!userCalled){
//       context.commit('updateSelectedMode', scene.nextModulation)
//     }
//     // DRY re: autoModulate & toggleModulationStyle
//     let type = helpers.randomElement(context.getters.selectedModulations)
//     let nextModeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
//     context.commit('updateNextModulation', nextModeInfo)
//   } else {
//     let type = helpers.randomElement(context.getters.selectedModulations)
//     let newModeInfo = helpers.pickMode(MODEDATA, type, scene.lastMode, scene.selectedRootPitches)
//     context.commit('updateSelectedMode', newModeInfo)
//   }
// }
