
<template>

  <div class="tutorial-target" @click="clickMe" />

  <div class="tutorial-text">
    <h1>Welcome to Toneflow</h1>
    <p>You can open and close this Tutorial any time with the "?" (shift-/) key</p>
  </div>

  <div class="tutorial-nav">
    <img
      @click="changePage('decrement')"
      src="@/assets/images/icons8-arrow-96.png"
      alt="play"
    />
    <p>{{this.$store.state.generalPersist.tutorialStep + 1}}</p>
    <img
      @click="changePage('increment')"
      src="@/assets/images/icons8-arrow-96.png"
      alt="play"
    />
  </div>


</template>

<script>

export default {

  data() {
    return {
    }
  },

  computed: {
    pianoSelectorRect() {
      const rect = this.$store.state.general.pianoSelectorRect
      // console.log('>>>rect', rect);
      return rect
    },
  },

  watch: {
    // loggedInUser(value){  // for case when user changes a note.random to 'fixed'
    //   window.location.reload()
    // },
  },
  methods: {
    clickMe() {
      console.log('target clicked');
    },
    changePage(change) {
      this.$store.commit('changeTutorialStep', change)
      console.log('change page');
    }
  },
  mounted() {
  },
  updated() {
  },

}

</script>


<style lang="scss">
@import "../assets/variables";

//https://stackoverflow.com/questions/8286550/transparent-hollow-or-cut-out-circle
//https://stackoverflow.com/questions/26654788/how-to-create-a-transparent-circle?noredirect=1&lq=1

  .tutorial-target {
    position: absolute;
    width:200px; height:200px;
    border-radius: 100%;
    top: 300px;
    left: 300px;
    background: transparent;
    z-index: 100;
    box-shadow: 0 0 0 3000px rgba(0, 0, 0, 0.85);
    cursor: pointer;

  }

  .tutorial-text {
    position: absolute;
    //width:200px; height:200px;
    top: 20px;
    left: 500px;
    z-index: 100;
    //background: rgba(0, 0, 0, 1);
    background: $accentMed1;
    border-radius: 8px;
    padding: 0 10px;


    h1 {
      font-size: 30px;
      //color: white;
      color: black;
    };

    p {
      //font-size: 30px;
      //color: white;
      color: black;
    };
  }

  .tutorial-nav {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    //background: rgba(0, 0, 0, 1);
    background: $accentMed1;
    border-radius: 8px;
    padding: 10px;
    border: 2px solid transparent;
    //box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    animation: tutorialNavBorder 2.4s linear infinite;
    @keyframes tutorialNavBorder {
      50% {
        border-color: white;
        box-shadow: 0 0 24px 3px rgba(255, 255, 255, 0.75);
      }
    }

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    img:nth-of-type(1) {
      transform: rotate(180deg);
    }

    p {
      font-size: 30px;
      margin: 0;
      font-weight: bold;
      color: black;
    }

    &:after {
      //position: absolute;
      //top: 0;
      //left: 0;
      //content: '';
      //width: 50px;
      //height: 50px;
      ////border: 2px solid white;
      //
      //box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.75);
      //animation: blinker 3s linear infinite;
    }
  }

</style>
