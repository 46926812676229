<template>
  <div class="tracks-wrapper">

    <draggable v-model="tracks" @start="drag=true" @end="drag=false" > <!-- :move="possiblyUpdateEditingTrackNumber" -->

      <div class="track-container"
           v-for="(track, trackNumber) in tracks"
           :key="track.id"
           :class="{ activeRegion: activeRegion === 'tune-entry' && trackNumber === scene.editingTrackNumber }"
      >

        <div class="track-controls-and-notes">
          <TrackControls :track-number="trackNumber" :track-id="track.id" ></TrackControls>
<!--          {{track.toneTuneIndex}}-->
          <div v-if="track.tune.length > 0" class="track-notes">
            <div class="note-container"
                 v-for="(note, index) in track.tune"
                 :class="{ editingCursor: trackNumber === editingTrackNumber && index === editingIndex,
                          insertCursor: editMode === 'insert',
                          noteClear: trackLineBreaks[trackNumber].indexOf(index) > -1
                        }"
            >
              <div class="note"
                   v-on:click="handleNoteClick(index, note, trackNumber, track)"
                   :class="{ noteAlternate: (index) % trackColorAlternations[trackNumber]*2 > trackColorAlternations[trackNumber]-1,
                              editingNote: trackNumber === editingTrackNumber && index === editingIndex,
                              randomNoRests: note.random === 'noRests',
                              randomRests: note.random === 'rests',
                              nowPlaying: scene.started && index === highlightedIndexes[trackNumber] }"
              >
                {{ track.hidePitches===true && note.pitch!="+" && note.pitch!=" " ? "?" : note.pitch }}
              </div>
            </div>
          </div>
          <div 
            v-else 
            class="track-notes"
            :title="showTitles ? 'No notes in this track...' : ''"
          >
            ...
          </div>

          <div 
            v-if="track.id !=leadTrackId" 
            class="remove-button" 
            v-on:dblclick="removeTrack(trackNumber)"
            :title="showTitles ? 'Remove track' : ''"
          >
            <p>X</p>
          </div>
        </div>

        <TrackSoundPanel
          v-if="track.soundPanel"
          :track-number="trackNumber"
          :track-id="track.id" >
        </TrackSoundPanel>

      </div>

    </draggable>

    <div class="add-track-container">
      <div 
        class="add-track" 
        v-on:click='addTrack'
        :title="showTitles ? 'Add new track' : ''"
      >
        +
      </div>
    </div>

    <NoteSelect
      v-if="noteData !== null"
      :note-data="noteData"
      :close="closeNoteSelectModal"
    />

  </div>

</template>

<script>
import { defineComponent } from "vue"
import { VueDraggableNext } from 'vue-draggable-next'

import TrackControls from '@/components/TrackControls.vue'
import TrackSoundPanel from "@/components/TrackSoundPanel.vue";
import NoteSelect from "@/components/NoteSelectModal";

export default defineComponent({
  name: "Tracks",
  components: {
    TrackControls,
    TrackSoundPanel,
    NoteSelect,
    'draggable': VueDraggableNext,
  },
  data() {
    return {
      noteData: null,
    }
  },
  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    activeRegion(){
      return this.$store.state.flow.activeRegion
    },
    scene(){
      return this.$store.state.flow.scenes[this.editingSceneNumber]
    },
    editingSceneNumber(){
      return this.$store.state.flow.editingSceneNumber
    },
    leadTrackId(){
      return this.scene.leadTrackId
    },
    tracks: {
      get() {
        return this.$store.state.flow.scenes[this.editingSceneNumber].tracks
      },
      set(tracks) {
        this.$store.dispatch('setTracks', tracks)
      }
    },
    newTrack(){
      return this.$store.state.flow.scenes[this.editingSceneNumber].tracks[0]
    },
    newTrackTune(){
      return this.$store.state.flow.scenes[this.editingSceneNumber].tracks[0].tune
    },
    newTrackId(){
      return this.$store.state.flow.scenes[this.editingSceneNumber].tracks[0].id
    },
    editingIndex(){
      return this.$store.state.flow.scenes[this.editingSceneNumber].editingIndex
    },
    editingTrackNumber(){
      return this.$store.state.flow.scenes[this.editingSceneNumber].editingTrackNumber
    },
    toneTunes(){
      return this.$store.getters.toneTunes
    },
    highlightedIndexes(){
      let highlightedIndexes = []
      this.$store.state.flow.scenes[this.editingSceneNumber].tracks.forEach( (track, index) => {
        // console.log('track ', index, 'toneTuneIndex:', track.toneTuneIndex);
        let val = track.toneTuneIndex-1
        highlightedIndexes.push( (val === -1) ? this.toneTunes[index].length-1 : val )
      })
      // console.log('highlightedIndexes:', highlightedIndexes);
      return highlightedIndexes
    },
    editMode(){
      return this.$store.state.general.editMode
    },
    trackColorAlternations(){
      let trackColorAlternations = []
      this.scene.tracks.forEach( (track, index) => {
        trackColorAlternations.push(track.colorAlternation)
      })
      return trackColorAlternations
      // return for (track in this.scene.tracks){ trackColorAlternations.push(track.colorAlternation) }
    },
    trackLineBreaks(){
      let trackLineBreaks = []
      this.scene.tracks.forEach( (track, index) => {
        trackLineBreaks.push(track.lineBreaks)
      })
      return trackLineBreaks
    },
  },

  methods: {
    addTrack(){
      this.$store.dispatch('addTrack')
    },
    handleNoteClick(noteIndex, note, trackNumber, track){
      // console.log('in handleNoteClick', noteIndex, note, trackNumber, track);
      // this.$store.dispatch('addRandomNoteToTrack', this.trackNumber) // this feature is not on original, on mobile it receives trackNumber as prop
      if (note.pitch === '+') {
        this.$store.dispatch('addRandomNoteToTrack', trackNumber)
      } else {
        // console.log('else...');
        this.openNoteSelectModal(trackNumber, noteIndex)
      }
    },
    openNoteSelectModal(trackNumber, noteIndex){
      // console.log('trackNumber', trackNumber, 'noteIndex', noteIndex)
      this.noteData = {trackNumber, noteIndex}
    },
    closeNoteSelectModal(){
      this.noteData = null
    },
    removeTrack(trackNumber){
      // console.log('in Tracks.vue removeTrack');
      this.$store.dispatch('removeTrack', trackNumber)
    },
  },
})

</script>


<style lang="scss">
@import "../assets/variables";


// applied globally:

.tracks-wrapper {
  //border: 1px solid transparent;
  margin: 0 0 10px 0;
  //background: linear-gradient(270deg, rgba(204,204,204,1) 0%, rgba(238,238,238,1) 35%, rgba(255,255,255,1) 100%);


  .track-container {
    box-sizing: border-box;
    //padding: 8px 16px 8px 8px;
    //padding: 5px 25px 5px 5px;
    //background-color: #ddd; // #777;
    position: relative;
    border: 2px solid transparent;
    margin: 3px 3px 3px 0;

    &.activeRegion {
      border: 2px solid white;
      //box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
      box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.75);
      /*  animation: blinker 2s linear infinite; */
    }


    .track-controls-and-notes {
      box-sizing: border-box;
      //padding: 8px 16px 8px 8px;
      //padding: 5px 25px 5px 5px;
      //background-color: #ddd; // #777;
      position: relative;
      //border: 1px solid black;
      //margin: 3px 3px 3px 0;
      display: flex;
      align-items: stretch;

      .track-notes {
        padding: 4px 8px 4px 8px;
        margin: 0 0 0 5px;
        border: 1px solid black;
        background-color: #bbb; // #666;
        //min-height: 58px;
        min-width: 44px;
        /*  box-shadow: inset 0 -2px 1px rgba(0,0,0,0.03); */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;

        .note-container {
          float: left;
          padding: 3px 0 3px 0;
          border: 2px solid transparent;
          /*  background: #777; */
          /*  box-shadow: inset 0 0 10px #faa; */
          display: block;

          &.editingCursor {
            border-bottom: 2px solid white;
            border-left: 2px solid transparent;
          }
          &.editingCursor.insertCursor {
            border-bottom: 1px solid transparent;
            border-left: 1px solid white;
          }
          &.noteClear {
            clear: both;
          }

          .note {
            background-color: rgba(170, 150, 255, 0.6);
            width: 40px;
            height: 40px;
            //padding: 10px 4px 0 0px;
            border: 1px solid black;
            margin: 0 3px 0 3px;
            vertical-align:top;
            text-align:center;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &.noteAlternate {
              background-color: rgba(130, 110, 255, 0.6);
            }

            &.editingNote {
              font-weight: bold;
              color: white;
              box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
              animation: blinker 1.4s linear infinite;

              @keyframes blinker {
                50% {
                  opacity: 0;
                }
              }
            }

            &.nowPlaying {
              border: 1px dashed white;
            }

            &.randomNote {
              border-radius: 30%;
            }

            &.randomNoRests {
              border-radius: 30%
            }

            &.randomRests {
              border-radius: 50%
            }
          }
        }
      }
    }
  }

  .remove-button {
    position: absolute;
    right: 0;
    top: 30px;
    color: red;
    font-weight: bold;
    background-color: #aaa;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    cursor: pointer;
  }

  .add-track-container {
    //background: green;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

    .add-track {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #abe;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      font-size: 60px;
      cursor: pointer;
    }
  }
}



</style>
