<template>

    <div class="flows">

      <div class="flows-header">
        <h1>Flows</h1>
      </div>

      <div v-if="flows.length === 0">(No flows stored yet!)</div>

      <FlowListing
        v-if="this.$store.state.useServer"
        v-for="(flow, flowIndex) in flows"
        :flow="flow"
        :show-jazzicon="true"
        :show-username="true"
        :key="flow._id"
      />
      <FlowListing
        v-else
        v-for="(flow, flowIndex) in localFlows"
        :flow="flow"
        :show-jazzicon="true"
        :show-username="true"
        :key="`local${flow._id}`"
        :show-delete="true"
      />

    </div>

</template>

<script>
// <script lang="ts">
// all manner of https://stackoverflow.com/questions/55168079/typescript-vue-js-property-xxx-does-not-exist-on-type-never from flowsLeadTrackNotes when using lang="ts"
import { defineComponent } from 'vue'
import router from "@/router";

import FlowListing from "@/components/general/FlowListing";

export default defineComponent({
  name: 'Signup',
  components: { FlowListing },
  data() {
    return {
      flows: [],
    }
  },
  computed: {
    localFlows() {
      const localFlows = this.$store.state.flows.localStoredFlows.map(e => JSON.parse(e))
      return localFlows
    },
  },
  methods: {
    loadFlows() {
      // MOVE THIS FETCH PLEEEZ

      // const queryString = '?user_id=61ec44880c83f6563063334a'
      const queryString = ''

      // console.log('loading...');
      fetch(`http://localhost:4000/flows/${queryString}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(async data => {
          console.log('loadFlows fetched data', data)

          this.flows = data.flows
          // ctx.commit('login', data)
          // const { user: { id: userID} } = data
          // console.log('userID', userID)
          // await router.push(`/userIDs/${userID}`)
        })
        .catch(err => console.log(err))
    },
  },
  mounted() {
    if (this.$store.state.useServer) {
      this.loadFlows()
    }
  },
});
</script>;

<style lang="scss">
@import "../assets/variables";


</style>
