
import { defineComponent } from 'vue'
import Jazzicon from 'vue3-jazzicon/src/components';

export default defineComponent({
  name: 'Users',
  components: {
    [Jazzicon.name]: Jazzicon
  },
  data() {
    return {
      users: [],
    }
  },
  mounted() {
    fetch(`http://localhost:4000/users`, {
      method: 'GET',
      // headers : new Headers(),
      headers: {
        'Content-Type': 'application/json',
        'X-Toneflow-Session-Key': 'test',
      },
    })
        .then(res => res.json())
        .then(async data => {
          console.log('fetched data', data)
          this.users = data
        })
        .catch(err => console.log(err))
    // this.$store.dispatch('fetchUsers') // no need to get the store involved here
  },
  // computed: {
  //   users() {
  //     return this.users
  //   }
  // },
});
