<template>

    <div class="mode-options">


        <div class="root-pitch-selector">

          <div class="button-esq increment-weights" @click="incrementModulationWeights">+ Types</div>

          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('C') > -1 }"
               @click="updateSelectedRootPitches('C')"
               @contextmenu="soloSelectedRootPitch('C', $event)">
            C</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('Cs') > -1 }"
               @click="updateSelectedRootPitches('Cs')"
               @contextmenu="soloSelectedRootPitch('Cs', $event)">
            C#</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('D') > -1 }"
               @click="updateSelectedRootPitches('D')"
               @contextmenu="soloSelectedRootPitch('D', $event)">
            D</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('Ds') > -1 }"
               @click="updateSelectedRootPitches('Ds')"
               @contextmenu="soloSelectedRootPitch('Ds', $event)">
            D#</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('E') > -1 }"
               @click="updateSelectedRootPitches('E')"
               @contextmenu="soloSelectedRootPitch('E', $event)">
            E</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('F') > -1 }"
               @click="updateSelectedRootPitches('F')"
               @contextmenu="soloSelectedRootPitch('F', $event)">
            F</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('Fs') > -1 }"
               @click="updateSelectedRootPitches('Fs')"
               @contextmenu="soloSelectedRootPitch('Fs', $event)">
            F#</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('G') > -1 }"
               @click="updateSelectedRootPitches('G')"
               @contextmenu="soloSelectedRootPitch('G', $event)">
            G</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('Gs') > -1 }"
               @click="updateSelectedRootPitches('Gs')"
               @contextmenu="soloSelectedRootPitch('Gs', $event)">
            G#</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('A') > -1 }"
               @click="updateSelectedRootPitches('A')"
               @contextmenu="soloSelectedRootPitch('A', $event)">
            A</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('As') > -1 }"
               @click="updateSelectedRootPitches('As')"
               @contextmenu="soloSelectedRootPitch('As', $event)">
            A#</div>
          <div class="root-pitch-option"
               :class="{ rootPitchOptionSelected: this.selectedRootPitches.indexOf('B') > -1 }"
               @click="updateSelectedRootPitches('B')"
               @contextmenu="soloSelectedRootPitch('B', $event)">
            B</div>

          <div class="button-esq all-root-pitches" @click="updateSelectedRootPitches('all')">All Roots</div>

        </div>


      <div class="mode-options-boxes">

        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('dia')" @contextmenu="soloModulationWeight('dia', $event)" >Dia</span>
            <input type="number" min="0" :value="modulationWeights['dia']" @input="updateModulationWeights('dia', $event)" @focus="focusFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('mel')" @contextmenu="soloModulationWeight('mel', $event)" >Mel</span>
            <input type="number" min="0" :value="modulationWeights['mel']" @input="updateModulationWeights('mel', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('har')" @contextmenu="soloModulationWeight('har', $event)" >Har</span>
            <input type="number" min="0" :value="modulationWeights['har']" @input="updateModulationWeights('har', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('dim')" @contextmenu="soloModulationWeight('dim', $event)" >Dim</span>
            <input type="number" min="0" :value="modulationWeights['dim']" @input="updateModulationWeights('dim', $event)" @focus="focusFunction"  @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('aug')" @contextmenu="soloModulationWeight('aug', $event)" >Aug</span>
            <input type="number" min="0" :value="modulationWeights['aug']" @input="updateModulationWeights('aug', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('chr')" @contextmenu="soloModulationWeight('chr', $event)" >Chr</span>
            <input type="number" min="0" :value="modulationWeights['chr']" @input="updateModulationWeights('chr', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('maj')" @contextmenu="soloModulationWeight('maj', $event)" >Maj</span>
            <input type="number" min="0" :value="modulationWeights['maj']" @input="updateModulationWeights('maj', $event)" @focus="focusFunction"  @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('min')" @contextmenu="soloModulationWeight('min', $event)" >Min</span>
            <input type="number" min="0" :value="modulationWeights['min']" @input="updateModulationWeights('min', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('sus')" @contextmenu="soloModulationWeight('sus', $event)" >Sus</span>
            <input type="number" min="0" :value="modulationWeights['sus']" @input="updateModulationWeights('sus', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('maj')" @contextmenu="soloModulationWeight('ma7', $event)" >Ma7</span>
            <input type="number" min="0" :value="modulationWeights['ma7']" @input="updateModulationWeights('ma7', $event)" @focus="focusFunction"  @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('dom')" @contextmenu="soloModulationWeight('dom', $event)" >Dom</span>
            <input type="number" min="0" :value="modulationWeights['dom']" @input="updateModulationWeights('dom', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('mi7')" @contextmenu="soloModulationWeight('mi7', $event)" >Mi7</span>
            <input type="number" min="0" :value="modulationWeights['mi7']" @input="updateModulationWeights('mi7', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('hdm')" @contextmenu="soloModulationWeight('hdm', $event)" >Hdm</span>
            <input type="number" min="0" :value="modulationWeights['hdm']" @input="updateModulationWeights('hdm', $event)" @focus="focusFunction"  @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('dm7')" @contextmenu="soloModulationWeight('dm7', $event)" >Dm7</span>
            <input type="number" min="0" :value="modulationWeights['dm7']" @input="updateModulationWeights('dm7', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('blu')" @contextmenu="soloModulationWeight('blu', $event)" >Blu</span>
            <input type="number" min="0" :value="modulationWeights['blu']" @input="updateModulationWeights('blu', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
        <div class="mode-options-box">
          <div class="mode-option"><span @click="changeToModeType('pen')" @contextmenu="soloModulationWeight('pen', $event)" >Pen</span>
            <input type="number" min="0" :value="modulationWeights['pen']" @input="updateModulationWeights('pen', $event)" @focus="focusFunction"  @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('fth')" @contextmenu="soloModulationWeight('fth', $event)" >Fth</span>
            <input type="number" min="0" :value="modulationWeights['fth']" @input="updateModulationWeights('fth', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
          <div class="mode-option"><span @click="changeToModeType('one')" @contextmenu="soloModulationWeight('one', $event)" >One</span>
            <input type="number" min="0" :value="modulationWeights['one']" @input="updateModulationWeights('one', $event)" @focus="focusFunction" @keyup.enter="enterFunction" />
          </div>
        </div>
      </div>

    </div>

</template>


<script>
import { MODEDATA } from "@/constants/modeData"

import * as helpers from "@/utils/helpers"
// import {bus} from '../main.js'


export default {
  components: {
  },
  data(){
    return {
    }
  },
  computed: {
    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    selectedRootPitches(){
      return this.scene.selectedRootPitches
    },
    modulationWeights() {
      return this.scene.modulationWeights
    },
  },

  methods: {
    updateSelectedRootPitches(rootPitch){
      this.$store.commit('updateSelectedRootPitches', rootPitch)
    },
    soloSelectedRootPitch(rootPitch, e){
      e.preventDefault()
      this.$store.commit('updateSelectedRootPitches', 'clear')
      this.$store.commit('updateSelectedRootPitches', rootPitch)
    },
    updateModulationWeights(modulationType, e){
      this.$store.commit('updateModulationWeights', { modulationType: modulationType, value: e.target.value } )
    },
    incrementModulationWeights(){
      let modulationTypes = ['dia', 'mel', 'har', 'dim', 'aug', 'chr', 'maj', 'min', 'sus', 'ma7', 'dom', 'mi7', 'hdm', 'dm7', 'blu', 'pen', 'fth', 'one']
      modulationTypes.forEach( (type, index) => {
        this.$store.commit('updateModulationWeights', { modulationType: type, value: 'increment' } )
      })
    },
    changeToModeType(modulationType){
      let modeInfo = helpers.pickMode(MODEDATA, modulationType, this.scene.lastMode, this.selectedRootPitches)
      if (this.scene.autoModulate){
        this.$store.commit('updateNextModulation', modeInfo) // an alternate style to employ this...
      } else {
        this.$store.commit('updateSelectedMode', modeInfo)
      }
    },
    soloModulationWeight(modulationType, e){
      // https://stackoverflow.com/questions/41303982/vue-js-how-to-handle-click-and-dblclick-events-on-same-element
      e.preventDefault()
      let modulationTypes = ['dia', 'mel', 'har', 'dim', 'aug', 'chr', 'maj', 'min', 'sus', 'ma7', 'dom', 'mi7', 'hdm', 'dm7', 'blu', 'pen', 'fth', 'one']
      modulationTypes.forEach( (type, index) => {
        this.$store.commit('updateModulationWeights', { modulationType: type, value: 0 } )
      })
      this.$store.commit('updateModulationWeights', { modulationType: modulationType, value: 1 } )
    },
    focusFunction(){
      // console.log("focussing")
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      this.$store.commit('changeActiveRegion', 'scene-dashboard')
    },
    enterFunction(event){
      // console.log('in SceneDashBoard enterFunction')
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      // bus.$emit('clearKeyFromDown', 'Enter')
    },
  },

}

</script>


<style lang="scss">
@import "../assets/variables";

.mode-options {
  text-align: center;
  //background: blue;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .root-pitch-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background: blue;
    padding: 0 0 0 3px;

    .increment-weights {
      display: inline-block;
      min-width: 65px;
      margin-right: 1px;
      border: 1px solid black;
      font-size: 12px;
      padding: 1px;
    }

    .all-root-pitches {
      display: inline-block;
      min-width: 65px;
      margin-left: 1px;
      border: 1px solid black;
      font-size: 12px;
      padding: 1px;
    }

    .root-pitch-option {
      display: inline-block;
      min-width: 28px;
      background: transparent;
      color: black;
      border: 2px solid transparent;
      font-size: 14px;


      &.rootPitchOptionSelected {
        //background: #afa;
        background: $pianoKeyNaturalSelected;
        color: black;
        border: 2px solid transparent;
      }
    }
  }

  .mode-options-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .mode-options-box {

      .mode-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: #3fb;
        background: $pianoKeyNaturalSelected;
        margin: 3px 0 0 3px;
        padding: 0 0 0 6px;

        span {
          padding: 0 2px;
          font-size: 12px;
        }
      }
      .mode-option input {
        font-size: 12px;
        width: 35px;
      }
    }
  }
}


</style>
