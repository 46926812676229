<template>


  <div class="scene-settings-background" @click="onClose">
    <div class="scene-settings-inner" @click="$event.stopPropagation()">


      <div class="close-button" @click="onClose" >
        <img class="medium" src="@/assets/images/icons8-close-60-inverted.png" />
      </div>

      <h1>Flow Settings</h1>

      <div class="scene-setting">
        <p class="entry-sound-text">Play sound when notes are added to track:</p>
        <input class="entry-sound" type="checkbox" v-model="entrySound">
      </div>

      <div class="scene-setting">
        <p class="edit-mode">Tracks editing mode:
          <button @click="toggleEditMode" v-if="this.$store.state.general.editMode === 'insert'">Insert</button>
          <button @click="toggleEditMode" v-else>Overwrite</button>
        </p>
      </div>

<!--      <div class="scene-setting">-->
<!--        <p>Flow Title:</p>-->
<!--        <input-->
<!--          class="flow-title"-->
<!--          v-model="flowTitle"-->
<!--          @focus="focusFunction"-->
<!--          @keyup.enter="enterFunction($event)"-->
<!--        >-->
<!--        <div-->
<!--          class="random-scene-title"-->
<!--          @click="pickRandomFlowTitle"-->
<!--          title="Pick a random title for the flow"-->
<!--        >-->
<!--          <img class="small" src="../assets/images/icons8-dice-64.png" alt="Random Scene Title" />-->
<!--        </div>-->
<!--      </div>-->

      <h1>Scene Settings</h1>

      <div class="scene-setting">
        <p>Scene Title:</p>
        <input
          class="scene-title"
          v-model="sceneTitle"
        >
<!--        @focus="focusFunction"-->
<!--        @keyup.enter="enterFunction($event)"-->
        <div
            class="random-scene-title"
            @click="pickRandomSceneTitle"
            title="Pick a random title for the scene"
        >
          <img class="small" src="../assets/images/icons8-dice-64.png" alt="Random Scene Title" />
        </div>
      </div>


      <div class="scene-setting">
        <p>Lead track number:</p>
        <!-- how does focus & enter work on select? it does work in the DOM https://jsfiddle.net/0eh6z947/ -->
        <!-- https://stackoverflow.com/questions/50434769/receive-event-object-in-vuex-v-model-setter?noredirect=1#comment87903913_50434769 -->
        <!-- note that v-model set is only called when the value changes. @keyup.enter="enterFunction($event)" doesn't work, is it possible to get enter to work on this some other way? For the moment looks like you'd need to use the global keyup listener (blur selects, possibly testing for active element as region)  -->
        <select
          id="leadSelect"
          class="lead-track-number"
          v-model="leadTrackNumberAdjust"
          @focus="focusFunction"
          >
          <!--          @change="enterFunction($event)"-->
          <!--          @blur="enterFunction($event)"-->
          <option
            v-for="(track, index) in scene.tracks "
            :key="Math.random().toString().slice(2)"
          >{{ index+1 }}</option>
        </select>
      </div>


      <div class="scene-setting">
        <p>Lead track cycles to change scene:</p>
        <input type="number" min="0"
          v-model="chainAdvancePer"
          @focus="focusFunction"
        >
<!--        @keyup.enter="enterFunction($event)"-->
<!--        @blur="enterFunction($event)"-->
      </div>


<!--      perhaps only enable this if tracks are remembered-->
      <div class="scene-setting">
        <p class="reset-option-text">Reset remembered tracks on scene change:</p>
        <input class="reset-option" type="checkbox" v-model="resetRememberedOnSceneChange">
      </div>


<!--      <div class="scene-setting">-->
<!--        <div class="button-esq random-fill" v-on:click='fill' >Fill Track</div>-->
<!--        <p>with</p>-->
<!--        <input-->
<!--          type="number" min="1"-->
<!--          v-bind:value="length"-->
<!--          v-on:change="updateSelectedLength"-->
<!--          @focus="focusFunction"-->
<!--          @keyup.enter="enterFunction"-->
<!--          @blur="enterFunction($event)"-->
<!--        >-->
<!--        <p>notes</p>-->
<!--      </div>-->


<!--      <div class="scene-setting">-->
<!--        <div class="button-esq random-distribute" v-on:click='distribute' >Distribute</div>-->
<!--        <p>All selected pitches</p>-->
<!--      </div>-->


      <!--    <span>Load Qwerty:</span>-->
      <!--    <select id="qwertySettingOnSceneChange"-->
      <!--      v-model:value="qwertySettingOnSceneChange"-->
      <!--      @focus="focusFunction"-->
      <!--      @change="enterFunction($event)"-->
      <!--      @blur="enterFunction($event)"-->
      <!--    >-->
      <!--      <option v-for="setting in qwertySettings">{{ setting }}</option>-->
      <!--    </select>-->


      <!--      <div class="scene-setting">-->
      <!--        <p>Change scene on:</p>-->
      <!--        <select-->
      <!--          id="sceneChangeIncrement"-->
      <!--          class="scene-change-increment"-->
      <!--          v-model="sceneChangeIncrement"-->
      <!--          @focus="focusFunction"-->
      <!--          @change="enterFunction($event)"-->
      <!--          @blur="enterFunction($event)"-->
      <!--        >-->
      <!--          <option v-for="increment in changeIncrements">{{ increment }}</option>-->
      <!--        </select>-->
      <!--      </div>-->

<!--      <div class="scene-setting">-->
<!--        <p>Lead track number:</p>-->
<!--        &lt;!&ndash; how does focus & enter work on select? it does work in the DOM https://jsfiddle.net/0eh6z947/ &ndash;&gt;-->
<!--        &lt;!&ndash; https://stackoverflow.com/questions/50434769/receive-event-object-in-vuex-v-model-setter?noredirect=1#comment87903913_50434769 &ndash;&gt;-->
<!--        &lt;!&ndash; note that v-model set is only called when the value changes. @keyup.enter="enterFunction($event)" doesn't work, is it possible to get enter to work on this some other way? For the moment looks like you'd need to use the global keyup listener (blur selects, possibly testing for active element as region)  &ndash;&gt;-->
<!--        <select-->
<!--          id="leadSelect"-->
<!--          class="lead-track-number"-->
<!--          v-model="leadTrackNumberAdjust"-->
<!--          @focus="focusFunction"-->
<!--          @change="enterFunction($event)"-->
<!--          @blur="enterFunction($event)"-->
<!--        >-->
<!--          <option-->
<!--            v-for="(track, index) in scene.tracks "-->
<!--            :key="Math.random().toString().slice(2)"-->
<!--          >{{ index+1 }}</option>-->
<!--        </select>-->
<!--      </div>-->


<!--      <div class="scene-setting">-->
<!--        <p>Lead track cycles to change scene:</p>-->
<!--        <input type="number" min="0"-->
<!--               v-model="chainAdvancePer"-->
<!--               @focus="focusFunction"-->
<!--               @keyup.enter="enterFunction($event)"-->
<!--               @blur="enterFunction($event)"-->
<!--        >-->
<!--      </div>-->

    </div>
  </div>

</template>

<script>
  import { defineComponent } from "vue";
  import { randomElement } from "@/utils/helpers";
  import * as CONSTANTS from "@/constants/constants"

  export default defineComponent({
    name: "SceneSettingsModal",
    props: ['close', 'registerCombos', 'unregisterCombos'],
    data:() => ({
      // changeIncrements: ['Lead Cycle', 'Lead Change', 'Modulation', 'Form'],
    }),
    computed: {
      flow(){
        // console.log('flow...', this.$store.state.flow);
        return this.$store.state.flow
      },
      scene(){
        return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
      },
      // flowTitle: {
      //   get() {
      //     return this.flow.flowTitle
      //   },
      //   set(title) {
      //     this.$store.commit('updateFlowTitle', title)
      //   },
      // },
      sceneTitle: {
        get(){
          return this.scene.title
        },
        set(title){
          this.$store.dispatch('updateSceneTitle', title)
        },
      },
      leadTrackNumberAdjust: {
        get(){
          let leadTrackNumber = ''
          this.scene.tracks.forEach( (track, index) => {
            if (track.id === this.scene.leadTrackId) { leadTrackNumber = index }
          })
          return leadTrackNumber+1
        },
        set(value){
          this.$store.commit('updateLeadTrack', value-1 )
        },
      },
      chainAdvancePer: {
        get(){
          return this.scene.chainAdvancePer
        },
        set(value){
          // console.log(value)
          this.$store.commit('updateChainAdvancePer', value)
        },
      },
      resetRememberedOnSceneChange: {
        get(){
          return this.scene.resetRememberedOnSceneChange
        },
        set(){
          this.$store.commit('toggleResetRememberedOnSceneChange')
        },
      },
      entrySound: {
        get(){
          // console.log('entrySound?', this.flow.entrySound)
          return this.flow.entrySound
        },
        set(){
          this.$store.commit('toggleEntrySound')
        },
      },

      // qwertySettingOnSceneChange: {
      //   get(){
      //     let loadQwerty = this.scene.loadQwertySettingOnSceneChange
      //     if (loadQwerty == 0) { return 'default' }
      //     return loadQwerty  // works because JS is loosely typed! livin' dangerous...
      //   },
      //   set(value){
      //     console.log(typeof value, value)
      //     switch (value){
      //       case 'none':
      //         this.$store.commit('updateLoadQwertySettingOnSceneChange', value)
      //         break
      //       case 'default':
      //         this.$store.commit('updateLoadQwertySettingOnSceneChange', 0)
      //         break
      //       default:  // otherwise it's a string with a number value
      //         this.$store.commit('updateLoadQwertySettingOnSceneChange', value) //parseInt(value, 10))
      //     }
      //   },
      // },
      // qwertySettings(){
      //   let qwertySettings = ['none', 'default']
      //   this.$store.state.playerParamSettings.forEach( (setting, index) => {
      //     if (setting.assigned && index!= 0){ qwertySettings.push(index) } // index.toString() not needed, it automatically makes it a string
      //   })
      //   return qwertySettings
      // },
      // sceneChangeIncrement: {
      //   get(){
      //     return this.scene.sceneChangeIncrement
      //   },
      //   set(increment){
      //     this.$store.commit('setSceneChangeIncrementType', increment)
      //   },
      // },

      // leadTrackNumberAdjust: {
      //   get(){
      //     let leadTrackNumber = ''
      //     this.scene.tracks.forEach( (track, index) => {
      //       if (track.id === this.scene.leadTrackId) { leadTrackNumber = index }
      //     })
      //     return leadTrackNumber+1
      //   },
      //   set(value){
      //     this.$store.commit('updateLeadTrack', value-1 )
      //   },
      // },
      // chainAdvancePer: {
      //   get(){
      //     return this.scene.chainAdvancePer
      //   },
      //   set(value){
      //     // console.log(value)
      //     this.$store.commit('updateChainAdvancePer', value)
      //   },
      // },


    },
    methods: {
      onClose(){
        const prevRegion = this.$store.state.flow.previousRegion
        if (prevRegion === 'bypass-dispatch') {
          this.$store.commit('changeActiveRegion', 'tune-entry')
        } else {
          this.$store.commit('changeActiveRegion', prevRegion)
        }
        this.registerCombos()
        this.close()
      },
      pickRandomFlowTitle(){
        const newTitle = randomElement(CONSTANTS.SCENETITLES)
        this.$store.commit('updateFlowTitle', newTitle)
      },
      toggleEditMode() {
        this.$store.commit('toggleEditMode')
      },
      pickRandomSceneTitle(){
        const newTitle = randomElement(CONSTANTS.SCENETITLES)
        this.$store.dispatch('updateSceneTitle', newTitle)
      },
      // focusFunction(){
      //   // console.log("focussing")
      //   this.$store.commit('changePreviousRegion', this.$store.state.activeRegion)
      //   this.$store.commit('changeActiveRegion', '')
      // },
      // enterFunction(event){
      //   event.target.blur()
      //   this.$store.commit('changeActiveRegion', this.$store.state.previousRegion)
      //   // bus.$emit('clearKeyFromDown', 'Enter')
      // },
      // updateSelectedLength(e){
      //   this.$store.commit('updateSelectedLength', e.target.value)
      // },
      // fill(){
      //   this.$store.dispatch('fill')
      // },
      // distribute(){
      //   this.$store.dispatch('distribute')
      // },
   },
    mounted(){
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      this.$store.commit('changeActiveRegion', 'bypass-dispatch')
      this.unregisterCombos()
    }
  });
</script>

<style lang="scss">
  @import "@/assets/variables";

  .scene-settings-background {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3; // 2 causes keyboard keys to peek through. why? NoteSelectModal has z-index 2 does not.
    //background: #555;
    background-color: rgba(10, 39, 30, 0.8);

    .scene-settings-inner {
      display: flex;
      flex-direction: column;
      background-color: rgb(10, 39, 30);
      position: relative;
      padding: 40px;

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        img {
          width: 60px;
        }
      }

      .scene-setting {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          font-size: 18px;
          margin-right: 10px;
          //width: 150px;

          &.reset-option-text {
            margin-right: 0;
          }

          &.entry-sound-text {
            margin-right: 0;
          }
        }

        .edit-mode {
          button {
            margin-left: 5px;
          }
        }

        .random-scene-title {
          cursor: pointer;
          margin: 0 0 0 10px;

          img {
            width: 50px;
          }
        }

        input,
        select {
          font-size: 16px;
        }

        .flow-title {
          width: 250px;
          height: 24px;
        }

        .scene-title {
          width: 250px;
          height: 24px;
        }

        .lead-track-number {
          width: 60px;
          height: 24px;
        }

        .reset-option {
          height: 20px;
        }

        .entry-sound {
          height: 20px;
        }

        //.scene-change-increment {
        //  //width: 40px;
        //  height: 24px;
        //}
        //
      }
    }
  }

</style>
