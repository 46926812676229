<template>
  <div class="play-n-tabs">

    <button 
      v-if="!this.$store.state.general.playing" 
      class="start" 
      v-on:click="togglePlay"
    >
      <img src="@/assets/images/icons8-play-96.png" alt="Play" />
    </button>
    <button 
      v-else class="start" 
      v-on:click="togglePlay"
    >
      <img src="@/assets/images/icons8-pause-96.png" alt="play" />
    </button>

    <button 
      v-if="!chain" 
      class="chain" 
      v-on:click="toggleChain" 
      :title="showTitles ? 'Scene auto-advance is off' : ''"
    > 
      <img src="@/assets/images/icons8-delete-link-96.png" alt="Scene Chain off" />
    </button>
    <button 
      v-else class="chain" 
      v-on:click="toggleChain" 
      :title="showTitles ? 'Scene auto-advance is on' : ''"
    >
      <img src="@/assets/images/icons8-link-96.png" alt="Scene Chain" />
    </button>

    <button
      v-if="this.$store.state.flow.chainLoop"
      class="chain-loop"
      :class="{ greyOut: !this.chain }"
      v-on:click="toggleChainLoop"
      :title="showTitles ? 'Play scenes in a loop' : ''"
    >
      <img src="@/assets/images/icons8-repeat-96.png" alt="Play" />
    </button>
    <button
      v-else
      class="chain-loop"
      :class="{ greyOut: !this.chain }"
      v-on:click="toggleChainLoop"
      :title="showTitles ? 'Play through scene once' : ''"
    >
      <img src="@/assets/images/icons8-arrow-96.png" alt="play" />
    </button>

<!--    <div class="scene-tabs">-->
        <draggable class="scene-tabs" v-model="scenes" @start="drag=true" @end="drag=false" >
          <div class="scene-tab"
               v-for="(scene, sceneIndex) in scenes"
               :class="{ currentScene: sceneIndex === state.flow.editingSceneNumber }"
               @click="setUpSceneChange(sceneIndex)"
               :key="scene.id" >
            <span>{{ scene.title }}</span>
            <div
              v-if="!(sceneIndex === state.flow.editingSceneNumber)"
              class="button-esq delete-scene"
              v-on:click.stop="deleteScene(scene)"
              :title="showTitles ? 'Delete scene' : ''"
            >
              -
            </div>
          </div>
        </draggable>
<!--    </div>-->

    <div 
      class="button-esq add-scene" 
      v-on:click="setUpNewScene"
      :title="showTitles ? 'Add new scene' : ''"
    >
      +
    </div>

    <button 
      v-if="!this.$store.state.general.playing" 
      class="reset" 
      v-on:click="resetDefaults"
      :title="showTitles ? 'Reset flow to defaults' : ''"
    >
      <img src="@/assets/images/icons8-reset-96.png" alt="Reset" />
    </button>

  </div>
</template>

<script>
import { log } from 'tone/build/esm/core/util/Debug'
// import draggable from 'vuedraggable'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: "",
  data: () => ({
  }),
  components: {
    'draggable': VueDraggableNext,
  },

  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    state(){
      return this.$store.state
    },
    playing(){
      return this.$store.state.playing
    },
    chain(){
      return this.$store.state.flow.chain
    },
    chainRepsToEnd: {
      get(){
        return this.$store.state.flow.chainRepsToEnd
      },
      set(value){
        this.$store.commit('updateChainRepsToEnd', value)
      },
    },
    scenes: {
      get() {
        return this.$store.state.flow.scenes
      },
      set(scenes) {
        this.$store.commit('dragScene', scenes)
      },
    },
    sceneBench(){
      return this.$store.state.flow.sceneBench
    },

  },

  methods: {
    togglePlay(){
      this.$store.commit('togglePlay')
    },
    toggleChain(){
      this.$store.commit('toggleChain')
    },
/*    updateSceneTitle(sceneIndex, e){
      console.log('sceneIndex', sceneIndex, "e", e)
      this.$store.commit(updateSceneTitle)
    }, */
    toggleChainLoop(){
      this.$store.commit('toggleChainLoop')
    },
    setUpNewScene(){
      this.$store.dispatch('setUpNewScene')
    },
    setUpSceneChange(sceneIndex){
      this.$store.dispatch('setUpSceneChange', sceneIndex)
    },
    deleteScene(scene){
      // console.log('deleteScene', scene)
      let del = confirm('Delete scene "' + scene.title + '" ?' )
      if (del) { this.$store.dispatch('deleteScene', scene.id) }
    },
    resetDefaults(){
      const doReset = window.confirm("This will wipe all data and reset defaults. Proceed?")
      // console.log('doReset', doReset);
      this.$store.dispatch('resetDefaults')
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
@import "../assets/variables";

.play-n-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0;
  //background-color: #ccc;
  margin-bottom: 10px;
  background: linear-gradient(270deg, rgba(204,204,204,1) 0%, rgba(238,238,238,1) 35%, rgba(255,255,255,1) 100%);

  button {
    padding: 0;
    background: transparent;
    border: none;
  }


  .start {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 40px;
    }
  }

  .chain,
  .chain-loop {
    cursor: pointer;
    margin-left: 10px;
    padding-top: 5px;

    img {
      width: 20px;
    }
  }

  .scene-tabs {
    margin-left: 5px;
    // background: #858;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 5px;
    flex-wrap: wrap;

    .scene-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ddd;
      border: 2px solid #bbb;
      padding: 5px 5px 5px 5px;
      margin: 1px 0 1px 2px;
      min-width: 110px;
      min-height: 34px;
      cursor: pointer;
      box-sizing: border-box;


      &.currentScene {
        box-shadow: 0px -1px 24px 3px rgba(255, 255, 255, 0.75);
        border: 2px solid rgb(63, 59, 59);
        cursor: auto;
      }

      .delete-scene {
        min-width: 18px;
        min-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-left: 4px;
      }
    }
  }

  .add-scene {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    min-height: 18px;
    margin-right: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .reset {
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 40px;
    }
  }
}


</style>
