import { AudioManager as AM } from "@/audio/AudioManager"
import * as HELPERS from "@/utils/helpers"
import * as CONSTANTS from "@/constants/constants"

// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************

export const updateChangeTotal = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].changeTotal = parseInt(payload.value, 10)
}

export const updateChangePer = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].changePer = parseInt(payload.value, 10)
}

export const adjustRange = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (payload.range === 'high') {
    // console.log("adjusting high")
    scene.tracks[payload.trackNumber].rangeHigh = payload.pitch
  } else if (payload.range === 'low'){
    // console.log("adjusting low")
    scene.tracks[payload.trackNumber].rangeLow = payload.pitch
  }
}

export const updateTrackPitchPercent = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].pitchPercent = payload.value
}

export const mToggleTrackMute = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let track= scene.tracks[trackNumber]
  if (!track.muted) {
    track.gainPreMute = track.gain
    track.gain = 0
    track.muted = true
  } else if (track.muted) {
    track.gain = track.gainPreMute
    track.muted = false
  }
}

export const mAddTrack = (state, track) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks.push(track)
}

export const spliceNoteIntoTune = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (payload.trackIndex || payload.trackIndex === 0){
    // console.log(scene.tracks[payload.trackIndex].tune)
    scene.tracks[payload.trackIndex].tune.splice(payload.start, payload.delete, payload.insert)
  } else {
    scene.tracks[scene.editingTrackNumber].tune.splice(payload.start, payload.delete, payload.insert)
  }
}

export const updateSelectedLength = (state, value) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.selectedLength = value
}

export const updateNoteRandomMobile = (state, payload) => {  // random what?
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  // console.log("payload", payload)
  scene.tracks[payload.trackNumber].tune[payload.noteIndex].random = payload.randomType
}

export const updateTrackNote = (state, payload) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let tune = scene.tracks[scene.editingTrackNumber].tune
  tune[payload.index].pitch = payload.pitch
}

export const updateTrackNoteMobile = (state, payload) => {
  // console.log('payload', payload)
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let tune = scene.tracks[payload.trackNumber].tune
  tune[payload.noteIndex].pitch = payload.pitch
}

export const mDeleteNote = (state, payload) => {
  // console.log('payload', payload);
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[payload.trackNumber].tune.splice(payload.noteIndex, 1)
}

export const assignEditingTrackIdFromNumber = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.editingTrackId = scene.tracks[scene.editingTrackNumber].id
}

// TODO remove this
// export const moveEndcap = state => {
//   let scene = state.flow.scenes[state.flow.editingSceneNumber]
//   scene.tracks.forEach( track => {
//     if (track.tune.length > 0 && track.tune[track.tune.length-1].pitch === '_') { track.tune.pop() }
//   })
//   scene.tracks[scene.editingTrackNumber].tune.push( {pitch:"_",random:'fixed'} )
// }

export const mRemoveTrack = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks.splice(trackNumber, 1)
}

export const changeEditingIndex = (state, change) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (change === 'increment') { scene.editingIndex++ }
  else if (change === 'decrement') { scene.editingIndex-- }
  else if (change === 'zero') { scene.editingIndex = 0 }
  else if (change === 'endcap') { scene.editingIndex = scene.tracks[scene.editingTrackNumber].tune.length-1 }
  else if (change === 'plusEight') {
    let newEditingIndex = scene.editingIndex + 8
    let endcap = scene.tracks[scene.editingTrackNumber].tune.length-1
    if (newEditingIndex >= endcap) { scene.editingIndex = endcap }
    else { scene.editingIndex = newEditingIndex }
  }
  else if (change === 'minusEight') {
    let newEditingIndex = scene.editingIndex - 8
    if (newEditingIndex <= 0) { scene.editingIndex = 0 }
    else { scene.editingIndex = newEditingIndex }
  }
  else if (change === 'adjust') {
    // seems like maybe this logic should be in the action?
    if (scene.editingIndex >= scene.tracks[scene.editingTrackNumber].tune.length) {
      scene.editingIndex = scene.tracks[scene.editingTrackNumber].tune.length-1
    }
  }
  //console.log("cEI new editingIndex", state.flow.editingIndex)
}

export const updateLeadTrack = (state, value) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.leadTrackId = scene.tracks[value].id
}

// DRAGGABLE
export const updateTracks = (state, tracks) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks = tracks
}

export const updateEditingTrackNumber = (state, indexOfEditingTrack) => {
  // console.log('iOET', indexOfEditingTrack)
  state.flow.scenes[state.flow.editingSceneNumber].editingTrackNumber = indexOfEditingTrack
}

export const toggleSoundPanel = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[trackNumber].soundPanel = !scene.tracks[trackNumber].soundPanel
}

// call this updateTracKTune or something.
// writeNewTune should be for the function in fill which does the composing
export const writeNewTune = (state, payload) => {
  const { tune, trackNumber } = payload
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  // scene.tracks[scene.editingTrackNumber].tune = tune
  scene.tracks[trackNumber].tune = tune
  // scene.editingIndex = tune.length-1
  // if (scene.editingTrackId === scene.leadTrackId) {
  //   scene.selectedLength = tune.length-1
  // }
}

export const toggleHidePitches = (state, trackNumber) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[trackNumber].hidePitches = !scene.tracks[trackNumber].hidePitches
}

export const toggleNoteRandom = (state) => {  // random what?
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  let noteRandomValue = scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random
  if (noteRandomValue === 'fixed') {
    scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random = 'noRests'
  } else if (noteRandomValue === 'noRests') {
    scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random = 'rests'
  } else if (noteRandomValue === 'rests') {
    //noteRandomValue = 'fixed'  (why doesn't this work? I guess since it's a string it's a pass by value)
    scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random = 'fixed'
  }
}


export const changeEditingTrackNumber = (state, change) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  if (change === 'increment') { scene.editingTrackNumber++ }
  else if (change === 'decrement') { scene.editingTrackNumber-- }
}

export const setTrackColorAlternation = (state, count) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.tracks[scene.editingTrackNumber].colorAlternation = count
}

// export const updateTrackLineBreaks = (state, trackLineBreaks) => {
//   let scene = state.flow.scenes[state.flow.editingSceneNumber]
//   scene.tracks[scene.editingTrackNumber].lineBreaks = trackLineBreaks
// }


// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

export const toggleTrackMute = (context, trackNumber) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let track = scene.tracks[trackNumber]

  if (!track.muted) {
    AM.scenes[context.getters.activeSceneTitle].gains[trackNumber].gain.value = 0
  } else if (track.muted) {
    AM.scenes[context.getters.activeSceneTitle].gains[trackNumber].gain.value = track.gainPreMute
  }
  context.commit('mToggleTrackMute', trackNumber)
}

// Fyi addTrack is not used in setUpNewScene
export const addTrack = (context) => {
  const scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  const leadTrack = scene.tracks[context.getters.leadTrackNumber];
  // console.log('leadTrack', leadTrack);
  // console.log('leadTrack.tune', leadTrack.tune);
  // console.log('leadTrack.tune.length', leadTrack.tune.length);

  const newTrack = context.getters.newTrack()
  newTrack.tune = HELPERS.createTune({
    length: leadTrack.tune.length
  })
  context.commit('mAddTrack', newTrack)

  context.dispatch('initializeSceneAudio', context.state.flow.editingSceneNumber)
}

// VS "randomNote"
export const addRandomNoteToTrack = (context, trackNum) => {
  // note 'randomNote' action, this may replace it
  const scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  const track = scene.tracks[trackNum]
  const newPitch = HELPERS.randomElement(context.getters.pitchSets[trackNum]);
  const note = { pitch: newPitch, random: 'rests' }
  const start = track.tune.length -1
  context.commit('spliceNoteIntoTune', { start:start, delete:0, insert:note, trackIndex: trackNum })

  // how about this?
  context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
}

// not using yet, here for comparison:
// export const addRandomNoteToAllTracks = (context) => {
//   let scene = context.state.scenes[context.state.editingSceneNumber]
//   scene.tracks.forEach( (track, index) => {
//     const pitch = randomElement(context.getters.pitchSets[index]);
//     const note = { pitch: pitch, random: 'rests' }
//     const start = scene.editingTrackNumber === index ? -1 : scene.tracks[index].tune.length
//     context.commit('spliceNoteIntoTune', { start:start, delete:0, insert:note, trackIndex: index })
//   })
//   context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
// }

export const removeTrack = (context, removeTrackNumber) => {
  // console.log("removeTrackNumber", removeTrackNumber)
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (scene.editingTrackNumber === removeTrackNumber && removeTrackNumber != 0 ) {
    context.commit('changeEditingTrackNumber', 'decrement')
    context.commit('assignEditingTrackIdFromNumber')
    // context.commit('moveEndcap')
  }
  if (scene.editingTrackNumber > removeTrackNumber) {
    context.commit('changeEditingTrackNumber', 'decrement')
  }
  context.commit('mRemoveTrack', removeTrackNumber)
  context.commit('changeEditingIndex', 'adjust')
  context.dispatch('initializeSceneAudio', context.state.flow.editingSceneNumber)
}

// DRAGGABLE
export const setTracks = (context, tracks) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  context.commit('updateTracks', tracks)
  let indexOfEditingTrack = ''
  scene.tracks.forEach( (track, index) => {
    if (track.id === scene.editingTrackId) { indexOfEditingTrack = index }
  })
  context.commit('updateEditingTrackNumber', indexOfEditingTrack)
  context.dispatch('initializeSceneAudio', context.state.flow.editingSceneNumber)
}

// RANDOMIZING
// Perhaps want to rename this "Randomize"
export const fill = (context, trackNumber) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (!trackNumber) trackNumber = scene.editingTrackNumber
  let track = scene.tracks[trackNumber]

  const filledTune = HELPERS.createTune({
    length: track.tune.length
    // fillFirst: false // ?
  })
  context.commit('writeNewTune', {tune: filledTune, trackNumber })

  // incomplete (haven't done form yet)
  let formSection = scene.harmonicForm[scene.formStep]
  if ( formSection !== undefined && formSection.match(/([c|d|f|g|a]#?|[b|e])\\/i) !== null) {
    context.dispatch('updateTuneWithPrefix', {trackIndex: scene.editingTrackNumber, formSection: formSection})
  }
}

export const distribute = (context, trackNumber) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]

  if (!trackNumber) trackNumber = scene.editingTrackNumber
  let track = scene.tracks[trackNumber]

  let pitchSet = context.getters.pitchSets[trackNumber].slice(0)
  HELPERS.shuffle(pitchSet)  // ; console.log("before:",context.getters.pitchSets[context.state.editingTrackNumber]) ;console.log("after:", pitchSet)

  let distributedTune = [{ pitch: pitchSet[0], random:'noRests' }]
  let i = 1
  const pitchPercent = track.pitchPercent || 1
  while (i < pitchSet.length){
    if (Math.random()*100 < pitchPercent){
      //distributedTune.push({ pitch: pitchSet[i], random: true })
      distributedTune.push({ pitch: pitchSet[i], random: 'rests' })
      i++
    } else {
      //distributedTune.push({ pitch: ' ', random: true })
      distributedTune.push({ pitch: ' ', random: 'rests' })
    }
  }
  distributedTune.push({ pitch: '+', random: 'fixed' })

  context.commit('writeNewTune', { tune: distributedTune, trackNumber })

  // incomplete (haven't done form yet)
  let formSection = scene.harmonicForm[scene.formStep]
  if ( formSection !== undefined && formSection.match(/([c|d|f|g|a]#?|[b|e])\\/i) !== null) {
    context.dispatch('updateTuneWithPrefix', {trackIndex: scene.editingTrackNumber, formSection: formSection})
  }
}

export const noteEntry = (context, payload) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  const random = scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random
  const note = { pitch: payload.pitch, random:random }
  if (payload.change === 'fromEndcap') {
    context.commit('spliceNoteIntoTune', { start:-1, delete:0, insert:note })
    context.commit('changeEditingIndex', 'increment')
  } else if (payload.change === 'currentNote') {
    if (context.state.general.editMode === 'insert') {
      context.commit('spliceNoteIntoTune', { start:scene.editingIndex, delete:0, insert:note })
      context.commit('changeEditingIndex', 'increment')
    } else if (context.state.general.editMode === 'overwrite'){
      context.commit('spliceNoteIntoTune', { start:scene.editingIndex, delete:1, insert:note })
    }
  }
  // DRY re: randomNote
  if (scene.editingTrackNumber === context.getters.leadTrackNumber) {
    context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
  }
}

export const deleteNote = (context, change) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (change === 'fromEndcap') {
    console.log('fromEndcap');
    if (scene.editingIndex > 0) {
      console.log('scene.editingIndex', scene.editingIndex);
      context.commit('mDeleteNote', { trackNumber: scene.editingTrackNumber, noteIndex: scene.editingIndex-1 } )
      context.commit('changeEditingIndex', 'decrement')
    }
  } else if (change === 'currentNote') {
    context.commit('mDeleteNote', {trackNumber: scene.editingTrackNumber, noteIndex: scene.editingIndex } )
  }
  if (scene.editingTrackNumber === context.getters.leadTrackNumber) {
    context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
  }
}

// VS addRandomNoteToTrack?
export const randomNote = (context, payload) => {
  console.log("payload", payload)
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let isRandom = (payload.random === 'check') ? scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random : payload.random
  const note = { pitch: payload.pitch, random: isRandom }
  if (payload.change === 'fromEndcap') {
    context.commit('spliceNoteIntoTune', { start:-1, delete:0, insert:note })
    context.commit('changeEditingIndex', 'increment')
  } else if (payload.change === 'currentNote') {
    if (context.state.general.editMode === 'insert') {
      context.commit('spliceNoteIntoTune', { start:scene.editingIndex, delete:0, insert:note })
      context.commit('changeEditingIndex', 'increment')
    } else if (context.state.general.editMode === 'overwrite'){
      context.commit('spliceNoteIntoTune', { start:scene.editingIndex, delete:1, insert:note })
    }
  } else if (payload.change === 'toggleNoteRandom'){
    context.commit('toggleNoteRandom') //, { start:context.state.editingIndex, delete:1, insert:note })
  }
  // DRY re: noteEntry
  if (scene.editingTrackNumber === context.getters.leadTrackNumber) {
    context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
  }
}

export const changeEditingTrack = (context, change) => {
  if (change === 'increment') { context.commit('changeEditingTrackNumber', 'increment') }
  else if (change === 'decrement') { context.commit('changeEditingTrackNumber', 'decrement') }
  context.commit('assignEditingTrackIdFromNumber')
  // context.commit('moveEndcap')
  context.commit('changeEditingIndex', 'adjust')
}

export const spreadTune = context => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let originalTune = JSON.parse(JSON.stringify(scene.tracks[scene.editingTrackNumber].tune))
  originalTune.splice(-1,1)
  let newTune = []
  for (let i=0; i < originalTune.length; i++){
    newTune.push(originalTune[i])
    newTune.push({ pitch:' ', random: 'fixed'})
  }
  newTune.push({ pitch:'+', random:'fixed' })
  context.commit('overwriteTrackTune', { trackNumber: scene.editingTrackNumber, newTune: newTune })
  if (scene.editingTrackNumber === context.getters.leadTrackNumber) {
    context.commit('updateSelectedLength', newTune.length - 1)
  }
}

export const doubleTune = context => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let originalTune = JSON.parse(JSON.stringify(scene.tracks[scene.editingTrackNumber].tune))
  originalTune.splice(-1,1)
  let double = JSON.parse(JSON.stringify(originalTune))
  let doubledTune = originalTune.concat(double)
  doubledTune.push({ pitch:'_', random:'fixed' })
  context.commit('overwriteTrackTune', { trackNumber: scene.editingTrackNumber, newTune: doubledTune })
  if (scene.editingTrackNumber === context.getters.leadTrackNumber) {
    context.commit('updateSelectedLength', doubledTune.length - 1)
  }
}

export const trackOctaveShift = (context, shift) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  let track = scene.tracks[scene.editingTrackNumber]
  track.tune.forEach( (note, index) => {
    if (note.pitch === ' ' || note.pitch === '+') { return }
    let pitch
    let endParsed = parseInt(note.pitch.slice(-2), 10)
    if (!isNaN(endParsed)) {
      pitch = note.pitch.slice(0, -2)
    } else {
      endParsed = parseInt(note.pitch.slice(-1), 10)
      pitch = note.pitch.slice(0,-1)
    }
    if (shift === 'down') { endParsed-- }
    else if (shift === 'up') { endParsed++ }
    let newPitch = pitch+endParsed
    context.commit('updateTrackNote', { index: index, pitch: newPitch })
  })
  let highRangeSplit = HELPERS.naturalSplit(track.rangeHigh)
  let lowRangeSplit = HELPERS.naturalSplit(track.rangeLow)
  if (shift==='up' && CONSTANTS.FULLRANGE.indexOf(track.rangeHigh) < 73){
    let newRangeHigh = highRangeSplit[0]+(highRangeSplit[1]+1)
    let newRangeLow = lowRangeSplit[0]+(lowRangeSplit[1]+1)
    context.commit('adjustRange', { trackNumber: scene.editingTrackNumber, range: 'high', pitch: newRangeHigh })
    context.commit('adjustRange', { trackNumber: scene.editingTrackNumber, range: 'low', pitch: newRangeLow })
  }
  if (shift==='down' && CONSTANTS.FULLRANGE.indexOf(track.rangeLow) > 11){
    let newRangeHigh = highRangeSplit[0]+(highRangeSplit[1]-1)
    let newRangeLow = lowRangeSplit[0]+(lowRangeSplit[1]-1)
    context.commit('adjustRange', { trackNumber: scene.editingTrackNumber, range: 'low', pitch: newRangeLow })
    context.commit('adjustRange', { trackNumber: scene.editingTrackNumber, range: 'high', pitch: newRangeHigh })
  }
}

export const addRandomNoteToAllTracks = (context) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  scene.tracks.forEach( (track, index) => {
    const pitch = HELPERS.randomElement(context.getters.pitchSets[index]);
    const note = { pitch: pitch, random: 'rests' }
    // const start = scene.editingTrackNumber === index ? -1 : scene.tracks[index].tune.length // this was for endCap
    const start = -1
    context.commit('spliceNoteIntoTune', { start:start, delete:0, insert:note, trackIndex: index })
  })
  context.commit('updateSelectedLength', context.getters.toneTunes[context.getters.leadTrackNumber].length)
}

export const deleteNoteFromAllTracks = (context) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  scene.tracks.forEach( (track, index) => {
    if (track.tune.length > 1) {
      context.commit('mDeleteNote', {trackNumber: index, noteIndex: track.tune.length - 2})
      if (scene.editingIndex >= track.tune.length) {
        // this is needed in case the editingIndex was currently at the endcap
        context.commit('changeEditingIndex', 'endcap')
      }
    }
    context.commit('updateSelectedLength', track.tune.length-1)
  })
}

export const noteShift = (context, shift) => {
  let scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  // let track = scene.tracks[scene.editingTrackNumber]
  let tune = context.getters.toneTunes[scene.editingTrackNumber]
  let note = tune[scene.editingIndex]
  let newNote = {}
  const random = scene.tracks[scene.editingTrackNumber].tune[scene.editingIndex].random
  if (note === 0 || note === undefined) { return }
  let noteArr = note.split('')  // this business would probably be easier with string.slice
  if ( (noteArr[1] === "#" && noteArr.length === 4) || (noteArr[1] !== "#" && noteArr.length === 3) ) { return }
  if (noteArr[1] === "#") { noteArr[0] = noteArr[0]+'#'; noteArr.splice(1,1) }
  let octave = ''
  if (shift === 'octave-up') {
    // console.log("noteArr", noteArr)
    let noteArrNum = noteArr[1]
    octave = parseInt(noteArrNum, 10)
    octave++
    if (octave > 9) { return }
    else { newNote = { pitch: noteArr[0]+octave, random:random } }
  } else if (shift === 'octave-down') {
    let noteArrNum = noteArr[1]
    octave = parseInt(noteArrNum, 10)
    octave--
    if (octave < 0) { return }
    else { newNote = { pitch: noteArr[0]+octave, random:random } }
  } else if (shift === 'pitchSetFullRange-up') {
    let noteIndex = context.getters.pitchSetFullRange.indexOf(note)
    noteIndex++
    let newPitch
    if (context.getters.pitchSetFullRange[noteIndex] === undefined) { return }
    else { newPitch = context.getters.pitchSetFullRange[noteIndex] }
    newNote = { pitch: newPitch, random:random }
  } else if (shift === "pitchSetFullRange-down") {
    let noteIndex = context.getters.pitchSetFullRange.indexOf(note)
    noteIndex--
    let newPitch
    if (context.getters.pitchSetFullRange[noteIndex] === undefined) { return }
    else { newPitch = context.getters.pitchSetFullRange[noteIndex] }
    newNote = { pitch: newPitch, random:random }
  }
  context.commit('spliceNoteIntoTune', { start:scene.editingIndex, delete:1, insert:newNote })
}

// export const getTrackGroupings = context => {
//   const promptData = prompt("Enter track line lengths, separated by spaces:")
//   let trackLines = promptData.split(' ')
//   for (let i=0; i<trackLines.length; i++) {
//     if (isNaN(parseInt(trackLines[i], 10))) {
//       return
//     } else {
//       trackLines[i] = parseInt(trackLines[i], 10)
//     }
//   }
//   console.log("trackLines", trackLines)
//   let trackLineBreaks = trackLines.map( (val, index, arr) => {
//     let j=0
//     for (let k=0; k<=index; k++) { j+= arr[k] }
//     return j
//   })
//   console.log("trackLineBreaks", trackLineBreaks)
//   context.commit('updateTrackLineBreaks', trackLineBreaks )
// }
