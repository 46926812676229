// These state items are non-persistent

export const general = {
  namespaced: false,
  state: {
    playing: false,
    editMode: 'overwrite',
    sleepSetting: 0,
    startTime: 0,
    currentTime: 0,
    // probably won't use:
      // hideInfoMenu: false,
      // loadTarget: "TF3_default",
    playerUIOpen: true, // putting this here for now
    refs: {
      pianoSelector: null,
    },
    pianoSelectorRect: {},
  },

  getters: {
    pianoSelectorRect: state => {
      const pianoSelector = state.refs.pianoSelector
      const rect = pianoSelector ? pianoSelector.getBoundingClientRect() : {}
      return rect
    },
  },
  mutations: {
    togglePlay: state => {
      state.playing = !state.playing
    },
    togglePlayerUIOpen: state=> {
      state.playerUIOpen = !state.playerUIOpen
    },
    toggleEditMode: state => {
      if (state.editMode === 'insert') state.editMode = 'overwrite'
      else if (state.editMode === 'overwrite') state.editMode = 'insert'
    },
    setRef: (state, payload) => {
      // console.log('setRef payload', payload);
      state.refs[payload.name] = payload.value
    },
    mSetPianoSelectorRect: (state, payload) => {
      state.pianoSelectorRect = payload
    },
  },

  actions: {
    setPianoSelectorRect: context => {
      const rect = context.getters.pianoSelectorRect
      context.commit('mSetPianoSelectorRect', rect)
    }
  },
}

