import * as HELPERS from "../utils/helpers";


export const keypressCombos = function() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const that = this

  return [
    // CTRL-SHIFT-
    {
      "keys"              : "control shift p",
      // TODO that.editingTrackNUmber won't work
      "on_keydown"        : function() { that.$store.commit('toggleSoundPanel', that.editingTrackNumber) },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": false,
    },
    {
      "keys"              : "control shift }",
      "on_keydown"        : function() { that.$store.dispatch('changeAll', 'all')},
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": false,
    },
    {
      "keys"              : "control shift s",
      "on_keydown"        : function() { that.$store.dispatch('spreadTune') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control shift d",
      "on_keydown"        : function() { that.$store.dispatch('doubleTune') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control shift f",
      "on_keydown"        : function() { that.$store.commit('toggleFilterOnChange') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control shift x",
      "on_keydown"        : function() { that.$store.dispatch('trackOctaveShift', 'down') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control shift c",
      "on_keydown"        : function() { that.$store.dispatch('trackOctaveShift', 'up') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control shift m",
      "on_keydown"        : function() { HELPERS.findAndFocus.call(that, '#modulatePerLeadChanges') },
      "that": that, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": false,
    },


// SEQUENCE
    {
      "keys"              : "control shift 1",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 1) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 2",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 2) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 3",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 3) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 4",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 4) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 5",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 5) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 6",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 6) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 7",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 7) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 8",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 8) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 9",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 9) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift 0",
      "on_keydown"        : function() { this.$store.commit('assignPlayerParamSetting', 0) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift =",
      "on_keydown"        : function() { this.$store.dispatch('addRandomNoteToAllTracks') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift -",
      "on_keydown"        : function() { this.$store.dispatch('deleteNoteFromAllTracks') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift w",
      "on_keydown"        : function() { this.$store.commit('setSceneAdvanceCued', false) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift e",
      "on_keydown"        : function() { this.$store.commit('setSceneAdvanceCued', false) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift z",
      "on_keydown"        : function() {
        console.log("*")
        // const ps = document.getElementById('pianoSelectorId')
        // const rect = ps.getBoundingClientRect()
        // console.log('rect', rect);
        // const pianoSelector = this.$store.state.general.refs.pianoSelector
        // console.log('pianoSelector', pianoSelector);
        // const refRect = pianoSelector.getBoundingClientRect()
        // console.log('refRect', refRect);
        console.log(this.$store.getters.pianoSelectorX());
        console.log(this.$store.getters.pianoSelectorY());
      },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift a",
      "on_keydown"        : function() { console.log("*** *** ***") },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },

// TODO (reading 'title' of undefined)
     {
      "keys"              : "control shift i",
      "on_keydown"        : function() { this.$store.dispatch('initializeSceneAudio', this.$store.state.editingSceneNumber) },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },
    {
      "keys"              : "control shift n",
      "on_keydown"        : function() { this.$store.dispatch('setUpNewScene') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true, "is_sequence": true,
    },


  // CTRL-

    {
      "keys"              : "control enter",
      "on_keydown"        : function()  { this.$store.dispatch('addTrack') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      "keys"              : "control b",
      "on_keydown"        : function(e, count){ this.$store.dispatch('getTrackGroupings') },
      "this": this,  "prevent_default": false, "prevent_repeat": true, "is_counting": false,
    },
    {
      "keys"              : "control space",
      "on_keydown"        : function(e, count){
        if (this.$store.state.flow.activeRegion === 'tune-entry'){ this.$store.commit('setTrackColorAlternation', count) }
      },
      "this": this,  "prevent_default": false, "prevent_repeat": false, "is_counting": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "control left",
      "keys"              : "cmd left",
      "on_keydown"        : function() { this.$store.commit('changeEditingIndex', 'minusEight') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "control right",
      "keys"              : "cmd right",
      "on_keydown"        : function() { this.$store.commit('changeEditingIndex', 'plusEight') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "control up",
      "keys"              : "cmd up",
      "on_keydown"        : function() { this.$store.dispatch('noteShift', 'pitchSetFullRange-up') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "control down",
      "keys"              : "cmd down",
      "on_keydown"        : function() { this.$store.dispatch('noteShift', 'pitchSetFullRange-down') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },

  // ALT-
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "alt left",
      "keys"              : "option left",
      "on_keydown"        : function() { this.$store.commit('changeEditingIndex', 'zero') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "alt right",
      "keys"              : "option right",
      "on_keydown"        : function() { this.$store.commit('changeEditingIndex', 'endcap') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "alt up",
      "keys"              : "shift up",
      "on_keydown"        : function() { this.$store.dispatch('noteShift', 'octave-up') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
    {
      // TODO MAC & WINDOWS...
      // "keys"              : "alt down",
      "keys"              : "shift down",
      "on_keydown"        : function() { this.$store.dispatch('noteShift', 'octave-down') },
      "this": this, "prevent_default": false, "prevent_repeat": true, "is_unordered": true,
    },
  ]
}
