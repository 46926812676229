import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"

import STATEDEFAULTS from "./stateDefaults.js"
import * as CONSTANTS from "@/constants/constants"
const stateDefaultsParsed = JSON.parse(JSON.stringify(STATEDEFAULTS))

import { auth } from "./modules/auth.js"
import { general } from "./modules/general.js"
import { generalPersist } from "./modules/generalPersist.js"
import { player } from "./modules/player.js"
import { flows } from "./modules/flows.js"
import * as setup from "./extractions/setup.js"
import * as engine from "./extractions/engine.js"
import * as scenes from "./extractions/scenes.js"
import * as scene from "./extractions/scene.js"
import * as tracks from "./extractions/tracks.js"
import * as soundPanel from "./extractions/soundPanel.js"
import * as hmm from "./extractions/hmm.js"
import * as qwerty from "./extractions/qwerty.js"


export default createStore({
  state: {
    // stateDefaults contains player settings, might move those
    flow: stateDefaultsParsed,
    useServer: false,
    // useServer: true,
  },
  modules: {
    auth,
    flows,
    general, // TODO "generalNonPersist"
    generalPersist,
    player,
  },
  plugins: [createPersistedState({
    paths: ['auth', 'flow', 'flows', 'generalPersist', 'player']
  })],

  getters: {
    newTrack: state => () => {
      // https://stackoverflow.com/questions/50070900/json-object-copy-in-vuex-getter
      const newTrack = JSON.parse(JSON.stringify(CONSTANTS.NEW_TRACK_DEFAULTS))
      const idNumber = Math.random().toString().slice(2)
      newTrack.id = idNumber
      return newTrack
    },
    activeSceneTitle: state => {
      return state.flow.scenes[state.flow.editingSceneNumber].title
    },

    // ENGINE
    toneTunes: state => engine.toneTunes(state),
    pitchSets: state => engine.pitchSets(state),
    maxChangeables: state => engine.maxChangeables(state),
    selectedModulations: state => engine.selectedModulations(state),

    // Hmm...
    leadTrackNumber: state => {  // Found first in ModulationControls but seems pretty general
      let scene = state.flow.scenes[state.flow.editingSceneNumber]
      let leadTrackNumber = ''
      scene.tracks.forEach( (track, index) => {
        if (track.id === scene.leadTrackId) { leadTrackNumber = index }
      })
      return leadTrackNumber
    },
    advanceSceneTitle: state => {
        return state.flow.scenes[state.flow.sceneChangeNumber].title
    },
    pitchSetFullRange: state => hmm.pitchSetFullRange(state),

    // QWERTY
    qwertyVals: state => qwerty.qwertyVals(state),

  },


  mutations: {

    // SETUP (SCENE MANAGEMENT)
    addNewScene: ( state, newScene ) => setup.addNewScene(state, newScene),
    mUpdateTrackSoundParams: (state, payload) => setup.mUpdateTrackSoundParams(state, payload),

    // ENGINE
    startScene: state => engine.startScene(state),
    changeToneTuneIndex: (state, payload) => engine.changeToneTuneIndex(state, payload),
    changeCycles: (state, payload) => engine.changeCycles(state, payload),
    toggleTrackChangeTriggered: (state, payload) => engine.toggleTrackChangeTriggered(state, payload),
    changeTuneNote: (state, payload) => engine.changeTuneNote(state, payload),
    overwriteTrackTune: (state, payload) => engine.overwriteTrackTune(state, payload),
    changeChainIncrement: (state, payload) => engine.changeChainIncrement(state, payload),
    setSceneAdvanceTriggered: (state, payload) => engine.setSceneAdvanceTriggered(state, payload),
    setSceneAdvanceCued: (state, payload) => engine.setSceneAdvanceCued(state, payload),
    updateSelectedMode: (state, payload) => engine.updateSelectedMode(state, payload),
    updateFormStep: (state, payload) => engine.updateFormStep(state, payload),
    updateNextModulation: (state, payload) => engine.updateNextModulation(state, payload),
    updateModulationCycles: (state, payload) => engine.updateModulationCycles(state, payload),
    toggleModulationTriggered: (state, payload) => engine.toggleModulationTriggered(state, payload),

    // SCENE
    updateSelectedNotes: (state, payload) => scene.updateSelectedNotes(state, payload),
    changeTempo: (state, payload) => scene.changeTempo(state, payload),
    resetScene: (state, payload) => scene.resetScene(state, payload),
    rememberTune: (state, payload) => scene.rememberTune(state, payload),
    tuneReturn: (state, payload) => scene.tuneReturn(state, payload),
    toggleSuspendChanges: (state, payload) => scene.toggleSuspendChanges(state, payload),
    updateAutoModulate: (state, payload) => scene.updateAutoModulate(state, payload),
    updateModulatePerLeadChanges: (state, payload) => scene.updateModulatePerLeadChanges(state, payload),
    toggleFilterOnChange: (state, payload) => scene.toggleFilterOnChange(state, payload),
    updateHarmonicForm: (state, payload) => scene.updateHarmonicForm(state, payload),
    updateModulationStyle: (state, payload) => scene.updateModulationStyle(state, payload),
    updateModulationWeights: (state, payload) => scene.updateModulationWeights(state, payload),
    updateSelectedRootPitches: (state, payload) => scene.updateSelectedRootPitches(state, payload),
    toggleEditingForm: (state, payload) => scene.toggleEditingForm(state, payload),

    // SCENES Plural
    toggleChain: (state, payload) => scenes.toggleChain(state, payload),
    toggleChainLoop: (state, payload) => scenes.toggleChainLoop(state, payload),
    mDeleteScene: (state, payload) => scenes.mDeleteScene(state, payload),
    dragScene: (state, payload) => scenes.dragScene(state, payload),
    setSceneChangeNumber: (state, payload) => scenes.setSceneChangeNumber(state, payload),
    mChangeScene: (state, payload) => scenes.mChangeScene(state, payload),
    mUpdateSceneTitle: (state, payload) => scenes.mUpdateSceneTitle(state, payload),
    updateChainAdvancePer: (state, payload) => scenes.updateChainAdvancePer(state, payload),
    toggleResetRememberedOnSceneChange: (state, payload) => scenes.toggleResetRememberedOnSceneChange(state, payload),
    assignScenePlayerParamSetting: (state, payload) => scenes.assignScenePlayerParamSetting(state, payload),

    // HMM (meta)
    changeActiveRegion: (state, payload) => hmm.changeActiveRegion(state, payload),
    changePreviousRegion: (state, payload) => hmm.changePreviousRegion(state, payload),
    toggleEntrySound: (state, payload) => hmm.toggleEntrySound(state, payload),
    updateFlowTitle: (state, payload) => hmm.updateFlowTitle(state, payload),
    toggleFlowPublic: (state, payload) => hmm.toggleFlowPublic(state, payload),
    setFlowId: (state, payload) => hmm.setFlowId(state, payload),
    setFlowUserId: (state, payload) => hmm.setFlowUserId(state, payload),
    resetFlow: state => hmm.resetFlow(state),
    setNewFlow: (state, payload) => hmm.setNewFlow(state, payload),

    // TRACKS
    updateChangeTotal: (state, payload) => tracks.updateChangeTotal(state, payload),
    updateChangePer: (state, payload) => tracks.updateChangePer(state, payload),
    adjustRange: (state, payload) => tracks.adjustRange(state, payload),
    updateTrackPitchPercent: (state, payload) => tracks.updateTrackPitchPercent(state, payload),
    mToggleTrackMute: (state, payload) => tracks.mToggleTrackMute(state, payload),
    mAddTrack: (state, payload) => tracks.mAddTrack(state, payload),
    spliceNoteIntoTune: (state, payload) => tracks.spliceNoteIntoTune(state, payload),
    updateSelectedLength: (state, payload) => tracks.updateSelectedLength(state, payload),
    updateNoteRandomMobile: (state, payload) => tracks.updateNoteRandomMobile(state, payload),
    updateTrackNote: (state, payload) => tracks.updateTrackNote(state, payload),
    updateTrackNoteMobile: (state, payload) => tracks.updateTrackNoteMobile(state, payload),
    mDeleteNote: (state, payload) => tracks.mDeleteNote(state, payload),
    assignEditingTrackIdFromNumber: (state, payload) => tracks.assignEditingTrackIdFromNumber(state, payload),
    // TODO remove moveEndcap
    // moveEndcap: (state, payload) => tracks.moveEndcap(state, payload),
    mRemoveTrack: (state, payload) => tracks.mRemoveTrack(state, payload),
    changeEditingIndex: (state, payload) => tracks.changeEditingIndex(state, payload),
    updateLeadTrack: (state, payload) => tracks.updateLeadTrack(state, payload),
    updateTracks: (state, payload) => tracks.updateTracks(state, payload),
    updateEditingTrackNumber: (state, payload) => tracks.updateEditingTrackNumber(state, payload),
    toggleSoundPanel: (state, payload) => tracks.toggleSoundPanel(state, payload),
    writeNewTune: (state, payload) => tracks.writeNewTune(state, payload),
    toggleHidePitches: (state, payload) => tracks.toggleHidePitches(state, payload),
    toggleNoteRandom: (state, payload) => tracks.toggleNoteRandom(state, payload),
    changeEditingTrackNumber: (state, payload) => tracks.changeEditingTrackNumber(state, payload),
    setTrackColorAlternation: (state, payload) => tracks.setTrackColorAlternation(state, payload),
    // updateTrackLineBreaks: (state, payload) => tracks.updateTrackLineBreaks(state, payload),

    // SOUND PANEL
    changeTrackNoteDuration: (state, payload) => soundPanel.changeTrackNoteDuration(state, payload),
    mChangeTrackWave: (state, payload) => soundPanel.mChangeTrackWave(state, payload),
    toggleTrackDelay: (state, payload) => soundPanel.toggleTrackDelay(state, payload),
  },


  actions: {

    // SETUP (SCENE MANAGEMENT)
    initializeApp: context => setup.initializeApp(context),
    setUpNewScene: context => setup.setUpNewScene(context),
    initializeSceneAudio: (context, sceneNumber) => setup.initializeSceneAudio(context, sceneNumber),
    updateTrackSoundParams: (context, payload) => setup.updateTrackSoundParams(context, payload),
    setTrackAMSoundParams: (context, payload) => setup.setTrackAMSoundParams(context,payload),
    initializeAllSceneAudio: (context, payload) => setup.initializeAllSceneAudio(context,payload),

    // HMM
    resetDefaults: (context, payload) => hmm.resetDefaults(context,payload),

    // ENGINE
    morphSelectedNotes: (context, payload) => engine.morphSelectedNotes(context, payload),
    checkChainIncrementAndTriggerAdvance: (context, payload) => engine.checkChainIncrementAndTriggerAdvance(context, payload),
    checkAdvanceCueVsChangeIncrement: (context, payload) => engine.checkAdvanceCueVsChangeIncrement(context, payload),
    changeTune: (context, payload) => engine.changeTune(context, payload),
    updateTuneWithPrefix: (context, payload) => engine.updateTuneWithPrefix(context, payload),
    formStepAndChainIncrement: (context, payload) => engine.formStepAndChainIncrement(context, payload),
    changeScene: (context, payload) => engine.changeScene(context, payload),
    changeAll: (context, payload) => engine.changeAll(context, payload),

    // SCENE
    rememberAllTunes: (context, payload) => scene.rememberAllTunes(context, payload),
    returnAllTunes: (context, payload) => scene.returnAllTunes(context, payload),
    autoModulate: (context, payload) => scene.autoModulate(context, payload),
    toggleModulationStyle: (context, payload) => scene.toggleModulationStyle(context, payload),
    buildHarmonicForm: (context, payload) => scene.buildHarmonicForm(context, payload),
    toggleAutoModulate: (context, payload) => scene.toggleAutoModulate(context, payload),
    // morphSelectedNotes: (context, payload) => engine.morphSelectedNotes(context, payload), // in engine, could be scene

    // SCENES
    setUpSceneChange: (context, payload) => scenes.setUpSceneChange(context, payload),
    deleteScene: (context, payload) => scenes.deleteScene(context, payload),
    updateSceneTitle: (context, payload) => scenes.updateSceneTitle(context, payload),

    // TRACKS
    toggleTrackMute: (context, payload) => tracks.toggleTrackMute(context, payload),
    addTrack: (context, payload) => tracks.addTrack(context, payload),
    addRandomNoteToTrack: (context, payload) => tracks.addRandomNoteToTrack(context, payload),
    removeTrack: (context, payload) => tracks.removeTrack(context, payload),
    setTracks: (context, payload) => tracks.setTracks(context, payload),
    fill: (context, payload) => tracks.fill(context, payload),
    distribute: (context, payload) => tracks.distribute(context, payload),
    noteEntry: (context, payload) => tracks.noteEntry(context, payload),
    deleteNote: (context, payload) => tracks.deleteNote(context, payload),
    randomNote: (context, payload) => tracks.randomNote(context, payload),
    changeEditingTrack: (context, payload) => tracks.changeEditingTrack(context, payload),
    spreadTune: (context, payload) => tracks.spreadTune(context, payload),
    doubleTune: (context, payload) => tracks.doubleTune(context, payload),
    trackOctaveShift: (context, payload) => tracks.trackOctaveShift(context, payload),
    addRandomNoteToAllTracks: (context, payload) => tracks.addRandomNoteToAllTracks(context, payload),
    deleteNoteFromAllTracks: (context, payload) => tracks.deleteNoteFromAllTracks(context, payload),
    noteShift: (context, payload) => tracks.noteShift(context, payload),
    // getTrackGroupings: (context, payload) => tracks.getTrackGroupings(context, payload),

    // SOUND PANEL
    changeTrackInstrumentOrSample: (context, payload) => soundPanel.changeTrackInstrumentOrSample(context, payload),
    changeTrackWave: (context, payload) => soundPanel.changeTrackWave(context, payload),
  },
});
