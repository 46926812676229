import bassGtr_C1 from "./bassGtr_mp3s/bassGtr_C1.mp3"
import bassGtr_C2 from "./bassGtr_mp3s/bassGtr_C2.mp3"
import bassGtr_C3 from "./bassGtr_mp3s/bassGtr_C3.mp3"
import bassGtr_C4 from "./bassGtr_mp3s/bassGtr_C4.mp3"
import bassGtr_E4 from "./bassGtr_mp3s/bassGtr_E4.mp3"

import elecPno1_C1 from "./elecPno1_mp3s/elecPno1_C1.mp3"
import elecPno1_C2 from "./elecPno1_mp3s/elecPno1_C2.mp3"
import elecPno1_C3 from "./elecPno1_mp3s/elecPno1_C3.mp3"
import elecPno1_C4 from "./elecPno1_mp3s/elecPno1_C4.mp3"
import elecPno1_C5 from "./elecPno1_mp3s/elecPno1_C5.mp3"
import elecPno1_C6 from "./elecPno1_mp3s/elecPno1_C6.mp3"
import elecPno1_C7 from "./elecPno1_mp3s/elecPno1_C7.mp3"

import elecPno2_C1 from "./elecPno2_mp3s/elecPno2_C2.mp3"
import elecPno2_C2 from "./elecPno2_mp3s/elecPno2_C2.mp3"
import elecPno2_C3 from "./elecPno2_mp3s/elecPno2_C3.mp3"
import elecPno2_C4 from "./elecPno2_mp3s/elecPno2_C4.mp3"
import elecPno2_C5 from "./elecPno2_mp3s/elecPno2_C5.mp3"
import elecPno2_C6 from "./elecPno2_mp3s/elecPno2_C6.mp3"
import elecPno2_C7 from "./elecPno2_mp3s/elecPno2_C7.mp3"

import elecPno3_C2 from "./elecPno3_mp3s/elecPno3_C2.mp3"
import elecPno3_C3 from "./elecPno3_mp3s/elecPno3_C3.mp3"
import elecPno3_C4 from "./elecPno3_mp3s/elecPno3_C4.mp3"
import elecPno3_C5 from "./elecPno3_mp3s/elecPno3_C5.mp3"
import elecPno3_C6 from "./elecPno3_mp3s/elecPno3_C6.mp3"
import elecPno3_C7 from "./elecPno3_mp3s/elecPno3_C7.mp3"

import gtrMute_C1 from "./gtrMute_mp3s/gtrMute_C1.mp3"
import gtrMute_C2 from "./gtrMute_mp3s/gtrMute_C2.mp3"
import gtrMute_C3 from "./gtrMute_mp3s/gtrMute_C3.mp3"
import gtrMute_C4 from "./gtrMute_mp3s/gtrMute_C4.mp3"
import gtrMute_C5 from "./gtrMute_mp3s/gtrMute_C5.mp3"

import gtrSwell_C2 from "./gtrSwell_mp3s/gtrSwell_C2.mp3"
import gtrSwell_C3 from "./gtrSwell_mp3s/gtrSwell_C3.mp3"
import gtrSwell_C4 from "./gtrSwell_mp3s/gtrSwell_C4.mp3"
import gtrSwell_C5 from "./gtrSwell_mp3s/gtrSwell_C5.mp3"
import gtrSwell_C6 from "./gtrSwell_mp3s/gtrSwell_C6.mp3"

import marimba_C0 from "./marimba_mp3s/marimba_C0.mp3"
import marimba_C1 from "./marimba_mp3s/marimba_C1.mp3"
import marimba_C2 from "./marimba_mp3s/marimba_C2.mp3"
import marimba_C3 from "./marimba_mp3s/marimba_C3.mp3"
import marimba_C4 from "./marimba_mp3s/marimba_C4.mp3"
import marimba_C5 from "./marimba_mp3s/marimba_C5.mp3"
import marimba_C6 from "./marimba_mp3s/marimba_C6.mp3"
import marimba_C7 from "./marimba_mp3s/marimba_C7.mp3"

import piano1_C2 from "./piano1_mp3s/piano1_C2.mp3"
import piano1_C3 from "./piano1_mp3s/piano1_C3.mp3"
import piano1_C4 from "./piano1_mp3s/piano1_C4.mp3"
import piano1_C5 from "./piano1_mp3s/piano1_C5.mp3"
import piano1_C6 from "./piano1_mp3s/piano1_C6.mp3"
import piano1_G2 from "./piano1_mp3s/piano1_G2.mp3"
import piano1_G3 from "./piano1_mp3s/piano1_G3.mp3"
import piano1_G4 from "./piano1_mp3s/piano1_G4.mp3"
import piano1_G5 from "./piano1_mp3s/piano1_G5.mp3"

import stringSection_C0 from "./stringSection_mp3s/stringSection_C0.mp3"
import stringSection_C1 from "./stringSection_mp3s/stringSection_C1.mp3"
import stringSection_C2 from "./stringSection_mp3s/stringSection_C2.mp3"
import stringSection_C3 from "./stringSection_mp3s/stringSection_C3.mp3"
import stringSection_C4 from "./stringSection_mp3s/stringSection_C4.mp3"
import stringSection_C5 from "./stringSection_mp3s/stringSection_C5.mp3"
import stringSection_C6 from "./stringSection_mp3s/stringSection_C6.mp3"

const tones = {
  bassGtr_C1,
  bassGtr_C2,
  bassGtr_C3,
  bassGtr_C4,
  bassGtr_E4,

  elecPno1_C1,
  elecPno1_C2,
  elecPno1_C3,
  elecPno1_C4,
  elecPno1_C5,
  elecPno1_C6,
  elecPno1_C7,

  elecPno2_C1,
  elecPno2_C2,
  elecPno2_C3,
  elecPno2_C4,
  elecPno2_C5,
  elecPno2_C6,
  elecPno2_C7,

  elecPno3_C2,
  elecPno3_C3,
  elecPno3_C4,
  elecPno3_C5,
  elecPno3_C6,
  elecPno3_C7,

  gtrMute_C1,
  gtrMute_C2,
  gtrMute_C3,
  gtrMute_C4,
  gtrMute_C5,

  gtrSwell_C2,
  gtrSwell_C3,
  gtrSwell_C4,
  gtrSwell_C5,
  gtrSwell_C6,

  marimba_C0,
  marimba_C1,
  marimba_C2,
  marimba_C3,
  marimba_C4,
  marimba_C5,
  marimba_C6,
  marimba_C7,

  piano1_C2,
  piano1_C3,
  piano1_C4,
  piano1_C5,
  piano1_C6,
  piano1_G2,
  piano1_G3,
  piano1_G4,
  piano1_G5,

  stringSection_C0,
  stringSection_C1,
  stringSection_C2,
  stringSection_C3,
  stringSection_C4,
  stringSection_C5,
  stringSection_C6,
};

export default tones;
