<template>


  <div class="save-modal-background" @click="onClose">
    <div class="save-modal-inner" @click="$event.stopPropagation()">


      <div class="close-button" @click="onClose" >
        <img class="medium" src="@/assets/images/icons8-close-60-inverted.png" />
      </div>

      <h1>Save Options</h1>

      <div class="save-setting">
        <p>Flow Title:</p>
        <input
        class="flow-title"
          v-model="flowTitle"
        >
        <div
          class="random-flow-title"
          @click="pickRandomFlowTitle"
          title="Pick a random title for this flow"
        >
          <img class="small" src="../assets/images/icons8-dice-64.png" alt="Random Flow Title" />
        </div>
      </div>

      <p v-if="flow._id">flow id: {{ flow._id }}</p>


      <div v-if="!currentFlowIsSaved" class="save-button">
        <button @click="saveFlowLocal">
          Save
        </button>
        <p v-if="duplicateTitle">(You already have a flow saved with this title)</p>
      </div>

      <div v-if="currentFlowIsSaved" class="save-button">
        <button @click="updateFlowLocal">
          Update Flow
        </button>
      </div>

      <div v-if="currentFlowIsSaved" class="save-button">
        <button @click="saveAsLocal" >
          Save As New
        </button>
        <p v-if="duplicateTitle">(You might want to pick a new title first)</p>
      </div>

<!--      <button-->
<!--        class="reset-defaults"-->
<!--        @click="resetDefaults"-->
<!--      >-->
<!--        Reset-->
<!--      </button>-->

    </div>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import { randomElement } from "@/utils/helpers";
import * as CONSTANTS from "@/constants/constants"

export default defineComponent({
  name: "SaveModal",
  props: ['close', 'registerCombos', 'unregisterCombos'],
  data:() => ({
    // changeIncrements: ['Lead Cycle', 'Lead Change', 'Modulation', 'Form'],
  }),
  computed: {
    userId() {
      // const userId = this.$store.state.auth.user._id
      // console.log('SaveModal userId:', userId);
      // return userId;
      return ''
    },
    flow(){
      // console.log('>>>flow...', this.$store.state.flow);
      return this.$store.state.flow
    },
    flowUserId(){
      const flowUserId = this.$store.state.flow.userId
      // console.log('flowUserId', flowUserId);
      return flowUserId
    },
    flowTitle: {
      get(){
        return this.flow.flowTitle
      },
      set(title){
        this.$store.commit('updateFlowTitle', title)
      },
    },
    currentFlowIsSaved(){
      // actually you could just use this.flow._id checks
      // BUT can you delete a flow from the list and still have the flow set?
      let flowSaved = false
      this.$store.state.flows.localStoredFlows.forEach(e => {
        if (JSON.parse(e)._id === this.flow._id) flowSaved = true
      })

      return flowSaved
    },
    duplicateTitle() {
      const localFlows = this.$store.state.flows.localStoredFlows;

      const flowTitle = this.flow.flowTitle
      let titleCheck = false
      localFlows.forEach(e => {
        const parsed = JSON.parse(e)
        if (parsed.flowTitle === flowTitle) titleCheck = true;
      })

      return titleCheck
    }

  },
  methods: {
    pickRandomFlowTitle(){
      const newTitle = randomElement(CONSTANTS.SCENETITLES)
      this.$store.commit('updateFlowTitle', newTitle)
    },
    onClose(){
      const prevRegion = this.$store.state.flow.previousRegion
      console.log('prevRegion', prevRegion);
      if (prevRegion === 'bypass-dispatch') {
        this.$store.commit('changeActiveRegion', 'tune-entry')
      } else {
        this.$store.commit('changeActiveRegion', prevRegion)
      }
      this.registerCombos()
      this.close()
    },
    saveFlowLocal() {
      this.$store.dispatch('createFlowLocal', this.onClose)
    },
    updateFlowLocal() {
      this.$store.dispatch('updateFlowLocal', this.onClose)
    },
    saveAsLocal() {
      this.$store.dispatch('createFlowLocal', this.onClose)
    },
    // resetDefaults(){
    //   const doReset = window.confirm("This will wipe all data and reset defaults. Proceed?")
    //   console.log('doReset', doReset);
    //   this.$store.dispatch('resetDefaults')
    //   this.$props.close()
    // },
  },
  mounted(){
    this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
    this.$store.commit('changeActiveRegion', 'bypass-dispatch')
    this.unregisterCombos()
  }
});
</script>

<style lang="scss">
@import "../assets/variables";

.save-modal-background {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3; // 2 causes keyboard keys to peek through. why? NoteSelectModal has z-index 2 does not.
  //background: #555;
  background-color: rgba(10, 39, 30, 0.8);

  .save-modal-inner {
    display: flex;
    flex-direction: column;
    background-color: rgb(10, 39, 30);
    position: relative;
    padding: 40px 40px 40px;
    text-align: left;
    color: #fff;
    min-width: 560px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      img {
        width: 60px;
      }
    }


    .save-button {
      display: flex;
      align-items: center;
      margin-top: 10px;

      button {
        cursor: pointer;
        width: 120px;
        margin-right: 10px;
        margin-top: 0;
        padding: 5px;
      }
       
      p {
        margin: 0;
      }
    }

    .save-setting {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      p {
        font-size: 18px;
        margin-right: 10px;
        //width: 150px;
        color: white;

        &.reset-option-text {
          margin-right: 0;
        }
      }

      .random-flow-title {
        cursor: pointer;
        margin: 0 0 0 10px;

        img {
          width: 50px;
        }
      }

      input,
      select {
        font-size: 16px;
      }

      .flow-title {
        width: 250px;
        height: 24px;
      }

      //.lead-track-number {
      //  width: 60px;
      //  height: 24px;
      //}
      //
      //.reset-option {
      //  height: 20px;
      //}
    }
  }
}

</style>
