<template>
  <div class="profile">
<!--    TODO class="profile"-->
    <div v-if="user==null">Loading...</div>
    <div
      v-else
      class="profile__details"
    >
      <jazzicon :address="user._id" :diameter="80" />
      <p class="user-title">
        <span class="user-title__username">{{user.username}}</span>
        <span class="user-title__you" v-if="loggedInUser">(Your page!)</span>
      </p>
    </div>


    <FlowListing
      v-for="(flow, flowIndex) in flows"
      :flow="flow"
      :show-jazzicon="false"
      :show-username="false"
      :key="flow._id"
      :is-logged-in-user="loggedInUser"
      :delete-flow="deleteFlow"
    />

  </div>
</template>

<script>
// <script lang="ts">
// all manner of https://stackoverflow.com/questions/55168079/typescript-vue-js-property-xxx-does-not-exist-on-type-never from flowsLeadTrackNotes when using lang="ts"
import { defineComponent } from 'vue'
import router from "@/router"

import FlowListing from "@/components/general/FlowListing";
import Jazzicon from "vue3-jazzicon/src/components"


export default defineComponent({
  name: 'Profile', // TODO Profile
  props: ['id'],
  components: {
    FlowListing,
    [Jazzicon.name]: Jazzicon
  },
  data() {
    return {
      user: null,
      flows: [],
    }
  },
  computed: {

    loggedInUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user._id === this.id
      } else {
        return false
      }
    },
  },
  watch:{
    loggedInUser(value){  // for case when user changes a note.random to 'fixed'
      window.location.reload()
    },
  },
  methods: {
    async deleteFlow(flowId) {
      // console.log('delete flowId', flowId);
      const check = confirm("Delete this flow?")
      if (check === false){ return }

      const flowDeleted = await this.$store.dispatch('deleteFlow', flowId)
      // console.log('Profile flowDeleted...', flowDeleted);
      alert('flowDeleted')

      if (this.$store.state.flow._id === flowId) {
        this.$store.dispatch('resetDefaults')
      }

      await this.loadUserFlows()
    },
    async loadUserFlows() {
      // console.log('loadUserFlows');
      let error = false;
      await fetch(`http://localhost:4000/users/${this.id}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
          'X-Toneflow-Session-Key': 'test',
        },
      })
        .then(res => res.json())
        .then(async data => {
          // console.log('fetched profile data', data)
          this.user = data
        })
        .catch(err => {
          console.error(err)
          error = true
        })

      if (error) {
        alert('Fetch error or no user found with that id')
        await this.$store.dispatch('resetDefaults')
        await router.push(`/`)
        // Error: Uncaught (in Promise) TypeError: Cannot read properties of undefined (reading 'Listener') (dispatchTables.js:9)
        // ...which you can (wrongly) make go away by:
        window.location.reload()
      }
      // this.$store.dispatch('fetchUsers') // no need to get the store involved here


      // temporary use of current user id; replace with session key
      const currentUserId = this.$store.state.auth.user ? this.$store.state.auth.user._id : ''
      // const queryString = '?user_id=61ec44880c83f6563063334a'
      const queryString = `?user_id=${this.id}&current_user_id=${currentUserId}`

      // console.log('loading...');
      await fetch(`http://localhost:4000/flows/${queryString}`, {
        method: 'GET',
        // headers : new Headers(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(async data => {
          // console.log('fetched flows data', data)

          this.flows = data.flows
          // ctx.commit('login', data)
          // const { user: { id: userID} } = data
          // console.log('userID', userID)
          // await router.push(`/userIDs/${userID}`)
        })
        .catch(err => console.error(err))
    },
  },
  async mounted() {
    await this.loadUserFlows()
  },
  // computed: {
  //   users() {
  //     return this.users
  //   }
  // },
});
</script>;

<style lang="scss">
@import "../assets/variables";

.profile {
  padding: 20px;

  &__details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .user-title {
      margin-left: 10px;
      &__username {
        font-size: 34px;
      }

      &__you {
        margin-left: 10px;
      }
    }
  }
}

</style>
