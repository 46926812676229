
import { defineComponent } from 'vue'
// import authAPI from SOMETHING-OR-OTHER
// console.log('Signup.vue authAPI', authAPI)

export default defineComponent({
  name: 'Signup',
  props: ['registerCombos', 'unregisterCombos'],
  components: {},
  data() {
    return {
      // name: 'Link',
      // age: 25 as number | string
      username: '',
      email: '',
      password: '',
    }
  },
  methods: {
    // changeAge(age: number | string) {
    //   this.age = age
    //   return age
    // }
    handleSubmit() {
      // make request to database to save user
      console.log('username: ', this.username)
      console.log('email: ', this.email)
      console.log('password: ', this.password)

      const userData = {
        username: this.username,
        email: this.email,
        password: this.password,
      }

      this.$store.dispatch('register', userData)
    }
  },
  mounted() {
    console.log('Signup mounted');
    if (this.$store.state.auth.sessionKey != null) {
      this.$router.push('/')
    }
    this.unregisterCombos()
  },
  unmounted() {
    console.log('Signup unmounted');
    this.registerCombos()
  }
});
