import * as Tone from "tone"

import { AudioManager as AM } from "@/audio/AudioManager"
import * as CONSTANTS from "@/constants/constants"
import * as HELPERS from "@/utils/helpers";


// MUTATIONS ***************************************************************************************************************

export const addNewScene = ( state, newScene ) => {
  // console.log('M addNewScene');
  state.flow.scenes.push(newScene)
  // state.editingSceneId = newScene.id // let's not do that...
  // console.log('state.flow', state.flow);
}

export const mUpdateTrackSoundParams = (state, payload) => {
  // console.log('muTSP payload', payload)
  // console.log('muTSP payload.sceneNumber', payload.sceneNumber)
  const sceneNumber = payload.sceneNumber != null ? payload.sceneNumber : state.flow.editingSceneNumber
  // console.log('muTSP sceneNumber', sceneNumber)
  // console.log('muTSP state.flow.scenes', state.flow.scenes)
  const scene = state.flow.scenes[sceneNumber]
  // console.log('muTSP scene', scene);
  // console.log('scene', scene)
  // console.log('scene.title', scene.title)
  // console.log('scene.tracks', scene.tracks)
  const track = scene.tracks[payload.trackNumber]
  // console.log('track', track)
  track[payload.param] = payload.value
  if (payload.param === 'gain' && track.muted) { track.muted = false }
}


// ACTIONS ***************************************************************************************************************

export const initializeApp = context => {
  // console.log('initializeApp');
  if (context.state.flow.scenes.length === 0) {
    context.dispatch('setUpNewScene')
  }

  // due to persistedstate flow may already have one or more scenes
  context.dispatch('initializeAllSceneAudio')
}

export const initializeAllSceneAudio = context => {
  context.state.flow.scenes.forEach( (scene, index) => {
    context.dispatch('initializeSceneAudio', index)
  })
}

export const setUpNewScene = context => {
  // console.log('setupNewScene');
  // can prob be a mutation
  const newScene = JSON.parse(JSON.stringify(CONSTANTS.NEW_SCENE_DEFAULTS))
  newScene.id = Math.random().toString().slice(2) // https://stackoverflow.com/questions/8723229/how-to-generate-unique-object-id-in-mongodb
  newScene.title = HELPERS.randomElement(CONSTANTS.SCENETITLES)
  const firstTrack = context.getters.newTrack()
  firstTrack.tune = HELPERS.createTune({})

  newScene.tracks.push(firstTrack)
  newScene.editingTrackId = newScene.tracks[0].id
  newScene.leadTrackId = newScene.tracks[0].id
  // console.log('newScene', newScene);

  context.commit('addNewScene', newScene)
  context.dispatch('initializeSceneAudio', context.state.flow.scenes.length-1)
}


export const updateTrackSoundParams = (context, payload) => {
  // console.log('updateTrackSoundParams dispatch payload', payload)
  const title = payload.title ? payload.title : context.getters.activeSceneTitle
  if (payload.value === '' || (typeof payload.value === 'string' && payload.value.slice(0,1) === '.') ) { return }
  context.dispatch('setTrackAMSoundParams',  { param: payload.param, sceneTitle: title, trackNumber: payload.trackNumber, value: payload.value, track: payload.track } )
  context.commit('mUpdateTrackSoundParams', { param: payload.param, sceneNumber: payload.sceneNumber, trackNumber: payload.trackNumber, value: payload.value })
}


export const initializeSceneAudio = (context, sceneNumber) => {
  const title = context.state.flow.scenes[sceneNumber].title
  const sceneAudio = AM.scenes[title] // unstable use of title (no guarantee unique)
  for (let nodeList in sceneAudio){
    sceneAudio[nodeList].forEach( (nodeListItem, index) => {
      //nodeListItem.disconnect(Tone.Master) // good try, though.
      nodeListItem.dispose()
    })
  }
  AM.scenes[title] = { instruments:[], autoFilters: [], gains:[], delays:[], distortions:[] } // https://stackoverflow.com/questions/1168807/how-can-i-add-a-key-value-pair-to-a-javascript-object
  context.state.flow.scenes[sceneNumber].tracks.forEach( (track, tracksIndex) =>  {
    // console.log('initializing scene audio for ', sceneNumber, title)
    const trackInstrument = AM.instrument(track.instrumentType, track.sampleType, track)
    // console.log('trackInstrument', trackInstrument);
    AM.scenes[title].instruments.push(trackInstrument)
    const autoFilter = new Tone.AutoFilter({
      frequency  : track.LFOFrequency,
      type  : track.LFOWaveType,
      depth  : track.LFODepth,
      baseFrequency  : track.filterBaseFrequency ,
      octaves  : track.LFOOctaves,
      filter  : {
        type  : track.filterType,
        rolloff  : track.filterRolloff,
        Q  : track.filterQ
      }
    })
    autoFilter.wet.value = track.filterWet
    AM.scenes[title].autoFilters.push(autoFilter)
    const distortion = new Tone.Distortion(track.distortion)
    AM.scenes[title].distortions.push(distortion)
    const delay = new Tone.FeedbackDelay(track.delayTime, track.delayFeedback)
    AM.scenes[title].delays.push(delay)
    const trackGain  = new Tone.Gain(track.gain)
    AM.scenes[title].gains.push(trackGain)

    const gainValue = ( () => {
      switch (track.instrumentType){
        case 'polySynth': return track.polySynthGainDefault
        case 'monoSynth': return track.monoSynthGainDefault
        case 'sampler': return track.samplerGainDefault
      }
    })()
    context.dispatch('updateTrackSoundParams', { param:'gain', sceneNumber: sceneNumber, title: title, trackNumber: tracksIndex, value:gainValue, track: track })

  })
  //AM.scenes[title].instruments.forEach( (synth, i) => synth.toMaster() )
  AM.scenes[title].instruments.forEach( (instrument, i) => instrument.connect(AM.scenes[title].autoFilters[i]) )
  AM.scenes[title].autoFilters.forEach( (autoFilter, i) => autoFilter.connect(AM.scenes[title].distortions[i]).start() )
  AM.scenes[title].distortions.forEach( (distortion, i) => {
    distortion.fan(AM.scenes[title].gains[i], AM.scenes[title].delays[i] )
    distortion.wet.value = 0.5
    //distortion.distortion = 0
  })
  AM.scenes[title].delays.forEach( (delay, i) => {
    delay.connect(AM.scenes[title].gains[i])
    const track = context.state.flow.scenes[sceneNumber].tracks[i]
    if (!track.delayActive) {
      // console.log('track.delayActive', track.delayActive )
      delay.wet.value = 0
    }
  })

  AM.scenes[title].gains.forEach( (gain, i) => gain.toDestination() )
  // console.log('AM', AM);
}


export const setTrackAMSoundParams = (context, payload) => {
  // console.log('setTrackAMSoundParams', payload)
  switch (payload.param){
    case 'gain':
      AM.scenes[payload.sceneTitle].gains[payload.trackNumber].gain.value = payload.value
      break
    case 'delayTime':
      AM.scenes[payload.sceneTitle].delays[payload.trackNumber].delayTime.value = payload.value
      break
    case 'delayFeedback':
      AM.scenes[payload.sceneTitle].delays[payload.trackNumber].feedback.value = payload.value
      break
    case 'distortion':
      AM.scenes[payload.sceneTitle].distortions[payload.trackNumber].distortion = payload.value
      break
    case 'attack':
      if (payload.track.instrumentType === 'monoSynth' || payload.track.instrumentType === 'polySynth'){
        AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'envelope': { attack: payload.value } })
      } else {
        AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'attack': payload.value })
      }
      break
    case 'decay':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'envelope': { decay: payload.value } })
      break
    case 'sustain':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'envelope': { sustain: payload.value } })
      break
    case 'release':
      if (payload.track.instrumentType === 'monoSynth' || payload.track.instrumentType === 'polySynth'){
        AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'envelope': { release: payload.value } })
      } else {
        AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'release': payload.value })
        // console.log('release', AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].release)
      }
      break
    case 'portamento':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'portamento': payload.value })
      break
    case 'modulationType':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'modulationType' : payload.value } })
      break
    case 'modulationIndex':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'modulationIndex' : payload.value } })
      break
    case 'harmonicity':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'harmonicity' : payload.value } })
      break
    case 'count':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'count' : payload.value } })
      break
    case 'spread':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'spread' : payload.value } })
      break
    case 'modulationFrequency':
      AM.scenes[payload.sceneTitle].instruments[payload.trackNumber].set({ 'oscillator': { 'modulationFrequency' : payload.value } })
      break
    case 'filterWet':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].wet.value = payload.value
      break
    case 'filterType':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].filter.type = payload.value
      break
    case 'filterRolloff':
      let rolloff = parseInt(payload.value, 10)
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].filter.rolloff = payload.value
      break
    case 'filterBaseFrequency':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].baseFrequency = payload.value
      break
    case 'filterQ':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].filter.Q.value = payload.value
      break
    case 'LFOWaveType':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].type = payload.value
      break
    case 'LFOFrequency':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].frequency.value = payload.value
      // console.log(AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].frequency.value)
      break
    case 'LFODepth':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].depth.value = payload.value
      // console.log(AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].depth.value = payload.value)
      break
    case 'LFOOctaves':
      AM.scenes[payload.sceneTitle].autoFilters[payload.trackNumber].octaves = payload.value
      break
  }
}
