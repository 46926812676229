import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Toneflow from "@/views/Toneflow.vue"
import Flows from "@/views/Flows.vue"
import Users from "@/views/Users.vue"
import Profile from "@/views/Profile.vue"
import Signup from "@/views/Signup.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: "/:id",
    name: "Toneflow",
    component: Toneflow,
    props: true,
  },
  // {
  //   path: "/:id",
  //   name: "Toneflow_Id", // this needs to be different from "Toneflow", otherwise above is apparently overwritten
  //   component: Toneflow,
  //   props: true,
  // },
  {
    path: "/flows",
    name: "Flows",
    component: Flows,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/users/:id",
    name: "Profile",
    component: Profile,
    props: true,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
