
import { defineComponent } from "vue";
import SaveModal from "@/components/SaveModal.vue"

export default defineComponent({
  name: "MainNav",
  props: ['registerCombos', 'unregisterCombos'],
  components: {
    SaveModal,
  },
  data() {
    return {
      showLoginFields: false,
      loginHandle: '',
      password: '',
      isSaveModalOpen: false,
    }
  },
  methods: {
    handleLogin() {
      // make request to database to save user
      if (!this.showLoginFields) {
        this.showLoginFields = true
      } else {

        const userData = {
          loginHandle: this.loginHandle,
          password: this.password,
        }

        this.$store.dispatch('login', userData)
      }
    },
    handleLogout() {
      this.$store.dispatch('logout')
    },
    toggleSaveModal(){
      this.isSaveModalOpen = !this.isSaveModalOpen
    },
    focusFunction(){
      this.$store.commit('changePreviousRegion', this.$store.state.flow.activeRegion)
      // NOTE careful with bypass-dispatch here, check for edge cases where it doesn't get set back
      this.$store.commit('changeActiveRegion', 'bypass-dispatch')
      this.unregisterCombos()
    },
    enterFunction(event){
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.flow.previousRegion)
      this.registerCombos()
      // bus.$emit('clearKeyFromDown', 'Enter')
    },
  },
  computed: {
    userID() {
      return this.$store.state.auth.user._id
    },
    activeFlowId() {
      // console.log('activeFlowId', this.$store.state.flow._id)
      return this.$store.state.flow._id
    }
  },

  mounted() {
    // console.log('mounted (in MainNav)')
  },
  updated() {
    // console.log('updated (in MainNav)')
  }
});
