.<template>
  <div class="scene-dashboard" ref="sceneDashboard">
    <div class="scene-dashboard__left">
      <SceneInfo
        :registerCombos="this.registerCombos"
        :unregisterCombos="this.unregisterCombos"
      />
    </div>
    <div class="scene-dashboard__middle">
      <PianoSelector />
    </div>
    <div class="scene-dashboard__right">
      <ModulationControls />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SceneInfo from "@/components/SceneInfo"
import PianoSelector from "@/components/PianoSelector.vue"
import ModulationControls from "@/components/ModulationControls"

export default defineComponent({
  name: "SceneDashboard",
  props: ['registerCombos', 'unregisterCombos'],
  components: {
    SceneInfo,
    PianoSelector,
    ModulationControls,
  },
  mounted() {
    this.$refs.sceneDashboard.addEventListener('scroll', () => {
      this.$store.dispatch('setPianoSelectorRect')
    })
    window.addEventListener('resize', () => {
      this.$store.dispatch('setPianoSelectorRect')
    })
  },
})
</script>

<style lang="scss">
@import "../assets/variables";

.scene-dashboard {
  //display: flex;
  //justify-content: flex-start;
  //flex-wrap: wrap;
  //align-items: center;
  margin-bottom: 10px;

  //background: #abc;
  overflow-x: auto;
  // overflow-y: visible; 
  //  this causes scroll seemingly in excess of 120px
  //  not sure why it was set to "visible" anyway...?
  overflow-y: hidden;
  white-space: nowrap;
  text-align: left;
  height: 120px;

  &__left {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    height: 100%;
  }
  &__middle {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    height: 100%;
  }
  &__right {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 480px);
    min-width: 600px;
    margin-left: 5px;
    height: 100%;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: black;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

</style>
