// import { AudioManager as AM } from "@/audio/AudioManager"
// import * as helpers from "@/utils/helpers"
import * as Tone from "tone"

// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************

export const toggleChain = state => {
  state.flow.chain = !state.flow.chain
}

export const toggleChainLoop = state => {
  state.flow.chainLoop = !state.flow.chainLoop
}


export const mDeleteScene = (state, indexOfSceneToDelete) => {
  state.flow.scenes.splice(indexOfSceneToDelete, 1)
}

export const dragScene = (state, scenes) => { // from play-n-tabs
  let editingSceneId = state.flow.editingSceneId
  state.flow.scenes = scenes
  state.flow.scenes.forEach( (scene, index) => {
    if (scene.id === editingSceneId) { state.flow.editingSceneNumber = index }
  })
}

//  Engine?
export const setSceneChangeNumber = (state, change) => {
  state.flow.sceneChangeNumber = change
}

export const mChangeScene = (state) => {
  // console.log("m changing scene to:", state.flow.sceneChangeNumber)
  state.flow.editingSceneNumber = state.flow.sceneChangeNumber
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  Tone.Transport.bpm.value = scene.bpm
}

export const mUpdateSceneTitle = (state, title) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.title = title
}

// Engine?
export const updateChainAdvancePer = (state, value) => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.chainAdvancePer = value
}

export const toggleResetRememberedOnSceneChange = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  scene.resetRememberedOnSceneChange = !scene.resetRememberedOnSceneChange
}

export const assignScenePlayerParamSetting = state => {
  let scene = state.flow.scenes[state.flow.editingSceneNumber]
  // console.log('assignScenePlayerParamSettings')

  if (scene.playerParams.assigned){
    const check = confirm("Update Player Params for this scene?")
    if (check === false){ return }
  }

  const currentPlayerParams = state.player.playerParams
  const settings = JSON.parse(JSON.stringify(currentPlayerParams)) // extract state.playerParams to constants
  // console.log('settings', settings)
  scene.playerParams = settings
}


// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

export const setUpSceneChange = (context, change) => {
  // console.log("setupScenechange state:", context.state)
  let changeToNumber = context.state.flow.sceneChangeNumber
  if (change === 'backward') {
    if (context.state.flow.editingSceneNumber > 0) {
      changeToNumber = context.state.flow.editingSceneNumber - 1
    } else if (context.state.flow.editingSceneNumber === 0) {
      changeToNumber = context.state.flow.scenes.length-1
    }
  } else if (change === 'forward') {
    if (context.state.flow.editingSceneNumber < context.state.flow.scenes.length-1 ) {
      changeToNumber = context.state.flow.editingSceneNumber + 1
    } else {
      changeToNumber = 0
    }
  } else {
    changeToNumber = change
  }
  if (context.state.general.playing) {
    context.commit('setSceneChangeNumber', changeToNumber)
    if (!context.state.flow.chain) {
      context.commit('setSceneAdvanceCued', true)
    }
  } else {
    context.commit('setSceneChangeNumber', changeToNumber)
    context.commit('mChangeScene')
  }

  // const scene = context.state.flow.scenes[context.state.flow.editingSceneNumber]
  if (context.state.player.playerParamCurrent === 'scene') {
    context.dispatch('activatePlayerParamSetting', 'scene')
  }
}


export const deleteScene = (context, sceneId) => {
  let indexOfSceneToDelete
  context.state.flow.scenes.forEach( (scene, index) => {
    if (scene.id === sceneId) {
      indexOfSceneToDelete = index
    }
  })
  if (indexOfSceneToDelete !== context.state.flow.editingSceneNumber) {
    context.commit('mDeleteScene', indexOfSceneToDelete)
  }

  context.dispatch('initializeAllSceneAudio')
}

export const updateSceneTitle = (context, title) => {
  context.commit('mUpdateSceneTitle', title)
  context.dispatch('initializeSceneAudio', context.state.flow.editingSceneNumber)
}
