<template>
  <div>
    <Tutorial v-if="this.$store.state.generalPersist.showTutorial" />
    <div class="main-nav-container">
      <MainNav
        v-if="this.$store.state.useServer"
        :registerCombos="registerCombos"
        :unregisterCombos="unregisterCombos"
      />
      <MainNavLocal
        v-else
        :registerCombos="registerCombos"
        :unregisterCombos="unregisterCombos"
      />
    </div>
    <div class="page-content-container">
      <!-- key used to remount component (eg: Profile) when id path changes -->
      <router-view
        :key="$route.fullPath"
        :registerCombos="registerCombos"
        :unregisterCombos="unregisterCombos"
      ></router-view>
    </div>
  </div>
</template>

<!--<script lang="ts">-->
<script>
import { defineComponent } from "vue";
import Tutorial from "@/components/Tutorial"
import MainNav from "@/components/MainNav.vue"
import MainNavLocal from "@/components/MainNavLocal.vue"
import {keypress as keypressListeners} from "@/keypress/dispatchTables"
import { keypressCombos } from "@/keypress/keypress_combos"
import { randomElement } from "@/utils/helpers";
import * as CONSTANTS from "@/constants/constants";

export default defineComponent({
  name: "App",
  components: {
    Tutorial,
    MainNav,
    MainNavLocal,
  },
  data() {
    return {
      keypressListener: null,
      modifierCombos: null,
    }
  },
  methods: {
    registerCombos() {
      console.log('registerCombos');
      const kc = keypressCombos.bind(this)()
      this.modifierCombos = this.keypressListener.register_many(kc)
    },
    unregisterCombos() {
      console.log('unRegisterCombos:', this.modifierCombos);
      // this catches a bug, but why is unregisterCombos called again to begin with?
      if (this.modifierCombos != null) {
        this.keypressListener.unregister_many(this.modifierCombos)
        this.modifierCombos = null
      }
    }
  },
  async created() {
    this.keypressListener = new window.keypress.Listener()
    this.registerCombos()
  },
  mounted() {
    function reportWindowSize(e) {
      console.log('resize', e);
    }
    // window.onresize = reportWindowSize;
  },
});
</script>

<style lang="scss">
@import "assets/variables";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  //background: $themeMed;
  //background: mediumseagreen;
  width: 100%;
  height: 100%;
  position: relative;

  // technically the (absolute) positioning could be in the components themselves, but I want to establish layout here
  .main-nav-container {
    position: absolute;
    height: 40px;
    top: 0;
    left: 0;
    right: 0;
  }

  .page-content-container {
    position: absolute;
    top: 45px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

</style>
