<template>
  <div class="toneflow">
<!--    <Tutorial />-->
    <RegionHighlighting />
<!--    <button @click="playTest">Play Test</button>-->
    <div
        class="toneflow__container"
        :class="{
          playerUIClosed: !this.$store.state.general.playerUIOpen
        }"
    >
      <div class="toneflow__controls">
        <PlayNTabs />
        <SceneDashboard
          :registerCombos="this.registerCombos"
          :unregisterCombos="this.unregisterCombos"
        />
        <ModeOptions v-if="false" />
      </div>
      <div class="toneflow__tracks">
        <Tracks />
      </div>
    </div>
    
    <div
      v-if="this.$store.state.general.playerUIOpen"
      class="player-ui-container--open"
    >
      <PlayerUI :down="down" />
    </div>
    <div
      v-else
      class="player-ui-container--closed"
    >
      <div class="open-player-ui" @click="openPlayerUI" >+</div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as HELPERS from "@/utils/helpers"
import * as CONSTANTS from "@/constants/constants"
import * as Tone from 'tone'
import { AudioManager as AM } from "@/audio/AudioManager"
import {generalKeyDispatchTable as generalKeyDispatchTable} from "@/keypress/dispatchTables"
import {tuneEntryDispatchTable as tuneEntryDispatchTable} from "@/keypress/dispatchTables"
import {keypress as keypressListeners} from "@/keypress/dispatchTables"

import PlayNTabs from "@/components/PlayNTabs"
import SceneDashboard from "@/components/SceneDashboard.vue"
import ModeOptions from "@/components/ModeOptions.vue"
import Tracks from "@/components/Tracks.vue"
import PlayerUI from "@/components/PlayerUI"
import RegionHighlighting from "@/components/RegionHighlighting"
import Tutorial from "@/components/Tutorial"
// import Qwerty from "@/components/Qwerty.vue"
import audio from '../audio/audio.js'

// import gtr from "@/audio/gtrSwell_C3.mp3" // works
// import gtr from "./gtrSwell_C3.mp3" // works
import emitter from '@/utils/emitter.js'
import router from "@/router";


export default defineComponent({
  name: "Toneflow",
  components: {
    PlayNTabs,
    SceneDashboard,
    Tracks,
    ModeOptions,
    PlayerUI,
    RegionHighlighting,
    Tutorial,
  },
  props: ['id', 'registerCombos', 'unregisterCombos'],
  data() {
    return {
      // TODO remove, these are on AM now
      // playerInstrument: null,
      // playerGain: new Tone.Gain(),
      // playerDelay: new Tone.FeedbackDelay(0, 0),
      // playerDistortion: new Tone.Distortion(),
      // playerAutoFilter: new Tone.AutoFilter("4n"),

      scheduleId: '',
      down: [],
    }
  },
  computed: {
    // PLAYING
    playing() {
      return this.$store.state.general.playing
    },
    scene(){
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    // bpm(){
    //   return this.scene.bpm
    // },
    tracks(){
      return this.scene.tracks
    },
    toneTunes(){
      return this.$store.getters.toneTunes
    },

    // Computed Player Params Basics
    storePlayerInstrumentType(){
      return this.$store.state.player.playerParams.instrumentType
    },
    storePlayerSampleType(){
      return this.$store.state.player.playerParams.sampleType
    },
    storePlayerWaveType(){
      return this.$store.state.player.playerParams.waveType
    },

    storePlayerGain(){
      return this.$store.state.player.playerParams.gain
    },
    storePlayerAttack(){
      return this.$store.state.player.playerParams.attack
    },
    storePlayerDecay(){
      return this.$store.state.player.playerParams.decay
    },
    storePlayerSustain(){
      return this.$store.state.player.playerParams.sustain
    },
    storePlayerRelease(){
      return this.$store.state.player.playerParams.release
    },
    storePlayerPortamento(){
      return this.$store.state.player.playerParams.portamento
    },
    storePlayerModulationType(){
      return this.$store.state.player.playerParams.modulationType
    },
    storePlayerHarmonicity(){
      return this.$store.state.player.playerParams.harmonicity
    },
    storePlayerModulationIndex(){
      return this.$store.state.player.playerParams.modulationIndex
    },
    storePlayerCount(){
      return this.$store.state.player.playerParams.count
    },
    storePlayerSpread(){
      return this.$store.state.player.playerParams.spread
    },
    storePlayerModulationFrequency(){
      return this.$store.state.player.playerParams.modulationFrequency
    },

    // Computed Player Params Effects
    storePlayerDelayTime(){
      return this.$store.state.player.playerParams.delayTime
    },
    storePlayerDelayFeedback(){
      return this.$store.state.player.playerParams.delayFeedback
    },
    storePlayerDistortion(){
      return this.$store.state.player.playerParams.distortion
    },
    storePlayerFilterWet(){
      return this.$store.state.player.playerParams.filterWet
    },
    storePlayerBaseFrequency(){
      return this.$store.state.player.playerParams.filterBaseFrequency
    },
    storePlayerFilterType(){
      return this.$store.state.player.playerParams.filterType
    },
    storePlayerFilterRolloff() {
      return this.$store.state.player.playerParams.filterRolloff
    },
    storePlayerFilterQ(){
      return this.$store.state.player.playerParams.filterQ
    },
    storePlayerLFOWaveType(){
      return this.$store.state.player.playerParams.LFOWaveType
    },
    storePlayerLFOFrequency(){
      return this.$store.state.player.playerParams.LFOFrequency
    },
    storePlayerLFODepth(){
      return this.$store.state.player.playerParams.LFODepth
    },
    storePlayerLFOOctaves(){
      return this.$store.state.player.playerParams.LFOOctaves
    },

    // EDITING
    // activeRegion(){ // maybe this should be specific to scene?
    //   return this.$store.state.activeRegion
    // },
    editingTrackNumber(){
      return this.scene.editingTrackNumber
    },
    editingTune(){
      return this.scene.tracks[this.editingTrackNumber].tune
    },
    editingTrackId(){ // currently this is only used to display info for debugging
      return this.scene.editingTrackId
    },
    editingIndex(){
      return this.scene.editingIndex
    },
    pitchSets(){
      return this.$store.getters.pitchSets
    },
  },

  watch: {
    $route (to, from) {
      // stop play when leaving page
      // is destroy hook called? if so wipe audioModule so it's not rebuilt on return
      // console.log('to', to)
      // console.log('from', from)
    },
    playing: function(playing){
      if(playing) { this.play() }
      else { this.stop() }
    },
    // Watch Player Params Basic
    storePlayerInstrumentType: function(storePlayerInstrumentType){
      this.updatePlayerStuff()
    },
    storePlayerSampleType: function(storePlayerSampleType){
      this.updatePlayerStuff()
    },
    storePlayerWaveType: function(storePlayerWaveType){
      // console.log('watch storePlayerWaveType', storePlayerWaveType)
      AM.playerInstrument.set({ "oscillator": { "type": storePlayerWaveType } })
    },
    storePlayerGain: function(storePlayerGain){ // doesn't work with arrow function! need to think about that.
      AM.playerGain.gain.value = storePlayerGain
    },
    storePlayerAttack: function(storePlayerAttack){
      // console.log('check attack', AM.playerInstrument)
      if (this.storePlayerInstrumentType === 'polySynth' || this.storePlayerInstrumentType === 'monoSynth'){
        AM.playerInstrument.set({ 'envelope': { attack: storePlayerAttack } })
      } else if (this.$store.state.playerParams.instrumentType === 'sampler'){
        AM.playerInstrument.set({ 'attack': storePlayerAttack })
        // console.log('check attack2', AM.playerInstrument)
      }
    },
    storePlayerDecay: function(storePlayerDecay){
      if (this.storePlayerInstrumentType === 'polySynth'){
        AM.playerInstrument.set({ 'envelope': { decay: storePlayerDecay } })
      }
    },
    storePlayerSustain: function(storePlayerSustain){
      if (this.storePlayerInstrumentType === 'polySynth'){
        AM.playerInstrument.set({ 'envelope': { sustain: storePlayerSustain } })
      }
    },
    storePlayerRelease: function(storePlayerRelease){
      if (this.storePlayerInstrumentType === 'polySynth' || this.storePlayerInstrumentType === 'monoSynth'){
        AM.playerInstrument.set({ 'envelope': { release: storePlayerRelease } })
      } else if (this.$store.state.playerParams.instrumentType === 'sampler'){
        AM.playerInstrument.set({ 'release': storePlayerRelease })
        //AM.playerInstrument.set({ 'envelope': { release: storePlayerRelease } })
        // console.log('player release', AM.playerInstrument.release)
      }
    },
    storePlayerPortamento: function(storePlayerPortamento){
      AM.playerInstrument.set({ 'portamento': storePlayerPortamento })
    },
    storePlayerHarmonicity: function(storePlayerHarmonicity){
      AM.playerInstrument.set({ 'oscillator': { 'harmonicity': storePlayerHarmonicity} })
    },
    storePlayerModulationType: function(storePlayerModulationType){
      // console.log('watch storePlayerModulationType', storePlayerModulationType)
      AM.playerInstrument.set({ 'oscillator': { 'modulationType' : storePlayerModulationType } })
    },
    storePlayerModulationIndex: function(storePlayerModulationIndex){
      AM.playerInstrument.set({ 'oscillator': { 'modulationIndex' : storePlayerModulationIndex } })
    },
    storePlayerSpread: function(storePlayerSpread){
      AM.playerInstrument.set({ 'oscillator': { 'spread' : storePlayerSpread } })
    },
    storePlayerCount: function(storePlayerCount){
      //console.log('osc', AM.playerInstrument.oscillator)
      //console.log('pre count?', AM.playerInstrument)
      AM.playerInstrument.set({ 'oscillator': { 'count' : storePlayerCount } })
    },
    storePlayerModulationFrequency: function(storePlayerModulationFrequency){
      AM.playerInstrument.set({ 'oscillator': { 'modulationFrequency' : storePlayerModulationFrequency } })
    },
    // Watch Player Params Effects
    storePlayerDelayTime: function(storePlayerDelayTime){
      AM.playerDelay.delayTime.value = storePlayerDelayTime
    },
    storePlayerDelayFeedback: function(storePlayerDelayFeedback){
      AM.playerDelay.feedback.value = storePlayerDelayFeedback
    },
    storePlayerDistortion: function(storePlayerDistortion){
      AM.playerDistortion.distortion = storePlayerDistortion
    },
    storePlayerFilterWet: function(storePlayerFilterWet){
      // console.log('watch storePlayerFilterWet', storePlayerFilterWet)
      AM.playerAutoFilter.wet.value = storePlayerFilterWet
      // console.log('AM.playerAutoFilter', AM.playerAutoFilter)
    },
    storePlayerFilterType: function(storePlayerFilterType) {
      AM.playerAutoFilter.filter.type = storePlayerFilterType
    },
    storePlayerFilterRolloff: function(storePlayerFilterRolloff) {
      AM.playerAutoFilter.filter.rolloff = storePlayerFilterRolloff
    },
    storePlayerBaseFrequency: function(storePlayerBaseFrequency){
      AM.playerAutoFilter.baseFrequency = storePlayerBaseFrequency
    },
    storePlayerFilterQ: function(storePlayerFilterQ){
      AM.playerAutoFilter.filter.Q.value = storePlayerFilterQ
    },
    storePlayerLFOWaveType: function(storePlayerLFOWaveType) {
      AM.playerAutoFilter.type = storePlayerLFOWaveType
    },
    storePlayerLFOFrequency: function(storePlayerLFOFrequency){
      AM.playerAutoFilter.frequency.value = storePlayerLFOFrequency
    },
    storePlayerLFODepth: function(storePlayerLFODepth){
      AM.playerAutoFilter.depth.value = storePlayerLFODepth
    },
    storePlayerLFOOctaves: function(storePlayerLFOOctaves){
      // console.log('watchStorePlayerLFOOctaves', storePlayerLFOOctaves)
      AM.playerAutoFilter.octaves = storePlayerLFOOctaves
    },
  },

  methods: {
    openPlayerUI() {
      this.$store.commit('togglePlayerUIOpen')
    },
    togglePlay(){
      // console.log('togglePlay');
      // Tone.start() // needed?
      this.$store.commit('togglePlay')
    },
    // async playTest() {
    //   console.log('playTest')
    //   const sampler = new Tone.Sampler({
    //     urls: {
    //       "C3": gtr, // works
    //       "C3": "./gtrSwell_C3.mp3" // doesn't work. why?
    //     },
    //     onload: () => {
    //       console.log('playTest gtrSwell loaded');
    //     },
    //   }).toDestination()
    //   Tone.loaded().then(() => {
    //     sampler.triggerAttackRelease("C3", 4)
    //   });
    // },
    async play() {
      Tone.loaded().then(async () => {
        await Tone.start()
        Tone.Transport.start()
      })
    },
    stop(){
      Tone.Transport.stop()
    },

    // delete me:
    async playNote(time){
      audio.synths[0].triggerAttackRelease('C4', '8n', time)
    },

    checkForTrackChanges(track, index){
      // console.log("cftc this.scene:", this.scene)
      // console.log(index, "ttI", track.toneTuneIndex)
      if (track.toneTuneIndex >= track.tune.length) {
        console.info('out of bounds')
        this.$store.commit('resetScene')
      }
      if (this.scene.started === false) { this.$store.commit('startScene') }

      // IF LEAD TRACK, CHECK CHANGE AND MODULATION TRIGGERS
      if (track.id === this.scene.leadTrackId){
        // console.log('CFTC track.id === this.scene.leadTrackId leadTrack');
        if (track.toneTuneIndex === 0 && this.scene.modulationTriggered){
          // console.log('track.toneTuneIndex === 0 && this.scene.modulationTriggered');
          this.$store.dispatch('morphSelectedNotes')
          this.$store.commit('toggleModulationTriggered', false)
        }
        if (track.toneTuneIndex === 0 && track.changeTriggered){
          // console.log('CFTC if track.toneTuneIndex === 0 && track.changeTriggered');
          this.$store.dispatch('changeTune', { trackIndex: index, all: false } )
          this.$store.commit('toggleTrackChangeTriggered', { index: index, bool: false } )
        }
        if (this.$store.state.flow.sceneAdvanceTriggered){
          // console.log('CFTC if this.$store.state.flow.sceneAdvanceTriggered');
          this.$store.commit('resetScene')
          if (this.scene.resetRememberedOnSceneChange) {
            this.$store.dispatch('returnAllTunes')
          }
          if (this.$store.state.flow.editingSceneNumber >= this.$store.state.flow.scenes.length-1 &&
            this.$store.state.flow.chain && this.$store.state.flow.chainLoop === false) {
            this.togglePlay()
            // there is some reason these have to come after in this if-block, why?
            this.$store.commit('updateFormStep', 'zero')  // having this here feels rather cludgey. there must be a cleaner way to do this formStep & sceneAdvanceTriggered biz
            this.$store.dispatch('changeScene')
            this.$store.commit('setSceneAdvanceTriggered', false)
            return
          }
          this.$store.commit('updateFormStep', 'zero')  // having this here feels rather cludgey. there must be a cleaner way to do this formStep & sceneAdvanceTriggered biz
          // console.log(">changing scene")
          this.$store.dispatch('changeScene')
          this.$store.commit('setSceneAdvanceTriggered', false)
        }
        if (this.$store.state.flow.chain && this.$store.state.flow.sceneChangeNumber === this.$store.state.flow.editingSceneNumber) {
          // console.log("this.$store.state.flow.chain && this.$store.state.flow.sceneChangeNumber === this.$store.state.flow.editingSceneNumber")
          this.$store.dispatch('setUpSceneChange', 'forward')
        }
      } else {
        // CHANGE OTHER TRACKS
        if (track.toneTuneIndex === 0 && track.changeTriggered){
          this.$store.dispatch('changeTune', { trackIndex: index, all: false } )
          this.$store.commit('toggleTrackChangeTriggered', { index: index, bool: false } )
        }
      }
    },

    advanceAndPlayTrack(track, index, time){

      // ESTABLISH TONETUNE
      let toneTune = this.toneTunes[index] // 'pass-by-reference', more complicated than I thought. When this is above CHECK CHANGE ANS MODULATION TIGEERS, play function gets the old array after change... https://stackoverflow.com/questions/7744611/pass-variables-by-reference-in-javascript

      // PLAY NOTES
      let pitch = toneTune[track.toneTuneIndex]
      if (pitch != 0){
        if (track.toneTuneIndex === toneTune.length-1 && this.$store.state.flow.sceneAdvanceTriggered) {
          // Tone.ToneAudioBuffer.loaded().then(() => {
            AM.scenes[this.scene.title].instruments[index].triggerAttackRelease(pitch, '16n', time) // corrects for last note duration bleed-over on scene change
          // })
        } else {
          // const inst = AM.scenes[this.scene.title].instruments[index]
          // console.log('inst', inst);
          // console.log('inst.loaded', inst.loaded);
          // Tone.ToneAudioBuffer.loaded().then(() => {
            AM.scenes[this.scene.title].instruments[index].triggerAttackRelease(pitch, track.noteDuration, time)
          // })
        }
      }

      // ADVANCE TRACK STEP and TRIGGERS CASCADE
      if (track.toneTuneIndex < toneTune.length-1) {
        // console.log('>>>advance track step calling changeToneTuneIndex');
        this.$store.commit('changeToneTuneIndex', {change:'increment', index:index} )

      } else {
        this.$store.commit('changeToneTuneIndex', {change:'zero', index:index} )
        this.$store.dispatch('checkChainIncrementAndTriggerAdvance', { track: track, increment: 'Lead Cycle' }  )
        this.$store.dispatch('checkAdvanceCueVsChangeIncrement', { track: track, increment: 'Lead Cycle', index: index } )

        if (track.changeCycles < track.changePer-1 && !(track.changePer === 0) && !this.scene.suspendChanges) {
          this.$store.commit('changeCycles', {change:'increment', index:index} )

        } else if (!(track.changePer === 0) && !this.scene.suspendChanges) {
          this.$store.commit('changeCycles', { change:'zero', index: index} )
          this.$store.commit('toggleTrackChangeTriggered', { index: index, bool: true })
          this.$store.dispatch('checkChainIncrementAndTriggerAdvance', { track: track, increment: 'Lead Change' }  )
          this.$store.dispatch('checkAdvanceCueVsChangeIncrement', { track: track, increment: 'Lead Change', index: index } )

          // LEAD TRACK QUEUES MODULATION
          if (track.id === this.scene.leadTrackId && this.scene.autoModulate) {
            if (this.scene.modulationCycles < this.scene.modulatePerLeadChanges-1){
              this.$store.commit('updateModulationCycles', 'increment')

            } else {
              this.$store.commit('updateModulationCycles', 'zero')
              this.$store.commit('toggleModulationTriggered', true)
              this.$store.dispatch('checkChainIncrementAndTriggerAdvance', { track: track, increment: 'Modulation' }  )
              this.$store.dispatch('checkAdvanceCueVsChangeIncrement', { track: track, increment: 'Modulation', index: index } )
            }
          }
        }
      }
    },


    /** KEY ENTRY *****************************************************************************************************
     *
     *******************************************************************************************************************/

    onkeydown(e) {
      // console.log('onkeydown e.key1', e.key);
      if(e.key === ' ' && !this.scene.editingForm) {
        e.preventDefault()
        //return  /* return prevents spacebar from playing previous pitch (gives error, but can be used musically) */
      }

      if(this.down.indexOf(e.key) === -1) { 	// this.down.indexOf(e.key) === -1 ? this.down.push(e.key) : return;  // can't use return in this way
        this.down.push(e.key);
        //console.log("entering down:", e.key)
        // console.log('Toneflow down', this.down);
        //joined', this.down.join('-'))

        if (this.$store.state.flow.activeRegion ==='' && e.key==='Enter') { this.down = [] } // this prevents 'Enter' from getting stuck in down when a select is focussed. It works but feeling a bit suspicious. (Or perhaps more conditions are needed here?)

        // console.log('this.$store.state.flow.activeRegion', this.$store.state.flow.activeRegion );
        // Experimental bypass to allow title entry in SaveModal. Also needed in onkeyup?
        if (this.$store.state.flow.activeRegion === 'bypass-dispatch') return
        // console.log('passed bypass-dispatch');
        // make sure you change the region back after assigning this, otherwise user can't change region themselves

        for (var k in generalKeyDispatchTable) {
          // console.log('k', k);
          if (generalKeyDispatchTable.hasOwnProperty(k) && this.down.indexOf(k) > -1) {
            generalKeyDispatchTable[k].bind(this)() // use call?
          }
        }

        switch (this.$store.state.flow.activeRegion) {
          case "tune-entry":
            // console.log('e.key2', e.key);

            if (e.key === 'Backspace') {
              if (this.editingIndex === this.editingTune.length-1 ) {
                this.$store.dispatch('deleteNote', 'fromEndcap')
              } else {
                this.$store.dispatch('deleteNote', 'currentNote')
              }
              break
            }

            if (e.key === '\\') {
              if (this.editingIndex === this.editingTune.length-1) {
                this.$store.dispatch('noteEntry', { change:'fromEndcap', pitch:' ' })
              } else {
                this.$store.dispatch('noteEntry', { change:'currentNote', pitch:' ' })
              }
              break
            }

            for (var k in tuneEntryDispatchTable) {
              // https://www.reddit.com/r/vuejs/comments/8j84cv/vuejs_and_keyboard_input_data_structure/?st=jh9vg13f&sh=4140f0ad // https://jsperf.com/hasownproperty-overkill/1
              if (tuneEntryDispatchTable.hasOwnProperty(k) && this.down.indexOf(k) > -1) {
                tuneEntryDispatchTable[k].bind(this)() // use call?
                break
              }
            }
            // Note Entry
            if ( (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Octave" && CONSTANTS.noteKeys_RowsOctave.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Fifth" && CONSTANTS.noteKeys_RowsFifth.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Clusters" && CONSTANTS.noteKeys_Clusters.indexOf(e.key) > -1)
            ){
              // console.log('e.key3', e.key);
              let pitch = this.$store.getters.qwertyVals[e.key]
              // console.log('pitch', pitch);
              if (this.$store.state.flow.entrySound) { this.playEntryPitch(pitch) }
              if (this.editingIndex === this.editingTune.length-1) {
                this.$store.dispatch('noteEntry', { change:'fromEndcap', pitch:pitch })
              } else {
                this.$store.dispatch('noteEntry', { change:'currentNote', pitch:pitch })
              }
            }
            break;

          case "qwerty-player":
            if ( (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Octave" && CONSTANTS.noteKeys_RowsOctave.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Fifth" && CONSTANTS.noteKeys_RowsFifth.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Clusters" && CONSTANTS.noteKeys_Clusters.indexOf(e.key) > -1)
            ) {
              AM.playerInstrument.triggerAttack(this.$store.getters.qwertyVals[e.key], Tone.context.currentTime)
            }
            if (this.down.indexOf('ArrowUp') > -1) {
              AM.playerInstrument.set('detune', 100)
            }
            if (this.down.indexOf('ArrowDown') > -1) {
              AM.playerInstrument.set('detune', -100)
            }
            if (this.down.indexOf('ArrowLeft') > -1) {
              AM.playerInstrument.set('detune', -200)
            }
            if (this.down.indexOf('ArrowRight') > -1) {
              AM.playerInstrument.set('detune', 200)
            }
            break

          case "piano-selector":
            // console.log("qwertyVals", this.$store.getters.qwertyVals)
            if ( (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Octave" && CONSTANTS.noteKeys_RowsOctave.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Rows-Fifth" && CONSTANTS.noteKeys_RowsFifth.indexOf(e.key) > -1) ||
              (this.$store.state.player.playerParams.keyToQwertyDisplay === "Clusters" && CONSTANTS.noteKeys_Clusters.indexOf(e.key) > -1)
            ){
              let note = this.$store.getters.qwertyVals[e.key].slice(0,-1)
              this.$store.commit('updateSelectedNotes', note)
            }
            break

          default:
            console.log('activeRegion (default)', this.$store.state.flow.activeRegion)
            break
        }
      }
    },

    onkeyup(e){
      if(this.down.indexOf(e.key) > -1) {
        let newdown = HELPERS.remove(this.down, e.key)
        let capitals = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '{', '}', '|', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ':', '"', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '<', '>', '?']
        newdown.forEach( (item, index) => {
          if (capitals.indexOf(item) > -1) {newdown = HELPERS.remove(newdown, item)}
        })
        this.down = newdown
        if (this.$store.state.flow.activeRegion === "qwerty-player") {
          if (this.storePlayerInstrumentType === 'monoSynth'){
            // console.log('down.length', this.down.length)
            if (this.down.length === 0) { AM.playerInstrument.triggerRelease() }
          } else {
            AM.playerInstrument.triggerRelease(this.$store.getters.qwertyVals[e.key])
          }
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            AM.playerInstrument.set('detune', 0)
          }
        }
      }

    },

    // clearKeyFromDown(key){
    //   console.log('clearing key... ')
    //   if (this.down.indexOf(key) > -1) { this.down.splice(this.down.indexOf(key), 1) }
    // },

    playEntryPitch(pitch){
      let sceneTitle = this.scene.title //console.log(AM.scenes[sceneTitle])
      if (pitch != ' ') {
        AM.scenes[sceneTitle].instruments[this.editingTrackNumber].triggerAttackRelease(pitch, '8n' )
      }
    },

    toggleEntrySound(){
      console.log('toggleEntrySound')
      this.$store.commit('toggleEntrySound')
    },


    /*************************************************************************************************
     * INITIALIZATION HELPER
     *************************************************************************************************/

    updatePlayerStuff(){

      // console.log('updateStuff AM.playerInstrument', AM.playerInstrument)

      // clear out any prior connections
      if (AM.playerInstrument != null){
        AM.playerInstrument.disconnect()
        //AM.playerInstrument.dispose() // apparently it still works ok without this, just overwriting it I guess...?
        // from storePlayerInstrumentType watcher callback: cannot read property 'dispose' of undefined...!?
        // also getting the same error from changePlayerWaveType but with no ill effect
      }
      AM.playerGain.disconnect()
      AM.playerAutoFilter.disconnect()
      AM.playerDistortion.disconnect()
      AM.playerDelay.disconnect()


      // assign instrument & envelope
      if (this.storePlayerInstrumentType === 'polySynth'){
        AM.playerInstrument = AM.playerPolySynth()
        AM.playerInstrument.set({
          "oscillator": { "type": this.$store.state.player.playerParams.waveType }
        })
        AM.playerInstrument.set({ 'envelope': { attack: this.$store.state.player.playerParams.attack } })
        AM.playerInstrument.set({ 'envelope': { decay: this.$store.state.player.playerParams.decay } })
        AM.playerInstrument.set({ 'envelope': { sustain: this.$store.state.player.playerParams.sustain } })
        AM.playerInstrument.set({ 'envelope': { release: this.$store.state.player.playerParams.release } })
      }
      else if (this.storePlayerInstrumentType === 'monoSynth'){
        AM.playerInstrument = AM.playerMonoSynth()
        AM.playerInstrument.set({
          "oscillator": { "type": this.$store.state.player.playerParams.waveType }
        })
        AM.playerInstrument.set({ 'envelope': { attack: this.$store.state.player.playerParams.attack } })
        AM.playerInstrument.set({ 'envelope': { decay: this.$store.state.player.playerParams.decay } })
        AM.playerInstrument.set({ 'envelope': { sustain: this.$store.state.player.playerParams.sustain } })
        AM.playerInstrument.set({ 'envelope': { release: this.$store.state.player.playerParams.release } })
        // could access store directly, or use computed for above:
        AM.playerInstrument.set({ 'portamento': this.storePlayerPortamento })
      } else {
        switch (this.storePlayerSampleType){
            //case 'piano': AM.playerInstrument = AM.playerPianoSampler(); break
            //case 'gtrSwell': AM.playerInstrument = AM.playerGtrSwellSampler(); break
            //case 'gtrMute': AM.playerInstrument = AM.playerGtrMuteSampler(); break
            //case 'marimba': AM.playerInstrument = AM.playerMarimbaSampler(); break
            //case 'strings': AM.playerInstrument = AM.playerStringsSampler(); break
          case 'gtrSwell': AM.playerInstrument = AM.instrument('sampler', 'gtrSwell'); break
          case 'gtrMute': AM.playerInstrument = AM.instrument('sampler', 'gtrMute'); break
          case 'bassGtr': AM.playerInstrument = AM.instrument('sampler', 'bassGtr'); break
          case 'piano': AM.playerInstrument = AM.instrument('sampler', 'piano'); break
          case 'digiHarp': AM.playerInstrument = AM.instrument('sampler', 'digiHarp'); break
          case 'elecPno1': AM.playerInstrument = AM.instrument('sampler', 'elecPno1'); break
          case 'elecPno2': AM.playerInstrument = AM.instrument('sampler', 'elecPno2'); break
          case 'elecPno3': AM.playerInstrument = AM.instrument('sampler', 'elecPno3'); break
          case 'marimba': AM.playerInstrument = AM.instrument('sampler', 'marimba'); break
          case 'strings': AM.playerInstrument = AM.instrument('sampler', 'strings'); break
        }
        AM.playerInstrument.set({ 'attack': this.$store.state.player.playerParams.attack })
        AM.playerInstrument.set({ 'release': this.$store.state.player.playerParams.release })
      }

      if (this.$store.state.player.playerParams.instrumentType === 'monoSynth'
      || this.$store.state.player.playerParams.instrumentType === 'polySynth'){
        switch(this.$store.state.player.playerParams.waveType.substring(0, 1)) {
          case 'am':
            AM.playerInstrument.set({ 'oscillator': { 'modulationType' : this.storePlayerModulationType } })
            AM.playerInstrument.set({ 'oscillator': { 'harmonicity': this.storePlayerHarmonicity} })
            break
          case 'fm':
            AM.playerInstrument.set({ 'oscillator': { 'modulationType' : this.storePlayerModulationType } })
            AM.playerInstrument.set({ 'oscillator': { 'harmonicity': this.storePlayerHarmonicity} })
            AM.playerInstrument.set({ 'oscillator': { 'modulationIndex' : this.storePlayerModulationIndex } })
            break
          case 'fa':
            AM.playerInstrument.set({ 'oscillator': { 'count' : this.storePlayerCount } })
            AM.playerInstrument.set({ 'oscillator': { 'spread' : this.storePlayerSpread } })
            break
          case 'pw':
            AM.playerInstrument.set({ 'oscillator': { 'modulationFrequency' : this.storePlayerModulationFrequency } })
            break
        }
      }

      // set up other stuff
      let gainValue = ( () => {
        switch (this.$store.state.player.playerParams.instrumentType){
          case 'polySynth': return this.$store.state.player.playerParams.polySynthGainDefault
          case 'monoSynth': return this.$store.state.player.playerParams.monoSynthGainDefault
          case 'sampler': return this.$store.state.player.playerParams.samplerGainDefault
        }
      })()
      // console.log("gainValue", gainValue)
      this.$store.commit('updatePlayerParam', { param:'gain', value:gainValue })
      //AM.playerGain.gain.value = this.$store.state.playerParams.gain
      AM.playerDistortion.distortion = this.$store.state.player.playerParams.distortion
      // console.log('wet dist', AM.playerDistortion.wet.value)
      AM.playerDistortion.wet.value = 0.5
      AM.playerDelay.wet.value = 0.5
      AM.playerDelay.delayTime.value = this.$store.state.player.playerParams.delayTime
      AM.playerDelay.feedback.value = this.$store.state.player.playerParams.delayFeedback

      // more arbitraty direct $store access vs using computed:
      AM.playerAutoFilter.wet.value = this.$store.state.player.playerParams.filterWet
      AM.playerAutoFilter.filter.type = this.storePlayerFilterType
      AM.playerAutoFilter.filter.rolloff = this.storePlayerFilterRolloff
      AM.playerAutoFilter.baseFrequency = this.storePlayerBaseFrequency
      AM.playerAutoFilter.filter.Q.value = this.$store.state.player.playerParams.filterQ
      AM.playerAutoFilter.frequency.value = this.$store.state.player.playerParams.LFOFrequency
      AM.playerAutoFilter.depth.value = this.$store.state.player.playerParams.LFODepth
      AM.playerAutoFilter.type = this.storePlayerLFOWaveType
      AM.playerAutoFilter.octaves = this.storePlayerLFOOctaves
      AM.playerAutoFilter.start()

      AM.playerInstrument.connect(AM.playerGain)
      AM.playerGain.connect(AM.playerAutoFilter)
      AM.playerAutoFilter.connect(AM.playerDistortion)
      AM.playerDistortion.fan(Tone.Destination, AM.playerDelay)
      if (this.$store.state.player.playerParams.delayActive) {
        AM.playerDelay.toDestination()
      }
    },

    deactivateDelay(){
      AM.playerDelay.disconnect()
    },
    reactivateDelay(){
      AM.playerDelay.connect(Tone.Destination)
    },
  },

  async created() {
    // console.log('Toneflow created');
    if (this.$store.state.flow.activeRegion === 'bypass-dispatch' || this.$store.state.flow.activeRegion === '') {
      console.log('Toneflow created active region check');
      this.$store.commit('changeActiveRegion', 'qwerty-player')
    } // this prevents 'Enter' from getting stuck in down when a select is focussed. It works but feeling a bit suspicious. (Or perhaps more conditions are needed here?)

    // CHECK TO SEE IF THE URL HAS AN ID. IF SO, LOAD THE FLOW FROM THE DB
    // console.log('created router', router);
    if (this.id) {
      // console.log(`id ${this.id} found, dispatch loadFlow`);

      if (this.$store.state.useServer) {
        await this.$store.dispatch('loadFlow', this.id)
      } else {
        await this.$store.dispatch('loadFlowLocal', this.id)
      }
    }

    // SCENE SETUP
    this.$store.dispatch('initializeApp')
    // this.$store.dispatch('initializeSceneAudio', this.$store.state.flow.editingSceneNumber)
    // check, are you already doing this in that action?

    // initialize PLAYER PARAM settings
    if (!this.$store.state.player.playerParamSettings[0].assigned){
      // const defaultSettings = JSON.parse(JSON.stringify(this.$store.state.player.playerParams)) // extract to constants
      this.$store.commit('assignPlayerParamSetting', 0)
    }

    // SET SCENE ID
    // this.$store.commit('updateEditingSceneId', this.scene.id)
      // this *should* be separate from setUpNewScene, right?

    // for testing...
    // audio.addSynth()

    // ENGINE SETUP
    this.scheduleId = Tone.Transport.scheduleRepeat(time => {
      // console.log('>>>Tick<<<');
      // this.playNote(time);
      this.tracks.forEach((track, index) => {
        this.checkForTrackChanges(track, index)
      })
      this.tracks.forEach((track, index) => {
        this.advanceAndPlayTrack(track, index, time)
      })
    }, '8n');

    // Tone.Transport.bpm.value = 60
    // console.log('TF scenes',  this.$store.state.flow.scenes)
    // console.log('editingSceneNumber', this.$store.state.flow.editingSceneNumber)
    Tone.Transport.bpm.value = this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber].bpm;

    // KEYBOARD ENTRY
    window.addEventListener('keydown', this.onkeydown) // document.onkeydown = this.onkeydown
    window.addEventListener('keyup', this.onkeyup) // document.onkeydown = this.onkeydown

    // TODO
    // REMOVE ENTER WHEN HTML SELECT IS USED
    // bus.$on('clearKeyFromDown', (key)=> {
    //   console.log("clearing key:", key)
    //   this.clearKeyFromDown(key)
    // })

    // PLAYER SYNTH
    this.updatePlayerStuff()
    // bus.$on('updatePlayerStuff', () => {
    emitter.on('updatePlayerStuff', () => {
      this.updatePlayerStuff()
    })
    // bus.$on('deactivateDelay', () => {
    emitter.on('deactivateDelay', () => {
      this.deactivateDelay()
    })
    // bus.$on('reactivateDelay', () => {
    emitter.on('reactivateDelay', () => {
      this.reactivateDelay()
    })

    // KEYPRESS LIBRARY
    keypressListeners.bind(this)()
  },

  // mounted() {
  //   // console.log('Toneflow mounted');
  // },

  beforeUnmount() {
    // console.log('beforeUnmount');
    Tone.Transport.clear(this.scheduleId) // ;console.log("beforeUnmount scheduleId...", this.scheduleId) // This clear prevents multiple scheduleRepeats from accumulating on Hot-Recompile. (Which causes multiple calls to advanceTrackStep )
    window.removeEventListener('keydown', this.onkeydown)
    window.removeEventListener('keyup', this.onkeyup)
  },
});
</script>

<style lang="scss">
@import "../assets/variables";

.toneflow {
  width: 100%;
  height: 100%;
  //background: purple;
  position: relative;
  overflow: hidden;

  .toneflow__container {
    position: absolute;
    top: 0;
    bottom: 200px;
    left: 0;
    right: 0;
    //background: violet;
    overflow: auto;

    &.playerUIClosed {
      bottom: 20px;
    }

    .toneflow__controls {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      //background: green;
      height: 180px;
    }

    .toneflow__tracks {
      position: absolute;
      top: 180px;
      bottom: 0;
      left: 0;
      right: 0;
      //background: chocolate;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: black;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .player-ui-container--open {
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //background: orange;
  }

  .player-ui-container--closed {
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //display: flex;
    //background: skyblue;

    .open-player-ui {
      background: grey;
      font-weight: bold;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 100%;
      //border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

</style>
