import emitter from '@/utils/emitter.js'

export const player = {
  namespaced: false,
  state: {
    //playerParamSettings: [{'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned':false}, {'assigned': false} ],
    playerParamSettings: [{}, {}, {},  {}, {}, {},  {}, {}, {}, {}],
    playerParamCurrent: 0,

    // extract to constants
    playerParams:  {
      // Basics
      assigned: true,
      instrumentType: 'polySynth',
      sampleType: 'piano',
      waveType: 'sawtooth',
      qwertyOctave: 3,
      keyToQwertyDisplay: 'Rows-Octave',
      polySynthGainDefault: 0.12,
      monoSynthGainDefault: 0.15,
      samplerGainDefault: 0.5,
      gain: 0.5,
      delayTime: 0,
      delayFeedback: 0,
      delayActive: true,
      distortion: 0,
      // ADSR
      attack: 0.005,
      decay: 0.1,
      sustain: 0.3,
      release: 3,
      portamento: 0,
      portamentoOnlyOnDouble: true,
      harmonicity: 1,
      modulationType: 'square',
      modulationIndex: 2,   // not related to scene form!
      count: 2,
      spread: 20,
      modulationFrequency: 0.4,
      // Filter
      filterWet: 0.5,
      filterType: 'lowpass',
      filterRolloff: -12,
      filterBaseFrequency: 200,
      filterQ: 0,
      LFOWaveType: 'sine',
      LFOFrequency: 0.5,
      LFODepth: 0.2,
      LFOOctaves: 2.6,
    },
  },

  getters: {},
  mutations: {
    // PLAYER UI
    changeQwertyOctave: (state, change) => {
      if (change ==="increment") { state.playerParams.qwertyOctave++ }
      else if (change ==="decrement") { state.playerParams.qwertyOctave-- }
    },
    changeQwertyDisplay: (state, value) => {
      state.playerParams.keyToQwertyDisplay = value
    },
    changePlayerInstrumentType: (state, instrumentType) => {
      state.playerParams.instrumentType = instrumentType
    },
    changePlayerWaveType: (state, waveType) => {
      state.playerParams.waveType = waveType
    },
    changePlayerSampleType: (state, sampleType) => {
      state.playerParams.sampleType = sampleType
    },
    toggleDelayActive: state => {
      if (state.playerParams.delayActive) {
        // bus.$emit('deactivateDelay')
        emitter.emit('deactivateDelay')
        state.playerParams.delayActive = false
      } else {
        // bus.$emit('reactivateDelay')
        emitter.emit('reactivateDelay')
        state.playerParams.delayActive = true
      }
    },

    updatePlayerParam: (state, payload) => {
      // console.log('>>>updatePlayerParam payload', payload)
      if (payload.value === '' || (typeof payload.value === 'string' && payload.value.slice(0,1) === '.') ) { return }
      switch(payload.param) {
        // Param Basics
        case 'gain':
          state.playerParams.gain = payload.value
          break
        case 'attack':
          state.playerParams.attack = payload.value
          break
        case 'decay':
          state.playerParams.decay = payload.value
          break
        case 'sustain':
          state.playerParams.sustain = payload.value
          break
        case 'release':
          state.playerParams.release = payload.value
          break
        case 'portamento':
          state.playerParams.portamento = payload.value
          break
        case 'harmonicity':
          state.playerParams.harmonicity = payload.value
          break
        case 'modulationType':
          state.playerParams.modulationType = payload.value
          break
        case 'modulationIndex':
          state.playerParams.modulationIndex = payload.value
          break
        case 'count':
          state.playerParams.count = payload.value
          break
        case 'spread':
          state.playerParams.spread = payload.value
          break
        case 'modulationFrequency':
          state.playerParams.modulationFrequency = payload.value
          break

        // Param Effects
        case 'delayTime':
          state.playerParams.delayTime = payload.value
          break
        case 'delayFeedback':
          state.playerParams.delayFeedback = payload.value
          break
        case 'distortion':
          state.playerParams.distortion = payload.value
          break
        case 'filterWet':
          state.playerParams.filterWet = payload.value
          break
        case 'filterType':
          state.playerParams.filterType = payload.value
          break
        case 'filterRolloff':
          let rolloff = parseInt(payload.value, 10)
          state.playerParams.filterRolloff = rolloff
          break
        case 'filterBaseFrequency':
          state.playerParams.filterBaseFrequency = payload.value
          break
        case 'filterQ':
          state.playerParams.filterQ = payload.value
          break
        case 'LFOWaveType':
          state.playerParams.LFOWaveType = payload.value
          break
        case 'LFOFrequency':
          state.playerParams.LFOFrequency = payload.value
          break
        case 'LFODepth':
          state.playerParams.LFODepth = payload.value
          break
        case 'LFOOctaves':
          state.playerParams.LFOOctaves = payload.value
          break
      }
    },

    assignPlayerParamSetting: (state, settingIndex) => {
      // console.log('assignPlayerParamSettings settingIndex:', settingIndex);
      if (state.playerParamSettings[settingIndex].assigned){
        const check = confirm("Update Settings #"+settingIndex+"?")
        if (check === false){ return }
      }
      const settings = JSON.parse(JSON.stringify(state.playerParams)) // extract state.playerParams to constants
      // Vue.set(state.playerParamSettings, settingIndex, settings)
      // ?
      state.playerParamSettings[settingIndex] = settings
    },
    setPlayerParamCurrent: (state, settingIndex ) => {
      state.playerParamCurrent = settingIndex
    },
    setPlayerParams: (state, settings ) => {
      state.playerParams = settings
    },
  },

  actions: {
    // TODO refactoring from mutation, search "commit('activate"
    activatePlayerParamSetting: (context, settingIndex) => {
      // console.log('activatePlayerParamSettings, settingIndex', settingIndex);
      const scene = context.rootState.flow.scenes[context.rootState.flow.editingSceneNumber]
      if (settingIndex === 'scene') {
        if (scene.playerParams.assigned) {
          const settings = {...scene.playerParams}
          context.commit('setPlayerParams', settings)
        }
      } else {
        if (settingIndex === 'default'){ settingIndex = 0 }

        if (context.state.playerParamSettings[settingIndex].assigned === true){
          const settings = JSON.parse(JSON.stringify(context.state.playerParamSettings[settingIndex]))
          context.commit('setPlayerParams', settings)
          // note that watchers will likely automatically respond to that, so $emit may be redundant...?
          //bus.$emit('updatePlayerStuff')
        }
      }
      context.commit('setPlayerParamCurrent', settingIndex)
    },

    toggleQwertyDisplay: context => {
      if (context.state.playerParams.keyToQwertyDisplay === 'Rows-Octave'){
        context.commit('changeQwertyDisplay', 'Rows-Fifth')
      } else if (context.state.playerParams.keyToQwertyDisplay === 'Rows-Fifth'){
        context.commit('changeQwertyDisplay', 'Clusters')
      } else if (context.state.playerParams.keyToQwertyDisplay === 'Clusters'){
        context.commit('changeQwertyDisplay', 'Rows-Octave')
      }
    }
  },

}

