<template>

  <div class="scene-info">

    <div class="tempo">
      <span>Tempo (BPM)</span>
      <input
          id="BPMInput" type="number" min="1"
          :value="getBPM"
          @change="changeTempo"
          @focus="focusFunction"
          @keyup.enter="enterFunction($event)"
      >
    </div>

    <div class="scene-advance-container">
      <div class="scene-advance">
        <div 
          class="scene-advance-meter"
          :title="showTitles ? 'Progress till next scene' : ''"
        >
          <div v-if="scene.started && (this.$store.state.flow.sceneAdvanceTriggered || this.$store.state.flow.sceneAdvanceCued || this.$store.state.flow.chain)"
               v-bind:style="{ width: sceneAdvanceProgress + '%' }">
            <span v-if="scene.sceneChangeIncrement==='Form' && scene.formStep===-1" class="loading-harmonic-form-message" >loading...</span>
          </div>
        </div>
        <div class="scene-advance-arrow">
          <img src="@/assets/images/icons8-arrow-thin-30.png" alt="play" />
        </div>
        <div 
          class="scene-advance-title"
          :title="showTitles ? 'Next scene' : ''"
          >
          <div v-if="this.$store.state.flow.sceneAdvanceTriggered || this.$store.state.flow.sceneAdvanceCued || this.$store.state.flow.chain" >{{ advanceSceneTitle }}</div>
          <!--        <div>-->
          <!--          {{ this.$store.state.flow.sceneAdvanceTriggered }}-->
          <!--          {{ this.$store.state.flow.sceneAdvanceCued }}-->
          <!--          {{ this.$store.state.flow.chain }}-->
          <!--        </div>-->
        </div>
      </div>
    </div>

    <div class="scene-controls">
      <div
        class="reset-scene"
        :class="{greyOut: scene.started === false}"
        v-on:click="resetScene"
        :title="showTitles ? 'Reset play positions to zero' : ''"
      >
        <img class="small" src="../assets/images/icons8-align-left-100.png" alt="Reset Scene" />
      </div>

      <div
        class="suspend-changes"
        v-if="this.scene.suspendChanges"
        @click="toggleSuspendChanges"
        :title="showTitles ? 'Resume changing of notes' : ''"
      >
        <img class="small" src="../assets/images/icons8-menu-thin-100.png" alt="Suspend Changes" />
      </div>
      <div
        class="suspend-changes"
        v-else @click="toggleSuspendChanges"
        :title="showTitles ? 'Suspend changing of notes' : ''"
      >
        <img class="small" src="../assets/images/icons8-sea-waves-100.png" alt="Suspend Changes" />
      </div>

      <div
        class="remember-tunes"
        @click="rememberAllTunes"
        title="Remember current notes"
        :title="showTitles ? 'Remember current notes' : ''"
      >
        <img class="small" src="../assets/images/icons8-copy-96.png" alt="Remember Tunes" />
      </div>

      <div
          class="return-tunes"
          @click="returnAllTunes"
          v-bind:class="{ greyOut: !tunesRemembered }"
          title="Return to remembered notes"
          :title="showTitles ? 'Return to remembered notes' : ''"
      >
        <img class="small" src="../assets/images/icons8-back-arrow-96.png" alt="Return Tunes" />
      </div>

      <div
        class="scene-settings"
        @click="toggleSceneSettingsModal"
        title="Toggle scene settings modal"
        :title="showTitles ? 'Toggle scene settings modal' : ''"
      >
        <img class="small" src="../assets/images/icons8-settings-96.png" alt="Return Tunes" />
      </div>
    </div>

    <SceneSettingsModal
      v-if="isSceneSettingModalOpen"
      :close="toggleSceneSettingsModal"
      :registerCombos="this.registerCombos"
      :unregisterCombos="this.unregisterCombos"
    />

  </div>


</template>


<script>

import SceneSettingsModal from "@/components/SceneSettingsModal"
import TrackControls from "@/components/TrackControls"
import NoteSelect from "@/components/NoteSelectModal"
import {VueDraggableNext} from "vue-draggable-next"
  /*https://forum.vuejs.org/t/how-to-listen-for-an-enter-on-an-element-ui-form/11631 */
  // import {bus} from '../main.js'


export default {

  props: ['registerCombos', 'unregisterCombos'],
  components: {
    SceneSettingsModal,
  },
  data:() => ({
    isSceneSettingModalOpen: false,
    stepsTowardSceneChange: 0,
  }),

  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    scene(){
      // console.log('this.$store.state.flow.scenes', this.$store.state.flow.scenes);
      // console.log('this.$store.state.flow.editingSceneNumber', this.$store.state.flow.editingSceneNumber);
      return this.$store.state.flow.scenes[this.$store.state.flow.editingSceneNumber]
    },
    toneTunes(){
      return this.$store.getters.toneTunes
    },
    leadTrackNumber(){
      let leadTrackNumber = ''
      this.scene.tracks.forEach( (track, index) => {
        if (track.id === this.scene.leadTrackId) { leadTrackNumber = index }
      })
      return leadTrackNumber
    },
    leadTrackStep(){
      // console.log('leadTrackStep scene', this.scene)
      // console.log('leadTrackStep scene.tracks', this.scene.tracks)
      return this.scene.tracks[this.leadTrackNumber].toneTuneIndex
      // there seemed to be a persist issue here like with TrackControls getMaxChangeable
      // this.scene was undefined on deleteScene. but not doing it now...
    },
    getBPM(){
      return this.scene.bpm
    },
    sceneChangeIncrement: {
      get(){
        return this.scene.sceneChangeIncrement
      },
      set(increment){
        this.$store.commit('setSceneChangeIncrementType', increment)
      },
    },
    chainAdvancePer: {
      get(){
        return this.scene.chainAdvancePer
      },
      set(value){
        // console.log(value)
        this.$store.commit('updateChainAdvancePer', value)
      },
    },
    sceneAdvanceProgress(){

      let leadTrack = this.scene.tracks[this.leadTrackNumber]
      let leadTrackTune = this.toneTunes[this.leadTrackNumber]

      switch (this.scene.sceneChangeIncrement){
          case 'Lead Cycle':
              if (this.$store.state.flow.chain) {
                let stepsNeeded = leadTrackTune.length * this.scene.chainAdvancePer
                let wellThoughtOutVariableName = leadTrackTune.length * this.scene.chainIncrement + leadTrack.toneTuneIndex
                let stepsSoFar = (wellThoughtOutVariableName === 0) ? stepsNeeded : wellThoughtOutVariableName
                let stepPercent = stepsSoFar/stepsNeeded * 100 // isNaN(stepsSoFar/stepsNeeded) ? 0 : (stepsSoFar/stepsNeeded) * 100
                return stepPercent
              } else {
                let stepsNeeded = leadTrackTune.length
                let stepsSoFar = (leadTrack.toneTuneIndex === 0) ? stepsNeeded : leadTrack.toneTuneIndex
                let stepPercent = stepsSoFar/stepsNeeded * 100 // isNaN(stepsSoFar/stepsNeeded) ? 0 : (stepsSoFar/stepsNeeded) * 100
                return stepPercent
              }
              break

          case 'Lead Change':
              if (this.$store.state.flow.chain) {
                let stepsNeeded = leadTrackTune.length * leadTrack.changePer * this.scene.chainAdvancePer
                let veryWellThoughtOutVariableName = (leadTrackTune.length * leadTrack.changePer * this.scene.chainIncrement)
                                                  + (leadTrackTune.length * leadTrack.changeCycles)
                                                  + leadTrack.toneTuneIndex
                let stepsSoFar = (veryWellThoughtOutVariableName === 0) ? stepsNeeded : veryWellThoughtOutVariableName
                let stepPercent = stepsSoFar/stepsNeeded * 100 // isNaN(stepsSoFar/stepsNeeded) ? 0 : (stepsSoFar/stepsNeeded) * 100
                // console.log("stpPercent", stepPercent)
                return stepPercent
              } else {
                let stepsNeeded = leadTrackTune.length * leadTrack.changePer
                let veryWellThoughtOutVariableName2 = leadTrackTune.length * leadTrack.changeCycles + leadTrack.toneTuneIndex
                let stepsSoFar = (veryWellThoughtOutVariableName2 === 0) ? stepsNeeded : veryWellThoughtOutVariableName2
                let stepPercent = stepsSoFar/stepsNeeded * 100 // isNaN(stepsSoFar/stepsNeeded) ? 0 : (stepsSoFar/stepsNeeded) * 100
                return stepPercent
              }
              break

          case 'Modulation':
              if (this.$store.state.flow.chain){
                  if (leadTrackTune.length === 0) { return 0 }
                  let stepsNeeded = leadTrackTune.length * leadTrack.changePer * this.scene.modulatePerLeadChanges  * this.scene.chainAdvancePer
                  let chainSteps = this.scene.chainIncrement * this.scene.modulatePerLeadChanges * leadTrack.changePer * leadTrackTune.length
                  let modCycleSteps = this.scene.modulationCycles * leadTrack.changePer * leadTrackTune.length
                  let currentChangeCycleSteps = leadTrack.changeCycles * leadTrackTune.length
                  let stepsByCycleCount = chainSteps + modCycleSteps + currentChangeCycleSteps + leadTrack.toneTuneIndex

                  let modProgBarDisplayCount = (this.scene.chainIncrement === 0 &&
                                                this.scene.modulationCycles === 0 && leadTrack.changeCycles === 0 &&
                                                leadTrack.toneTuneIndex === 0 && this.scene.started)
                                                ? stepsNeeded : stepsByCycleCount
                  let progress = modProgBarDisplayCount / stepsNeeded * 100
                  return progress <= 100 ? progress :  100 // if modulatePerLeadChanges is dropped during the cycle, stepsSoFar may exceed stepsNeeded
              } else {
                  if (leadTrackTune.length === 0) { return 0 }
                  let stepsNeeded = leadTrackTune.length * leadTrack.changePer * this.scene.modulatePerLeadChanges
                  let currentChangeCycleSteps = leadTrack.changeCycles * leadTrackTune.length
                  let modCycleSteps = this.scene.modulationCycles * leadTrack.changePer * leadTrackTune.length
                  let stepsByCycleCount = modCycleSteps + currentChangeCycleSteps + leadTrack.toneTuneIndex
                  let modProgBarDisplayCount = (this.scene.modulationCycles === 0 && leadTrack.changeCycles === 0 &&
                                                leadTrack.toneTuneIndex === 0 && this.scene.started)
                                                ? stepsNeeded : stepsByCycleCount
                  let progress = modProgBarDisplayCount / stepsNeeded * 100
                  return progress <= 100 ? progress :  100 // if modulatePerLeadChanges is dropped during the cycle, stepsSoFar may exceed stepsNeeded
              }
              break

          case 'Form':
              let stepsNeeded = 0
              if (this.$store.state.flow.chain){
                  stepsNeeded = leadTrack.changePer * leadTrackTune.length * this.scene.modulatePerLeadChanges * this.scene.harmonicForm.length * this.scene.chainAdvancePer
              } else {
                  stepsNeeded = leadTrack.changePer * leadTrackTune.length * this.scene.modulatePerLeadChanges * this.scene.harmonicForm.length
              }
              if (this.scene.formStep === -1){
                  return 0
              }
              if (this.stepsTowardSceneChange >= stepsNeeded) {
                  this.stepsTowardSceneChange = 0
                  return 100
              } else {
                  return (this.stepsTowardSceneChange/stepsNeeded) * 100
              }
              break
      }
    },
    advanceSceneTitle(){
      return this.$store.getters.advanceSceneTitle
    },
    length(){
      /* // tracking length of lead track tune:
      let leadTrackTuneLength = this.$store.getters.toneTunes[this.$store.getters.leadTrackNumber].length
      console.log(leadTrackTuneLength)
      let length = ''
      if (leadTrackTuneLength === 0) { length = this.scene.selectedLength }
      else length = leadTrackTuneLength
      return length */
      let length = this.scene.selectedLength
      if (length === 0) { return 8 }
      else { return length }
    },
    tunesRemembered(){
      let remembered = false
      for (let i=0; i < this.scene.tracks.length; i++) {
        if (this.scene.tracks[i].rememberedTune.length > 0) { remembered = true }
      }
      return remembered
    },

  },

  watch: {   // watchers expensive? https://www.reddit.com/r/vuejs/comments/9w57sz/performance_cost_of_emit/
    leadTrackStep: function (val) {
      if ( ! (this.scene.sceneChangeIncrement === "Form" && this.scene.formStep===-1 ) &&
           this.scene.suspendChanges === false )  {
        this.stepsTowardSceneChange++
      }
      if (this.scene.started === false) {
        this.stepsTowardSceneChange = 0
      }
    },

  },

  methods: {
    resetScene(){
      this.$store.commit('resetScene')
    },
    changeTempo(e){
      // console.log("changing tempo")
      if (e.target.value > 0){ this.$store.commit('changeTempo', e) }
    },
    toggleSuspendChanges(){
      // console.log('sceneInfo tSC');
      this.$store.commit('toggleSuspendChanges')
    },
    rememberAllTunes(){
      this.$store.dispatch('rememberAllTunes')
    },
    returnAllTunes(){
      this.$store.dispatch('returnAllTunes')
    },
    toggleSceneSettingsModal(){
      this.isSceneSettingModalOpen = !this.isSceneSettingModalOpen
    },
    focusFunction(){
      // console.log("focussing")
      this.$store.commit('changePreviousRegion', this.$store.state.activeRegion)
      this.$store.commit('changeActiveRegion', '')
    },
    enterFunction(event){
      event.target.blur()
      this.$store.commit('changeActiveRegion', this.$store.state.previousRegion)
      // bus.$emit('clearKeyFromDown', 'Enter')
    },
  },

  // created: function () {
  // },

}

/*
case 'Form':
    if (this.$store.state.chain){
        let stepsNeeded = leadTrack.changePer * leadTrackTune.length * this.scene.modulatePerLeadChanges * this.scene.harmonicForm.length *this.scene.chainAdvancePer
        let excessivelyWellThoughtOutVariableName = (leadTrackTune.length * leadTrack.changePer * this.scene.modulationCycles)
                                          + (leadTrackTune.length * leadTrack.changePer * this.scene.chainIncrement)
                                          + (leadTrackTune.length * leadTrack.changeCycles)
                                          + leadTrack.toneTuneIndex
    } else {
        let stepsNeeded = leadTrack.changePer * leadTrackTune.length * this.scene.modulatePerLeadChanges * this.scene.harmonicForm.length
        console.log('form', leadTrackTune.length * leadTrack.changePer * this.scene.modulatePerLeadChanges * this.scene.formStep)
        console.log('mod', leadTrackTune.length * leadTrack.changePer * this.scene.modulationCycles)
        console.log('change', leadTrackTune.length * leadTrack.changeCycles)
        console.log('step', leadTrack.toneTuneIndex)
        console.log('*')
        let variableName = (leadTrackTune.length * leadTrack.changePer * this.scene.modulatePerLeadChanges * this.scene.formStep)
                            + (leadTrackTune.length * leadTrack.changePer * this.scene.modulationCycles)
                            + (leadTrackTune.length * leadTrack.changeCycles)
                            + leadTrack.toneTuneIndex
        if (variableName < 0) { return 0 }
        let stepsSoFar = (variableName === 0) ? stepsNeeded : variableName
        let stepPercent = stepsSoFar/stepsNeeded * 100 // isNaN(stepsSoFar/stepsNeeded) ? 0 : (stepsSoFar/stepsNeeded) * 100
        return stepPercent
    }

    break
*/

</script>


<style lang="scss">

.scene-info {
  //background: #b77;
  color: white;

  min-width: 200px;
  min-height: 100%;
  //align-self: stretch;
  //padding: 20px 0;
  box-sizing: border-box;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  //grid-gap: 20px;

  .tempo {
    display: flex;
    align-items: center;
  }

  .scene-advance-container {
    display: flex;
    align-items: center;

    .scene-advance {
      display: flex;
      max-height: 16px;

      .scene-advance-meter {
        display: inline-block;
        width: 90px;
        min-height: 14px;
        border: 1px solid #000;
        /*  margin: 0 auto 20px auto; */
        box-sizing: border-box;

        div{
          height: 14px;
          background: blue;
        }

        span {
          display: flex;
        }
      }

      .scene-advance-arrow {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15px;
        }
      }

      .scene-advance-title {
        display: flex;
        align-items: center;
        width: 90px;
        min-height: 14px;
        border: 1px solid #000;
        box-sizing: border-box;
        padding: 0 0 0 2px;

        div {
          font-size: 12px;
          overflow: hidden;
        }
      }
    }
  }

  input {
    width: 60px;
    margin: 3px;
  }


  .scene-controls {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .remember-tunes,
    .return-tunes,
    .reset-scene,
    .suspend-changes,
    .scene-settings {
      cursor: pointer;
      //margin: 0 10px;

      img {
        width: 20px;
      }
    }
  }
}

</style>
