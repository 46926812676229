
import { defineComponent } from "vue";
import SaveModalLocal from "@/components/SaveModalLocal.vue"

export default defineComponent({
  name: "MainNav",
  props: ['registerCombos', 'unregisterCombos'],
  components: {
    SaveModalLocal,
  },
  data() {
    return {
      showLoginFields: false,
      loginHandle: '',
      password: '',
      isSaveModalLocalOpen: false,
    }
  },
  methods: {
    handleLogin() {
      // make request to database to save user
      if (!this.showLoginFields) {
        this.showLoginFields = true
      } else {

        const userData = {
          loginHandle: this.loginHandle,
          password: this.password,
        }

        this.$store.dispatch('login', userData)
      }
    },
    handleLogout() {
      this.$store.dispatch('logout')
    },
    toggleSaveModalLocal(){
      // console.log('toggleSaveModalLocal');
      this.isSaveModalLocalOpen = !this.isSaveModalLocalOpen
    },
  },
  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
    userID() {
      return this.$store.state.auth.user._id
    },
    activeFlowId() {
      // console.log('activeFlowId', this.$store.state.flow._id)
      return this.$store.state.flow._id
    }
  },

  mounted() {
    // console.log('mounted (in MainNav)')
  },
  updated() {
    // console.log('updated (in MainNav)')
  }
});
