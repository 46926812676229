import * as CONSTANTS from "@/constants/constants"

// ***************************************************************************************************************
// GETTERS
// ***************************************************************************************************************

export const qwertyVals = state => {
  let keyToQwerty = []
  switch (state.player.playerParams.keyToQwertyDisplay){
    case 'Rows-Octave':
      keyToQwerty = CONSTANTS.keyToQwertyValRowsOctave
      break
    case 'Rows-Fifth':
      keyToQwerty = CONSTANTS.keyToQwertyValRowsFifth
      break
    case 'Clusters':
      keyToQwerty = CONSTANTS.keyToQwertyValClusters
      break
  }
  //keyToQwerty = state.keyToQwertyVal
  let qwertyVals = { " ":" ", "Enter":" " }
  for (const key in keyToQwerty) {
    if (keyToQwerty[key] === "") {
      qwertyVals[key] = ""
    } else {
      let suffix = keyToQwerty[key].slice(-3)
      let val = keyToQwerty[key].slice(0, -3)
      if (suffix === "-Lo") {
        qwertyVals[key] = val + state.player.playerParams.qwertyOctave
      }
      else if (suffix === "-Hi") {
        qwertyVals[key] = val + (state.player.playerParams.qwertyOctave+1)
      }
      else if (suffix === "-Hx") {
        qwertyVals[key] = val + (state.player.playerParams.qwertyOctave+2)
      }
    }
  }
  if (state.flow.activeRegion === 'tune-entry') {
    qwertyVals['Backspace'] = 'Delete'
    qwertyVals['\\'] = 'Rest'
  }
  return qwertyVals
}


// ***************************************************************************************************************
// MUTATIONS
// ***************************************************************************************************************


// ***************************************************************************************************************
// ACTIONS
// ***************************************************************************************************************

