<template>
<div class="player-ui">
    <div class="player-ui__left">
      <PlayerInfo />
    </div>
    <div class="player-ui__middle">
      <qwerty :down="down" :playerInstrument="playerInstrument" ref="qwertyDisplay"></qwerty>
    </div>
    <div class="player-ui__right">
      <player-controls-filters></player-controls-filters>
    </div>

    <div 
      class="close-player-ui" 
      @click="closePlayerUI"
      :title="showTitles ? 'Open/close qwerty player' : ''"
    >
      -
    </div>
  </div>
</template>
<script>

import PlayerInfo from './PlayerInfo.vue'
import Qwerty from './Qwerty.vue'
import PlayerControlsFilters from './PlayerControlsFilters.vue'

export default {
  name: "PlayerUI",
  components: {
    'PlayerInfo': PlayerInfo,
    'qwerty': Qwerty,
    'player-controls-filters': PlayerControlsFilters,
  },
  props: ['down', 'playerInstrument'],
  data: () => ({}),
  computed: {
    showTitles() {
      return this.$store.state.generalPersist.showTitles
    },
  },
  methods: {
    closePlayerUI() {
      this.$store.commit('togglePlayerUIOpen')
    },
  }
}
</script>

<style lang="scss">

.player-ui {
  height: 100%;
  background: #556;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &__left {
    display: inline-block;
    vertical-align: top;
  }
  &__middle {
    display: inline-block;
    vertical-align: top;
  }
  &__right {
    display: inline-block;
    vertical-align: top;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: black;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .close-player-ui {
    background: grey;
    font-weight: bold;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 100%;
    //border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
